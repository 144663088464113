const fontSizes = {
  extra8: 8,
  sm: 14,
  md: 18,
  exrta20: 20,
  exrta22: 22,
  ml: 24,
  lg: 28,
  exrta32: 32,
  xl: 36,
  extra40: 40,
  xxl: 48,
  xxxl: 72,
};

export default fontSizes;
