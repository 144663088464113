import LOCALES from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    //Authorization
    login_title: 'Click to Login',
    login_to_space_title: 'Login to space',
    user_login_title: 'User Login',
    user_login_ti_space_title: 'You´re logging in to foodscale of',
    source_login_to_space_title: 'You´re logging in to foodscale of',

    add_db: 'add another database',
    select_db: 'select other space (database)',
    select_accout: 'select the account to login with',
    go_to_login_selection: 'go to login selection',
    choose_space: 'Choose space (database)  to Login',

    pinLogin: 'use personal PIN to Login',
    userLogin: 'User Selection for Login',
    login_to_accoutnt_with: 'with the account of',
    password_title: 'Password',

    // Accardion
    all_recipes_block_title: 'all recipes',
    todo_block_title: 'todo',
    todo_block_title_up: 'Todo',
    parking_block_title: 'parking',
    parking_block_title_up: 'Parking',
    in_progress_block_title: 'in progress',
    in_progress_block_title_up: 'In progress',
    done_block_title: 'done',
    default: '',

    //toast messages
    incorect_password: 'You entered an incorrect password',
    user_not_found: 'User is not found. Please re-login via nutrimero',
    success_added_recipe_in_todo:
      'Recipe has been successfully added to "TODO"',
    success_added_recipe_in_in_progress:
      'Recipe has been successfully added to "IN PROGRESS"',
    success_deleted_recipe_in_todo:
      'The recipe has been successfully removed from "TODO"',
    success_added_production_list: 'Production list successfully created',
    success_imported_production_list: 'Product list imported successfully',
    success_edit_production_list: 'Production list successfully edited',
    failed_added_production_list:
      'An error occurred during the creation of the product list',
    error_minimum_number_of_products:
      'It is necessary to choose at least one recipe',
    create_production_list_validation: 'One of the fields is invalid',

    success_edit_recipe: 'Recipe edited successfully',
    failed_edit_recipe: 'An error occurred while editing the recipe',
    import_incorrect_type: 'Incorrect type!',
    import_recipe_not_found: 'Recipe not found',
    import_error: 'There was an error importing the file',

    //Titles
    category_title: 'Category',
    search_result_title: 'Search Item',
    results_title: 'Results',
    to_list_title: 'To list',
    name_title: 'Name',
    planed_amount_title: 'Planed amount',
    reset_title: 'reset',
    complete_sum: 'Complete Sum',
    x_time_base_pecipe: 'x-times the base recipe',
    cancel_title: 'Cancel',
    ok_title: 'Ok',
    print_production_list_title: 'print production list',
    print_parking_list_title: 'print parking list',
    print_recipe_title: 'print recipe',

    //ProductionLists
    production_lists_title: 'Production Lists',
    create_btn_title: 'create',
    edit_btn_title: 'edit',
    dublicate_btn_title: 'dublicate',
    delete_btn_title: 'delete',
    import_btn_title: 'import',
    time_span_title: 'Time span:',
    to_title: 'to',
    //ProductList
    production_list_title: 'Production List',
    add_recipe_title: 'add recipe',
    edit_recipe_title: 'edit recipe',
    delete_entry_title: 'delete entry',
    save_title: 'save',
    back_title: 'back',
    list_name_title: 'list name',
    date_title: 'date',
    fixed_amount_title: 'fixed amount',
    add_precipe_modal_title:
      'Which recipe should be added to this production list?',
    entry_exists_title: 'This entry already exists !',
  },
};
