import { Box } from '@material-ui/system';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import CustomCheckBox from '../../../components/CheckBox/CustomCheckBox';
import { setActiverecipes } from '../../../redux/reducers/productionLists';
import { productionListsSelector } from '../../../redux/selectors';
import { getRecipeTotalWeight } from '../../../utils/getRecipeTotalWeight';
import { isNaNValidation } from '../../../utils/isNaNValidation';
import useStyles from '../styles';

const ProductListItem = ({ recipe }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { variant, name, xValue, ingridients, completeSum } = recipe;
  const classes = useStyles();

  const { activeRecipes } = useSelector(productionListsSelector);

  const handleActiveRecipe = (recipe) => {
    dispatch(
      setActiverecipes({
        activeRecipes: recipe.id === activeRecipes?.id ? null : recipe,
      }),
    );
  };

  return (
    <>
      <Box className={classes.tableRow}>
        <Box className={classes.tableRowCheckboxWrapper}>
          <CustomCheckBox
            size='small'
            checked={activeRecipes?.id === recipe?.id ? true : false}
            onChange={() => handleActiveRecipe(recipe)}
            classes={{ root: classes.formControlLabel }}
          />
        </Box>
        <Box className={classNames(classes.tableRowTitleWrapper, 'drag')}>
          <Box className={classes.rowTitle}>{name}</Box>
          <Box className={classes.totalWeight}>
            {`${isNaNValidation(
              getRecipeTotalWeight(recipe)?.toFixed(3),
              ' kg',
            )} `}
          </Box>
        </Box>
      </Box>
      <Box className={classes.listsWrapper}>
        {variant === 'ingridients'
          ? ingridients.map((item, index) => (
              <Box key={index} className={classes.listsWrapperItem}>
                <Box className={classes.listsWrapperItemText}>{item?.name}</Box>
                <Box className={classes.listsWrapperItemText}>
                  {isNaNValidation(item?.count)}
                </Box>
              </Box>
            ))
          : null}

        {variant === 'sum' ? (
          <Box className={classes.listsWrapperItem}>
            <Box className={classes.listsWrapperItemText}>
              {formatMessage({ id: 'fixed_amount_title' })}
            </Box>
            <Box className={classes.listsWrapperItemText}>{`${isNaNValidation(
              Number(completeSum).toFixed(3),
              ' kg',
            )}`}</Box>
          </Box>
        ) : null}

        {variant === 'x-value' ? (
          <Box className={classes.listsWrapperItem}>
            <Box className={classes.listsWrapperItemText}>
              {formatMessage({ id: 'x_time_base_pecipe' })}
            </Box>
            <Box className={classes.listsWrapperItemText}>{`${isNaNValidation(
              xValue,
            )}`}</Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ProductListItem;
