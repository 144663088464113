export const validationRecipe = (recipe) => {
  let isValid = true;

  if (isNaN(recipe?.weight)) {
    isValid = false;
  } else if (isNaN(recipe?.count)) {
    isValid = false;
  }

  recipe?.ingredients?.forEach((ingridient) => {
    if (isNaN(ingridient?.amount)) {
      isValid = false;
    }
  });
  return isValid;
};

export const validationRecipesValues = (arrayRecipes) => {
  let isValid = true;

  arrayRecipes?.forEach((recipe) => {
    let isRecipeValid = validationRecipe(recipe);
    if (!isRecipeValid) {
      isValid = false;
    }
  });

  return isValid;
};

export const validationToDoRecipe = (recipe, variant) => {
  let isValid = true;

  if (variant === 'complete sum' && isNaN(+recipe?.weight)) {
    isValid = false;
  } else if (variant === 'x-time the base recipe' && isNaN(recipe?.count)) {
    isValid = false;
  }

  recipe?.arrayAmounts &&
    recipe?.arrayAmounts?.forEach((ingridient) => {
      if (variant === 'amount products' && isNaN(ingridient?.amount)) {
        isValid = false;
      }
    });
  return isValid;
};
