import { Box, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import ArrowLeftStrongIcon from '../../../components/icons/ArrowLeftStrongIcon';
import CompleteIcon from '../../../components/icons/CompleteIcon';
import AuthLayout from '../../../components/Layout/AuthLayout/AuthLayout';
import MainLayout from '../../../components/Layout/MainLayout/MainLayout';
import SpinnerLogo from '../../../components/Spinner/SpinnerLogo';
import {
  KEYBOARD_FOR_PIN_OPTIONS,
  MAX_PIN_LENGTH,
  ROUTES,
} from '../../../config/constants';
import {
  getActiveSpaceToLogin,
  useAuthContext,
} from '../../../context/AuthContext';
import { useSignViaPinQuery } from '../../../hooks/useQueries';
import fontSizes from '../../../theme/fontSizes';
import useStyles from './styles';

const UserLoginPin = () => {
  const { formatMessage } = useIntl();
  const [pinValue, setPinValue] = useState('');

  const [query] = useSignViaPinQuery();
  const activeSpaceToLogin = getActiveSpaceToLogin();
  const { loading, startLoading, pinError, removePINError } = useAuthContext();
  const { push } = useHistory();

  const maxInputLength = MAX_PIN_LENGTH;
  const isValid = pinValue.length > 0;
  const classes = useStyles({ pinValue, maxInputLength, isValid, pinError });

  const handleChange = (value) => {
    if (pinValue.length < maxInputLength) {
      removePINError();
      setPinValue(pinValue + value);
    }
  };

  const handleInputChange = (event) => {
    setPinValue(event.target.value);
  };

  const handleChangePage = () => {
    push(ROUTES.userLogin);
  };

  const deleteLastPinSymbol = (event) => {
    event.preventDefault();
    let newPinValue = pinValue;
    if (pinValue.length > 0) {
      removePINError();
      setPinValue(newPinValue.slice(0, -1));
    }
  };

  return (
    <MainLayout>
      <AuthLayout
        botomBtnTitle={'go_to_login_selection'}
        title='user_login_title'
        bottomBtnAction={handleChangePage}
      >
        <Typography
          fontSize={fontSizes.ml}
          marginTop='46px'
          marginBottom={'29px'}
        >
          {formatMessage({ id: 'pinLogin' })}
        </Typography>

        {loading ? (
          <SpinnerLogo />
        ) : (
          <>
            <Box className={classes.enterPinWrapper}>
              <TextField
                type='password'
                // disabled
                value={pinValue}
                classes={{ root: classes.pinInput }}
                inputProps={{ maxLength: maxInputLength }}
                onChange={handleInputChange}
              />
              <Box
                className={classNames(classes.btn, classes.validBtn)}
                onClick={deleteLastPinSymbol}
              >
                <ArrowLeftStrongIcon />
              </Box>
              <Box
                className={classNames(classes.btn, classes.validBtn)}
                onClick={async () => {
                  startLoading();
                  await query({
                    variables: {
                      databaseId: +activeSpaceToLogin.id,
                      pin: +pinValue,
                    },
                  });

                  setPinValue('');
                }}
              >
                <CompleteIcon />
              </Box>
            </Box>
            <Box className={classes.keyboardBtnsWrapper}>
              {KEYBOARD_FOR_PIN_OPTIONS?.map((btn) => {
                return (
                  <Box
                    key={btn}
                    className={classNames(classes.btn, classes.keyboardBtn)}
                    onClick={() => handleChange(btn)}
                  >
                    {btn}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </AuthLayout>
    </MainLayout>
  );
};

export default UserLoginPin;
