import './gridLoyautStyles.css';

import { Modal, TextField } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import { Field, FieldArray, Formik } from 'formik';
import { TextField as TextFieldMUI } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import { toast } from 'react-toastify';

import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import ProductionListLayout from '../../components/Layout/ProductionListLayout/ProductionListLayout';
import AddRecipe from '../../components/Modals/AddRecipe/AddRecipe';
import SpinnerLogo from '../../components/Spinner/SpinnerLogo';
import { ROUTES } from '../../config/constants';
import { useCreateProductionListMutation } from '../../hooks/useMutations';
import { useGetProductionList } from '../../hooks/useQueries';
//import { recipesList } from '../../moc/recipes';
import { setActiverecipes } from '../../redux/reducers/productionLists';
import { productionListsSelector, recipeSelector } from '../../redux/selectors';
import { getRecipesList } from '../../utils/getRecipesList';
import { createProductionListSchemas } from '../../utils/schemas';
import { validationRecipesValues } from '../../utils/validationRecipevalues';
import EditRecipe from '../EditRecipe/EditRecipe';
import ProductionListsBtn from '../ProductionLists/ProductionListsBtn/ProductionListsBtn';
import ProductListItem from './ProductListItem/ProductListItem';
import useStyles from './styles';

const ProductionList = ({ match }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { activeRecipes } = useSelector(productionListsSelector);
  const { recipes } = useSelector(recipeSelector);
  const [date, setDate] = useState(new Date());
  const [isEditRecipe, setIsEditRecipe] = useState(false);
  const [isAddRecipe, setIsAddRecipe] = useState(false);

  const productionListId = match.params.id;
  const isEdit = productionListId !== 'new';

  const [createMutation, { loading: createLoading }] =
    useCreateProductionListMutation(isEdit);

  const [
    getProductionList,
    { loading: productionListLoading, data: productionListData },
  ] = useGetProductionList();

  const [layoutValue, setLayoutValue] = useState(null);

  const databaseId = JSON.parse(localStorage.getItem('activeSpaceToLogin'))?.id;

  const sortRecipes = (recipes) => {
    return (
      recipes.sort(function (a, b) {
        return a.order - b.order;
      }) || []
    );
  };

  const getRecipeVariantValueByCreate = (value) => {
    switch (value) {
      case 'ingridients':
        return 'amount of products from this recipe';
      case 'x-value':
        return 'x-time the base recipe';
      case 'sum':
        return 'complete sum';
    }
  };

  const onSubmit = (formValues) => {
    const arrayRecipes = [];

    formValues?.recipes?.forEach((item) => {
      const orderIndex = layoutValue?.map((item) => item.i).indexOf(item?.name);

      const productionListRecipeIdObj = item?.recipeListId
        ? { productionListRecipeId: +item?.recipeListId }
        : {};

      arrayRecipes?.push({
        order: +layoutValue[orderIndex]?.y.toFixed(0) || 0,
        count: +item?.xValue,
        baseWeight: +item?.baseWight,
        weight: +item?.completeSum,
        typeMeasurements: getRecipeVariantValueByCreate(item?.variant),
        recipeId: +item?.id,
        ...productionListRecipeIdObj,
        ingredients: item?.ingridients?.map((ingridient) => {
          return {
            id: ingridient?.id,
            name: ingridient?.name,
            weight: +ingridient?.weight,
            amount: +ingridient?.count,
            unitId: +ingridient?.measureId,
            recipeItemId: +ingridient?.recipeItemId,
          };
        }),
      });
    });

    const idValue = isEdit ? { productionListId: +productionListId } : {};

    if (arrayRecipes?.length) {
      if (validationRecipesValues(arrayRecipes)) {
        createMutation({
          variables: {
            name: formValues?.name,
            date: String(new Date(date)),
            databaseId: +databaseId,
            ...idValue,
            arrayRecipes,
          },
        });
      } else {
        toast(formatMessage({ id: 'create_production_list_validation' }), {
          type: 'error',
        });
      }
    } else {
      toast(formatMessage({ id: 'error_minimum_number_of_products' }), {
        type: 'error',
      });
    }
  };

  const handleDelete = (activeRecipes, values, setFieldValue) => {
    const recipeIndex = values.recipes
      ?.map((item) => item.id)
      .indexOf(activeRecipes.id);

    const newREcipeValue = [...values.recipes];
    newREcipeValue.splice(recipeIndex, 1);
    setFieldValue('recipes', newREcipeValue);

    dispatch(
      setActiverecipes({
        activeRecipes: null,
      }),
    );
  };

  const getLayout = (values) => {
    return (
      (values?.recipes?.length &&
        values?.recipes?.map((item) => {
          let height =
            item?.variant === 'ingridients'
              ? 1 + item?.ingridients?.length * 1.1
              : 2.1;
          return {
            i: item.name,
            w: 1,
            h: height,
            x: 0,
            y: 0,
          };
        })) ||
      []
    );
  };

  const getInitialValueProductionList = (data) => {
    let result = {
      name: data ? data?.productionList?.name : '',
      recipes: [],
    };

    result.recipes = sortRecipes(
      getRecipesList(data?.productionListRecipeList),
    );

    return result;
  };

  const initialValues =
    getInitialValueProductionList(
      productionListData?.getProductionListRecipes,
    ) || {};

  useEffect(() => {
    if (isEdit) {
      getProductionList({
        variables: {
          productionListId: +productionListId,
          databaseId: +databaseId,
        },
      });
    }
  }, [productionListId]);

  useEffect(() => {
    if (isEdit) {
      const newDate =
        productionListData?.getProductionListRecipes.productionList?.forADay;
      setDate(+newDate);
    }
  }, [productionListData]);

  useEffect(() => {
    dispatch(
      setActiverecipes({
        activeRecipes: null,
      }),
    );
  }, []);

  return (
    <MainLayout>
      <ProductionListLayout title='production_list_title'>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
          {productionListLoading ? (
            <SpinnerLogo fullPage={true} />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={createProductionListSchemas}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <>
                  <Box className={classes.btnsWrapper}>
                    <ProductionListsBtn
                      title='add_recipe_title'
                      isActive={true}
                      action={() => setIsAddRecipe(true)}
                    />
                    <ProductionListsBtn
                      title='edit_recipe_title'
                      isActive={activeRecipes}
                      action={() => setIsEditRecipe(true)}
                    />
                    <ProductionListsBtn
                      title='delete_entry_title'
                      mr='auto'
                      isActive={activeRecipes}
                      action={() =>
                        handleDelete(activeRecipes, values, setFieldValue)
                      }
                    />
                    <ProductionListsBtn
                      title='save_title'
                      isActive={!createLoading}
                      action={handleSubmit}
                    />
                    <ProductionListsBtn
                      title='back_title'
                      mr={0}
                      action={() => push(ROUTES.productionLists)}
                      isActive={true}
                    />
                  </Box>
                  <Box className={classes.titleRow}>
                    <Box className={classes.listName} mr={'20px'}>
                      {formatMessage({ id: 'list_name_title' })}
                    </Box>
                    <Field
                      component={TextFieldMUI}
                      name={`name`}
                      type='text'
                      className={classes.title}
                    />
                    <Box className={classes.date} ml={'auto'}>
                      {formatMessage({ id: 'date_title' })}
                    </Box>

                    <DatePicker
                      inputFormat={`EEE dd.MM.yy`}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          classes={{ root: classes.dateInput }}
                          {...params}
                        />
                      )}
                    />
                  </Box>

                  {createLoading ? (
                    <SpinnerLogo />
                  ) : (
                    <>
                      {values.recipes?.length ? (
                        <Box className={classes.tableWrapper}>
                          <Box className={classes.tableListItemWrapper}>
                            <FieldArray name={'recipes'}>
                              {(arrayHelpers) => (
                                <GridLayout
                                  container
                                  layout={getLayout(values)}
                                  width={417}
                                  cols={1}
                                  spacing={0}
                                  draggableHandle='.drag'
                                  autoSize={true}
                                  rowHeight={20}
                                  className='layout'
                                  onLayoutChange={(layout) => {
                                    setLayoutValue(layout);
                                  }}
                                >
                                  {values.recipes?.map((item, index) => (
                                    <div key={item.name}>
                                      <ProductListItem
                                        key={index}
                                        recipe={item}
                                        arrayHelpers={arrayHelpers}
                                      />
                                    </div>
                                  ))}
                                </GridLayout>
                              )}
                            </FieldArray>
                          </Box>
                        </Box>
                      ) : null}
                    </>
                  )}

                  <Modal
                    open={isEditRecipe}
                    onClose={() => setIsEditRecipe(false)}
                  >
                    <EditRecipe
                      setIsEdit={setIsEditRecipe}
                      activeRecipe={activeRecipes}
                      isModal={true}
                      onSubmit={onSubmit}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Modal>

                  <Modal
                    open={isAddRecipe}
                    onClose={() => setIsAddRecipe(false)}
                  >
                    <AddRecipe
                      recipesList={recipes}
                      setIsAddRecipe={setIsAddRecipe}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Modal>
                </>
              )}
            </Formik>
          )}
        </LocalizationProvider>
      </ProductionListLayout>
    </MainLayout>
  );
};

export default withRouter(ProductionList);
