import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import pallete from '../../theme/pallete';

const useStyles = ({ width, paperWidth, papperML, ml, height }) => {
  return makeStyles(() => ({
    select: {
      marginLeft: ml,
      width,
      borderRadius: '7px!important',
      background: pallete.secondary1,
      height,
      fontSize: '18px!important ',
      lineHeight: '28px!important',
      border: '1px solid #000000',
      '& fieldset': {
        border: 'none',
      },
    },
    arrowBtn: {
      width: 24,
      height: 24,
      marginRight: 5,
      position: 'absolute',
      right: 0,
      top: 0,
      pointerEvents: 'none',
    },
    dropdownStyle: {
      boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)!important',
      border: '1px solid #000000!important',
      borderRadius: 7,
      minWidth: `${paperWidth}px!important`,
      maxWidth: `${paperWidth}px!important`,
      marginLeft: `${papperML}px!important`,
      '& ul': {
        paddingTop: 0,
        paddingBottom: 0,
        border: 'none',
        backgroundColor: pallete.selectBg,
      },
      '& li': {
        padding: '3px 15px',
        color: pallete.textPrimary,
        fontSize: fontSizes.md,
        backgroundColor: pallete.selectBg,
      },
    },
  }))();
};

export default useStyles;
