import { Box, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

import {
  API_HOST,
  LOCALE_OPTIONS,
  LOGIN_OPTIONS,
  ROUTES,
  SCALES_OPTIONS,
} from '../../config/constants';
import { useAccordionContext } from '../../context/AccordionContext';
import { useAuthContext } from '../../context/AuthContext';
import { useLanguageContext } from '../../context/LanguageContext';
import { getDayName } from '../../utils/getDayName';
import { getDefaultDateFormat } from '../../utils/getDefaultDateFormat';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import CustomSelect from '../CustomSelect/CustomSelect';
import HamburgerMenuIcon from '../icons/HamburgerMenuIcon';
import LogoIcon from '../icons/LogoIcon';
import SelectArrowIcon from '../icons/SelectArrowIcon';
import SelectDaysIcon from '../icons/SelectDaysIcon';
import MenuDropdown from '../MenuDropdown/MenuDropdown';
import enFlagIcon from './../../img/en-flag-icon.png';
import scaleIcon from './../../img/scale-1.png';
import useStyles from './styles';

const NavBar = () => {
  const { handleChangeLanguage } = useLanguageContext();
  const { isAuth, user } = useAuthContext();
  const { productionList, setActiveDay, activeDayId } = useAccordionContext();
  const { push } = useHistory();

  const classes = useStyles({ isAuth });

  const handleChangeDay = (event) => {
    setActiveDay(event.target.value);
  };

  const handleChangeScale = (value) => {
    console.log(value);
  };

  const getProductionListOptions = productionList?.map((item) => {
    return {
      label: `${item?.name}, ${getDayName(
        item?.forADay,
      )}, ${getDefaultDateFormat(item?.forADay, 'y-MM-d')}`,
      value: item.id,
    };
  });

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.hamburgerMenuBtnWrapper}>
        <MenuDropdown>
          <IconButton>
            <HamburgerMenuIcon />
          </IconButton>
        </MenuDropdown>
      </Box>
      <Box
        display='flex'
        onClick={() => {
          isAuth && push(ROUTES.home);
        }}
        style={{ cursor: isAuth ? 'pointer' : 'auto' }}
      >
        <LogoIcon />
      </Box>
      {isAuth ? (
        <Box className={classes.daysSelectWrapper}>
          <SelectDaysIcon />

          <CustomSelect
            value={activeDayId}
            onChange={handleChangeDay}
            itemsList={getProductionListOptions || []}
          />
        </Box>
      ) : null}

      <Box className={classes.languageSelectWrapper}>
        <CustomDropdown
          content={LOCALE_OPTIONS}
          setValue={handleChangeLanguage}
        >
          <Box style={{ display: 'flex' }}>
            <img src={enFlagIcon} alt='en-flug' />
            <IconButton>
              <SelectArrowIcon />
            </IconButton>
          </Box>
        </CustomDropdown>
      </Box>

      <Box className={classes.scalesSelectWrapper}>
        <CustomDropdown content={SCALES_OPTIONS} setValue={handleChangeScale}>
          <Box style={{ display: 'flex' }}>
            <img src={scaleIcon} alt='en-flug' />
            <IconButton>
              <SelectArrowIcon />
            </IconButton>
          </Box>
        </CustomDropdown>
      </Box>

      {isAuth ? (
        <Box className={classes.userSelectWrapper}>
          <CustomDropdown content={LOGIN_OPTIONS} setValue={handleChangeScale}>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              className={classes.userInfoWrapper}
            >
              <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
              <Box
                className={classes.noUserIconWrapper}
                display='flex'
                alignItems={'center'}
              >
                <img
                  src={`${API_HOST}/${user?.avatar}`}
                  alt={`${user?.firstName} ${user?.lastName}`}
                />
              </Box>
              <IconButton>
                <SelectArrowIcon />
              </IconButton>
            </Box>
          </CustomDropdown>
        </Box>
      ) : null}
    </Box>
  );
};

export default NavBar;
