import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';

const useStyles = ({ mr, isActive }) =>
  makeStyles(() => ({
    wrapper: {
      padding: '5px 10px',
      minWidth: 115,
      width: 'auto',
      border: '1px solid #5E5E5E',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: isActive ? 'pointer' : 'auto',
      opacity: isActive ? 1 : 0.4,
      marginRight: mr,
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
    btnTitle: {
      marginLeft: 12,
      fontSize: fontSizes.md,
    },
    fileInput: {
      display: 'none',
    },
  }))();

export default useStyles;
