import { Box } from '@material-ui/system';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { getDefaultDateFormat } from '../../utils/getDefaultDateFormat';
import { getRecipeTotalWeight } from '../../utils/getRecipeTotalWeight';
import { isNaNValidation } from '../../utils/isNaNValidation';
import useStyles from './styles';

// eslint-disable-next-line react/display-name
const PrintRecipe = forwardRef(({ recipe, type }, ref) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Box
      ref={ref}
      className={classNames(
        classes.contentWrapper,
        classes.activeContentWrapper,
      )}
    >
      <Box className={classes.content}>
        {type === 'todo' && recipe?.createAt ? (
          <Box className={classes.contentRow}>
            <Box className={classes.contentRowTitle}>
              {formatMessage({ id: 'to_list_title' })}
            </Box>
            <Box className={classes.contentRowValue}>
              {recipe?.createAt &&
                getDefaultDateFormat(recipe?.createAt, 'y MMMM d @ HH:mm')}
            </Box>
          </Box>
        ) : null}

        <Box
          className={classes.contentRow}
          marginBottom={type === 'all' ? 0 : '17px'}
        >
          <Box className={classes.contentRowTitle}>
            {formatMessage({ id: 'name_title' })}
          </Box>
          <Box className={classes.contentRowValue}>{recipe?.name}</Box>
        </Box>

        {type === 'todo' && recipe?.ingridients ? (
          <>
            <Box className={classes.contentRow}>
              <Box
                className={classNames(
                  classes.contentRowTitle,
                  classes.contetnRowTitleAuto,
                )}
              >
                {formatMessage({ id: 'planed_amount_title' })}
              </Box>
              <Box
                className={classNames(
                  classes.contentRowValue,
                  classes.contentRowWeightValue,
                )}
              >
                {`${isNaNValidation(
                  getRecipeTotalWeight(recipe)?.toFixed(3),
                  ' kg',
                )} `}
              </Box>
            </Box>
            {recipe?.variant === 'ingridients'
              ? recipe?.ingridients?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      className={classes.contentIngridientsValue}
                      marginBottom={0}
                    >{`${item?.count} * ${item?.name}`}</Box>
                  );
                })
              : null}
            {recipe?.variant === 'sum' ? (
              <Box className={classes.contentIngridientsValue} marginBottom={0}>
                {`${formatMessage({
                  id: 'fixed_amount_title',
                })}: ${isNaNValidation(
                  Number(recipe?.completeSum).toFixed(3),
                  ' kg',
                )}`}
              </Box>
            ) : null}

            {recipe?.variant === 'x-value' ? (
              <Box className={classes.contentIngridientsValue} marginBottom={0}>
                {`${formatMessage({
                  id: 'x_time_base_pecipe',
                })}: ${isNaNValidation(recipe?.xValue)}`}
              </Box>
            ) : null}
          </>
        ) : null}
      </Box>
    </Box>
  );
});

export default PrintRecipe;
