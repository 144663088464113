import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import fontSizes from '../../../theme/fontSizes';
import CustomSelect from '../../CustomSelect/CustomSelect';
import ModalLayout from '../../Layout/ModalLayout/ModalLayout';
import SearchComponent from '../../Search/Search';
import useStyles from './styles';

const SearchModal = ({
  onClose,
  open,
  searchValue,
  setSearchValue,
  handleSearch,
  handleCategory,
  category,
  categoryList,
  recipes,
}) => {
  const { formatMessage } = useIntl();

  // const [category, setCategory] = useState(categoriesRecipes[0].value);

  // const handleChangeCategory = (event) => {
  //   setCategory(event.target.value);
  // };

  const classes = useStyles();

  return (
    <>
      <ModalLayout onClose={onClose} open={open} type='search'>
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentRow}>
            <Typography
              fontSize={fontSizes.md}
              classes={{ root: classes.contentRowTitle }}
            >
              {formatMessage({ id: 'category_title' })}
            </Typography>
            <CustomSelect
              value={category}
              onChange={handleCategory}
              itemsList={categoryList}
              width={269}
              paperWidth={269}
              papperML={0}
              ml={0}
              height={37}
            />

            <Typography
              fontSize={fontSizes.md}
              marginLeft={'10px'}
              classes={{ root: classes.result }}
            >
              {`${recipes?.length} ${formatMessage({
                id: 'results_title',
              })}`}
            </Typography>
          </Box>
          <Box
            className={classNames(classes.contentRow, classes.contentBottomRow)}
          >
            <Typography
              fontSize={fontSizes.md}
              classes={{ root: classes.contentRowTitle }}
            >
              {formatMessage({ id: 'search_result_title' })}
            </Typography>
            <SearchComponent
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              handleSearch={handleSearch}
            />
          </Box>
        </Box>
      </ModalLayout>
    </>
  );
};

export default SearchModal;
