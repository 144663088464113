import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import pallete from '../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    relativeContainer: {
      position: 'relative',
    },
    drodownContainer: {
      position: 'absolute',
      top: '50%',
      marginTop: '2rem',
      left: '-18px',
      width: 'auto',
      minWidth: 'auto',
      height: 'auto',
      color: pallete.textSecondary,
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: pallete.textSecondary,
      boxShadow: '3px 4px 4px rgba(0, 0, 0, 0.25)',
    },

    contentContainer: {
      flex: 1,
      display: 'flex',
      flexBasis: '100%',
      overflow: 'hidden',
    },
    listItemCompact: {
      padding: 3,
    },
    list: {
      padding: 0,
      width: 210,
    },
    listItem: {
      padding: '10px 5px!important',
      borderLeft: '4px solid #fff',
      '& svg': {
        marginLeft: 10,
      },
      '& *': {
        color: pallete.textMenuSecondary,
      },
      '&:hover': {
        borderLeft: '4px solid #3949AB',
        color: pallete.textMenuPrimary,
        cursor: 'pointer',
        backgroundColor: pallete.menuHoverBg,
        '& *': {
          color: pallete.textMenuPrimary,
        },
        '& p': {
          fontWeight: fontWeights.BOLD,
        },
      },
      '&>p': {
        fontFamily: 'Open Sans',
        fontWeight: fontWeights.REGUlAR,
        fontSize: fontSizes.sm,
        color: pallete.textMenuSecondary,
      },
    },

    textItemCompact: {
      padding: 3,
      margin: 3,
    },
  }))();

export default useStyles;
