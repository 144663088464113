import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const CustomCheckBox = withStyles({
  root: {
    color: '#ABABAB',
    '&$checked': {
      color: '#BABC16',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

export default CustomCheckBox;
