import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const useStyles = ({ isHasContent, isHasAccardeon }) =>
  makeStyles(() => ({
    screenContainer: {
      width: '100vw',
      height: '100vh',
      minWidth: 1024,
      backgroundColor: pallete.secondary2,
      padding: 6,
    },
    contentWrapper: {
      marginTop: 6,
      display: 'flex',
      height: 'calc(100% - 64px)',
    },
    pagesWrapper: {
      width: isHasAccardeon ? 'calc(100% - 280px)' : '100%',
    },
    accardeonWrapper: {
      width: !isHasContent ? '100%' : 280,
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }))();

export default useStyles;
