import { setActiverecipes } from '../redux/reducers/productionLists';

const getNewValues = (formValues) => {
  const selectSettings = formValues?.selectValue;
  switch (selectSettings) {
    case 'amount products':
      return {
        ingridients: formValues.ingridients,
        variant: 'ingridients',
      };
    case 'complete sum':
      return {
        completeSum: formValues.completeSum,
        variant: 'sum',
      };
    case 'x-time the base recipe':
      return {
        xValue: formValues.xValue,
        variant: 'x-value',
      };
  }
};

export const isModalEditRecipeAction = (
  values,
  activeRecipe,
  formValues,
  setFieldValue,
  dispatch,
) => {
  let newRecipesValue = values?.recipes?.map((item) => {
    return { ...item };
  });

  const recipeIndex = newRecipesValue
    .map((item) => item.id)
    .indexOf(activeRecipe.id);

  const newValues = getNewValues(formValues);

  if (formValues?.selectValue === 'amount products') {
    newRecipesValue[recipeIndex].ingridients = formValues?.ingridients;
    newRecipesValue[recipeIndex].variant = 'ingridients';
  }

  if (formValues?.selectValue === 'complete sum') {
    newRecipesValue[recipeIndex].completeSum = formValues?.completeSum;
    newRecipesValue[recipeIndex].variant = 'sum';
  }

  if (formValues?.selectValue === 'x-time the base recipe') {
    newRecipesValue[recipeIndex].xValue = formValues?.xValue;
    newRecipesValue[recipeIndex].variant = 'x-value';
  }

  setFieldValue('recipes', newRecipesValue);

  dispatch(
    setActiverecipes({
      activeRecipes: {
        ...activeRecipe,
        ...newValues,
      },
    }),
  );
};
