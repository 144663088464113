import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';
import pallete from '../../../theme/pallete';

const useStyles = ({ pinValue, maxInputLength, isValid, pinError }) =>
  makeStyles(() => ({
    enterPinWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 43,
    },
    btn: {
      marginLeft: 7.5,
      marginRight: 7.5,
      width: 75,
      height: 72,
      border: '1px solid #000000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: fontSizes.xxl,
      borderRadius: 7,
      backgroundColor: pallete.secondary1,
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        backgroundColor: pallete.buttonBg,
      },
    },
    pinInput: {
      marginRight: '17.5px !important',
      marginLeft: '7.5px !important',
      '& input': {
        '-webkit-text-fill-color': '#000!important',
        height: 84,
        width: 338,
        fontSize: fontSizes.xxxl,
        padding: 0,
        textAlign: 'center',
      },
      '& fieldset': {
        border: `3px solid ${pinError ? 'red' : '#000'}!important`,
        borderRadius: 7,
      },
    },
    keyboardBtnsWrapper: {
      margin: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      width: 450,
    },
    keyboardBtn: {
      marginBottom: 18,
      opacity: pinValue.length === maxInputLength ? 0.5 : 1,
    },
    validBtn: {
      opacity: isValid ? 1 : 0.5,
    },
  }))();

export default useStyles;
