import React from 'react';

const ProductLogIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 5V7'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 14H21'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 14H5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4142 11.5858C14.1952 12.3669 14.1952 13.6332 13.4142 14.4142C12.6331 15.1953 11.3668 15.1953 10.5858 14.4142C9.80471 13.6332 9.80471 12.3669 10.5858 11.5858C11.3668 10.8048 12.6331 10.8048 13.4142 11.5858'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 5V7'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 14H19'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 14H3'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.41 9.59001L13.41 11.59'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4142 11.5858C14.1952 12.3669 14.1952 13.6332 13.4142 14.4142C12.6331 15.1953 11.3668 15.1953 10.5858 14.4142C9.80471 13.6332 9.80471 12.3669 10.5858 11.5858C11.3668 10.8048 12.6331 10.8048 13.4142 11.5858'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.319 19H5.67901C4.92901 19 4.22301 18.591 3.89701 17.916C3.18101 16.438 2.85601 14.734 3.06201 12.94C3.53201 8.83401 6.87901 5.50401 10.987 5.05501C16.411 4.46301 21 8.69501 21 14C21 15.399 20.68 16.723 20.11 17.903C19.782 18.584 19.074 19 18.319 19V19Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ProductLogIcon;
