import { Box } from '@material-ui/system';
import classNames from 'classnames';
import { Field, FieldArray, Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import ActionBtn from '../../components/ContentAccordion/ActionBtn/ActionBtn';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import CalcIcon from '../../components/icons/CalcIcon';
import CloseIcon from '../../components/icons/CloseIcon';
import CompleteIcon from '../../components/icons/CompleteIcon';
import CalcModal from '../../components/Modals/CalcModal/CalcModal';
import { MEASUREMENT_SELECT_OPTIONS } from '../../config/constants';
import { isModalEditRecipeAction } from '../../utils/editRecipesActions';
import { getEditRecipeTotalWeight } from '../../utils/getRecipeTotalWeight';
import useStyles from './styles';

const getSettingValue = (variant) => {
  switch (variant) {
    case 'ingridients':
      return 'amount products';
    case 'sum':
      return 'complete sum';
    case 'x-value':
      return 'x-time the base recipe';
  }
};

const EditRecipe = ({
  isModal = false,
  activeRecipe,
  setIsEdit,
  values,
  setFieldValue,
  editRecipeAction,
  loading = false,
}) => {
  const [changeValueName, setChangeValueName] = useState('');
  const [changeValueIndex, setChangeValueIndex] = useState('');
  const [isCalcOpen, setIsCalcOpen] = useState(false);

  const { formatMessage } = useIntl();
  const classes = useStyles({ isModal, loading });

  const dispatch = useDispatch();

  const initialValues = {
    ingridients: activeRecipe?.ingridients,
    completeSum: activeRecipe?.completeSum,
    xValue: activeRecipe?.xValue,
    baseWight: activeRecipe?.baseWight,
    name: activeRecipe?.name,
    selectValue: getSettingValue(activeRecipe?.variant),
  };

  const onSubmit = (formValues) => {
    if (isModal) {
      isModalEditRecipeAction(
        values,
        activeRecipe,
        formValues,
        setFieldValue,
        dispatch,
      );
      setIsEdit(false);
    } else {
      editRecipeAction(formValues);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit, resetForm }) => (
        <Box
          className={classNames(
            classes.wrapper,
            isModal ? classes.editWrapper : null,
          )}
        >
          <Box className={classes.content}>
            <Box className={classes.editPageWrapper}>
              <Box className={classes.editRow} marginBottom={'20px'}>
                <Box
                  className={classNames(
                    classes.editHeaderElem,
                    classes.editHeaderTitle,
                  )}
                >
                  {activeRecipe?.name}
                </Box>
                <Box
                  className={classNames(
                    classes.editHeaderElem,
                    classes.editHeaderWeight,
                  )}
                >
                  {`${
                    isNaN(
                      getEditRecipeTotalWeight(
                        {
                          ingridients: values.ingridients,
                          completeSum: values.completeSum,
                          xValue: values.xValue,
                          baseWight: values.baseWight,
                        },
                        values.selectValue,
                      )?.toFixed(3),
                    )
                      ? 'Error'
                      : getEditRecipeTotalWeight(
                          {
                            ingridients: values.ingridients,
                            completeSum: values.completeSum,
                            xValue: values.xValue,
                            baseWight: values.baseWight,
                          },
                          values.selectValue,
                        )?.toFixed(3) + 'kg'
                  }`}
                </Box>
              </Box>
              <Box className={classes.editRow}>
                <Box className={classes.editSelect}>
                  <CustomSelect
                    value={values.selectValue}
                    onChange={(event) => {
                      setFieldValue('selectValue', event.target.value);
                    }}
                    itemsList={MEASUREMENT_SELECT_OPTIONS}
                    height={37}
                    width={'100%'}
                    ml={0}
                    papperML={0}
                    paperWidth={'100%'}
                  />
                </Box>
                <Box
                  className={classes.resetSelectBtnWrapper}
                  onClick={resetForm}
                >
                  <CloseIcon />
                  <Box component='p'>
                    {formatMessage({ id: 'reset_title' })}
                  </Box>
                </Box>
              </Box>

              {values.selectValue === 'amount products' ? (
                <Box className={classes.ingridientsRowsWrapper}>
                  <FieldArray name={'ingridients'}>
                    {(arrayHelpers) => (
                      <>
                        {values?.ingridients?.map((item, index) => (
                          <Box
                            key={index}
                            className={classNames(
                              classes.editRow,
                              classes.ingridientRow,
                            )}
                          >
                            <Box
                              className={classNames(
                                classes.infridientText,
                                classes.infridientTittle,
                              )}
                            >
                              {item?.name}
                            </Box>
                            <Field
                              name={`ingridients.${index}.count`}
                              type='text'
                              className={classes.ingridientInput}
                              onFocus={() => {
                                setChangeValueName(`ingridients`);
                                setChangeValueIndex(+index);
                              }}
                            />
                            <Box
                              className={classNames(
                                classes.infridientText,
                                classes.ingridientMeasure,
                              )}
                            >
                              {item?.measure}
                            </Box>
                            <Box
                              className={classes.deleteBtn}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <CloseIcon />
                            </Box>
                          </Box>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Box>
              ) : null}

              {values.selectValue === 'complete sum' ? (
                <Box className={classes.completeSumWrapper}>
                  <Box className={classes.completeSumTitle}>
                    {formatMessage({ id: 'complete_sum' })}
                  </Box>
                  <Field
                    name={`completeSum`}
                    type='text'
                    className={classes.completeSumValue}
                    onFocus={() => {
                      setChangeValueName('completeSum');
                    }}
                  />
                  <Box
                    className={classes.deleteBtn}
                    onClick={() => {
                      setFieldValue('completeSum', 0);
                    }}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              ) : null}

              {values.selectValue === 'x-time the base recipe' ? (
                <>
                  <Box className={classes.recipesBaseWeightTitle}>
                    {`weight base recipe = ${values.baseWight} kg`}
                  </Box>
                  <Box className={classes.completeSumWrapper}>
                    <Box className={classes.completeSumTitle}>
                      {formatMessage({ id: 'x_time_base_pecipe' })}
                    </Box>
                    <Field
                      name={`xValue`}
                      type='text'
                      className={classNames(
                        classes.completeSumValue,
                        classes.xValueInput,
                      )}
                      onFocus={() => {
                        setChangeValueName('xValue');
                      }}
                    />
                    <Box
                      className={classes.deleteBtn}
                      onClick={() => {
                        setFieldValue('xValue', 0);
                      }}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
          <Box
            style={{ display: 'flex', alignItems: 'flex-end' }}
            className={classes.btnsWrapper}
          >
            <ActionBtn
              isActive={isCalcOpen}
              Icon={CalcIcon}
              action={() => changeValueName && setIsCalcOpen(true)}
              isDisable={!changeValueName}
              marginRight='auto'
            />
            <Box className={classes.cancelBtn} onClick={() => setIsEdit(false)}>
              {formatMessage({ id: 'cancel_title' })}
            </Box>
            <Box
              className={classes.okBtn}
              onClick={() => {
                !loading && handleSubmit();
              }}
            >
              <Box> {formatMessage({ id: 'ok_title' })}</Box>
              <CompleteIcon />
            </Box>
          </Box>
          <CalcModal
            open={isCalcOpen}
            onClose={() => {
              setIsCalcOpen(false);
              setChangeValueName('');
            }}
            changeValueName={changeValueName}
            setFieldValue={setFieldValue}
            values={values}
            changeValueIndex={changeValueIndex}
          />
        </Box>
      )}
    </Formik>
  );
};

export default EditRecipe;
