import React from 'react';

const SearchGlass = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6182 20.3428C12.9241 20.3428 15.0505 19.6326 16.7902 18.449L23.3351 24.6826C23.6389 24.9719 24.0393 25.1166 24.4673 25.1166C25.3648 25.1166 26 24.459 26 23.6174C26 23.2228 25.8619 22.8415 25.5582 22.5653L19.0547 16.358C20.4217 14.6484 21.2363 12.5311 21.2363 10.2297C21.2363 4.66681 16.4588 0.116577 10.6182 0.116577C4.79129 0.116577 0 4.65366 0 10.2297C0 15.7925 4.77748 20.3428 10.6182 20.3428ZM10.6182 18.1597C6.0616 18.1597 2.29209 14.5695 2.29209 10.2297C2.29209 5.88985 6.0616 2.29964 10.6182 2.29964C15.1747 2.29964 18.9442 5.88985 18.9442 10.2297C18.9442 14.5695 15.1747 18.1597 10.6182 18.1597Z'
        fill='#3C3C43'
        fillOpacity='0.6'
      />
    </svg>
  );
};

export default SearchGlass;
