import React, { createContext, Fragment } from 'react';
import { IntlProvider as ReactIntilProvider } from 'react-intl';

import messages from '../i18n/data';
import { useLanguageContext } from './LanguageContext.jsx';

const IntlContext = createContext();

const IntlProvider = ({ children }) => {
  const { currentLanguage } = useLanguageContext();

  return (
    <IntlContext.Provider value={currentLanguage.value}>
      <ReactIntilProvider
        locale={currentLanguage.value}
        textComponent={Fragment}
        messages={messages[currentLanguage.value]}
      >
        {children}
      </ReactIntilProvider>
    </IntlContext.Provider>
  );
};

export { IntlContext, IntlProvider };
