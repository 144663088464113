import React from 'react';

const inProgressIcon = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.72 2.88004C1.92 2.64004 2.08 2.32004 2.36 2.12004C2.84 1.84004 3.32 2.00004 3.76 2.32004C4.96 3.20004 6.12 4.12004 7.32 5.00004C12.28 8.72004 17.24 12.44 22.2 16.16C23.04 16.8 23.04 17.64 22.2 18.28C16.04 22.88 9.92 27.48 3.76 32.12C3.6 32.24 3.4 32.36 3.2 32.48C3 32.48 2.8 32.48 2.6 32.48C2.16 32.32 1.88 32 1.72 31.6C1.72 22 1.72 12.44 1.72 2.88004Z'
        fill='currentColor'
      />
      <path
        d='M28.4 32.48C27.72 32.2 27.52 31.68 27.52 30.96C27.52 21.76 27.52 12.56 27.52 3.36C27.52 2.64 27.8 2.2 28.4 2.04C28.52 2 28.6 2 28.72 2C29.48 2 30.24 2 31 2C31.72 2 32.2 2.48 32.2 3.2C32.2 12.56 32.2 21.88 32.2 31.24C32.2 31.88 31.84 32.24 31.32 32.48C30.36 32.48 29.4 32.48 28.4 32.48Z'
        fill='currentColor'
      />
      <path
        d='M1.72 31.5601C1.88 32.0001 2.2 32.2801 2.6 32.4401C2.32 32.4401 2 32.4401 1.72 32.4401C1.72 32.1601 1.72 31.8801 1.72 31.5601Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default inProgressIcon;
