export const getRecipeVariantValueByGet = (value) => {
  switch (value) {
    case 'amount of products from this recipe':
      return 'ingridients';
    case 'x-time the base recipe':
      return 'x-value';
    case 'complete sum':
      return 'sum';
  }
};

export const getRecipesList = (data) => {
  let recipes = [];

  data?.forEach((recipe) => {
    recipes.push({
      name: recipe?.recipe?.name,
      group: recipe?.recipe?.group,
      order: recipe?.order,
      id: recipe?.recipe?.id,
      transferId: recipe?.id,
      baseWight: recipe?.baseWeight,
      xValue: recipe?.count,
      variant: getRecipeVariantValueByGet(recipe?.typeMeasurements),
      completeSum: recipe?.weight,
      recipeListId: recipe?.id,
      ingridients: recipe?.amount?.map((ingredient) => {
        return {
          name: ingredient?.name,
          weight: ingredient?.weight,
          count: ingredient?.amount,
          measure: ingredient?.unit?.fullName,
          measureId: ingredient?.unit?.id,
          id: +ingredient?.id,
          recipeItemId: +ingredient?.recipeItemId,
        };
      }),
    });
  });

  return recipes;
};
