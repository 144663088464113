import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import SpinnerLogo from '../../Spinner/SpinnerLogo';
import useStyles from './styles';

const ProductionListLayout = ({ children, title, loading = false }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  if (loading) {
    return <SpinnerLogo fullPage={true} />;
  }

  return (
    <Box className={classes.wrapper}>
      <Typography fontSize={fontSizes.lg} fontWeight={fontWeights.BOLD}>
        {formatMessage({ id: title })}
      </Typography>
      <Box className={classes.contentWrapper}>{children}</Box>
    </Box>
  );
};

export default ProductionListLayout;
