import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { MENU_OPTIONS, ROUTES } from '../../config/constants';
import { useAccordionContext } from '../../context/AccordionContext';
import { useAuthContext } from '../../context/AuthContext';
import useStyles from './styles';

const MenuDropdown = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const { push } = useHistory();
  const { setActiveTab } = useAccordionContext();
  const { isAuth } = useAuthContext();

  const classes = useStyles();

  const handleChange = (e, listItem) => {
    e.stopPropagation();

    if (listItem.value === 'hide_menu') {
      setVisible(false);
    } else if (listItem.value === 'production_list') {
      setActiveTab('');
      setVisible(false);
      push(ROUTES.productionLists);
    } else {
      console.log('123');
    }
  };

  return (
    <div className={classes.relativeContainer}>
      <Box onClick={() => isAuth && setVisible(!visible)}>{children}</Box>
      {visible && (
        <Box className={classes.drodownContainer}>
          <Box className={classes.contentContainer}>
            <List classes={{ root: classes.list }}>
              {MENU_OPTIONS.map((listItem, index) => {
                const Icon = listItem?.icon;
                const title = listItem?.title;
                return (
                  <ListItem
                    key={index}
                    classes={{
                      root: classes.listItem,
                    }}
                    onClick={(e) => {
                      handleChange(e, listItem);
                    }}
                  >
                    {Icon && (
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                    )}
                    {title && (
                      <ListItemText
                        secondary={listItem.title}
                        classes={{ container: classes.textItem }}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default MenuDropdown;
