import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import pallete from '../../../theme/pallete';

const useStyles = ({ isValidRecipeValue, loading }) =>
  makeStyles(() => ({
    wrapper: {
      backgroundColor: pallete.textSecondary,
      padding: 35,
      width: 568,
      margin: '0 auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    btnsWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      width: 111,
      height: 23,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.BOLD,
      background: pallete.buttonBg,
      border: '1px solid #000000',
      borderRadius: 7,
      cursor: 'pointer',
      marginRight: 24,
    },
    okBtn: {
      width: 138,
      height: 46,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: fontSizes.exrta32,
      fontWeight: fontWeights.BOLD,
      background: pallete.buttonBg,
      border: '1px solid #000000',
      borderRadius: 7,
      cursor: 'pointer',
      textTransform: 'uppercase',
      paddingLeft: 14,
      paddingRight: 14,
      opacity: isValidRecipeValue || loading ? 0.4 : 1,
    },
  }))();

export default useStyles;
