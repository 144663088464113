import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';
import pallete from '../../../theme/pallete';

const useStyles = ({ isValid }) =>
  makeStyles(() => ({
    userInfoWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,
      marginBottom: 30,
      '& img': {
        width: 50,
        height: 50,
        marginRight: 24,
        borderRadius: '50%',
      },
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    enterPasswordWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    passwordInput: {
      marginRight: '17.5px !important',
      marginLeft: '7.5px !important',
      '& input': {
        height: 47,
        width: 356,
        fontSize: fontSizes.lg,
        padding: 0,
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
      },
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: 7,
      },
    },
    btn: {
      marginLeft: 7.5,
      marginRight: 7.5,
      width: 42,
      height: 40,
      border: '1px solid #000000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 7,
      backgroundColor: pallete.secondary1,
      opacity: isValid ? 1 : 0.5,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: pallete.buttonBg,
      },
    },
  }))();

export default useStyles;
