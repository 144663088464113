import React from 'react';

const KeyboardIcon = () => {
  return (
    <svg
      width='17'
      height='14'
      viewBox='0 0 17 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1.98333 1.9631H3.11667V3.08487H1.98333V1.9631Z' fill='black' />
      <path d='M5.1 1.9631H3.96667V3.08487H5.1V1.9631Z' fill='black' />
      <path d='M5.95 1.9631H7.08333V3.08487H5.95V1.9631Z' fill='black' />
      <path d='M11.05 1.9631H9.91667V3.08487H11.05V1.9631Z' fill='black' />
      <path d='M13.8833 1.9631H15.0167V3.08487H13.8833V1.9631Z' fill='black' />
      <path d='M13.0333 1.9631H11.9V3.08487H13.0333V1.9631Z' fill='black' />
      <path d='M7.93333 1.9631H9.06667V3.08487H7.93333V1.9631Z' fill='black' />
      <path d='M3.11667 3.87013H1.98333V4.9919H3.11667V3.87013Z' fill='black' />
      <path d='M3.96667 3.87013H5.1V4.9919H3.96667V3.87013Z' fill='black' />
      <path d='M7.08333 3.87013H5.95V4.9919H7.08333V3.87013Z' fill='black' />
      <path d='M9.91667 3.87013H11.05V4.9919H9.91667V3.87013Z' fill='black' />
      <path d='M15.0167 3.87013H13.8833V4.9919H15.0167V3.87013Z' fill='black' />
      <path d='M11.9 3.87013H13.0333V4.9919H11.9V3.87013Z' fill='black' />
      <path d='M9.06667 3.87013H7.93333V4.9919H9.06667V3.87013Z' fill='black' />
      <path d='M1.98333 5.8893H3.11667V7.01107H1.98333V5.8893Z' fill='black' />
      <path d='M13.0333 5.8893H3.96667V7.01107H13.0333V5.8893Z' fill='black' />
      <path d='M13.8833 5.8893H15.0167V7.01107H13.8833V5.8893Z' fill='black' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.566667 0C0.253727 0 0 0.251105 0 0.560886V8.41329C0 8.72307 0.253727 8.97418 0.566667 8.97418H16.4333C16.7463 8.97418 17 8.72307 17 8.41329V0.560886C17 0.251105 16.7463 0 16.4333 0H0.566667ZM0.85 0.841329V8.13285H16.15V0.841329H0.85Z'
        fill='black'
      />
      <path
        d='M8.5 12.9824L6.07756 10.6347L5.55322 11.143L8.50111 14L11.4465 11.143L10.9222 10.6347L8.5 12.9824Z'
        fill='black'
      />
    </svg>
  );
};

export default KeyboardIcon;
