export const getIngridientsTotalWeight = (ingridients) => {
  return ingridients?.reduce((acc, item) => {
    return (acc += +item?.weight * +item?.count);
  }, 0);
};

export const getRecipeTotalWeight = (recipe) => {
  const { variant, ingridients, completeSum, baseWight, xValue } = recipe;

  switch (variant) {
    case 'ingridients':
      return getIngridientsTotalWeight(ingridients);
    case 'sum':
      return +completeSum;
    case 'x-value':
      return +baseWight * +xValue;
  }
};

export const getEditRecipeTotalWeight = (recipe, selectValue) => {
  const { ingridients, completeSum, baseWight, xValue } = recipe;

  switch (selectValue) {
    case 'amount products':
      return ingridients?.reduce((acc, item) => {
        return (acc += +item?.weight * +item?.count);
      }, 0);
    case 'complete sum':
      return +completeSum;
    case 'x-time the base recipe':
      return +baseWight * +xValue;
  }
};
