import React from 'react';

const SortIcon = () => {
  return (
    <svg
      width='35'
      height='39'
      viewBox='0 0 35 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0012 13.6635C12.0012 17.5705 12.0012 21.5258 12.0012 25.4329C12.0012 25.9635 12.0977 26.1564 12.6765 26.1082C13.4482 26.06 14.22 26.06 14.9435 26.1082C15.7635 26.1082 16.1012 26.7353 15.6671 27.4105C13.4 30.787 11.133 34.2117 8.81766 37.5882C8.38354 38.2153 7.85296 38.2153 7.41884 37.5882C5.15178 34.2117 2.83648 30.8353 0.61766 27.4105C0.183542 26.7353 0.472954 26.1564 1.34119 26.1082C2.11295 26.1082 2.93295 26.06 3.70472 26.1082C4.18707 26.1564 4.28354 25.9635 4.28354 25.4811C4.28354 21.7188 4.28354 17.9564 4.28354 14.2423C4.28354 10.1905 4.28354 6.13879 4.28354 2.08702C4.28354 0.977609 4.47648 0.784668 5.5859 0.784668C7.3706 0.784668 9.15531 0.784668 10.94 0.784668C11.76 0.784668 12.0494 1.02584 12.0494 1.89408C12.0012 5.84937 12.0012 9.75643 12.0012 13.6635Z'
        fill='currentColor'
      />
      <path
        d='M27.0506 13.2293C26.3753 13.2293 25.7 13.2775 24.9765 13.2293C24.4459 13.1811 24.2047 13.3258 24.06 13.9046C23.8671 14.6764 23.5777 15.4481 23.3847 16.2199C23.24 16.7987 22.9024 17.0399 22.3236 17.0399C21.793 17.0399 21.2624 17.0399 20.6836 17.0399C19.6224 17.0399 19.333 16.654 19.6706 15.6893C21.0212 11.8787 22.3718 8.06813 23.6741 4.30577C23.9636 3.43754 24.3012 2.5693 24.5906 1.70107C24.7353 1.21872 25.073 0.977539 25.5553 0.977539C26.52 0.977539 27.4847 0.977539 28.4012 0.977539C28.8836 0.977539 29.173 1.17048 29.3177 1.65283C31.0059 6.42813 32.6941 11.2034 34.3341 15.9787C34.5753 16.654 34.2377 17.0881 33.5624 17.0881C32.9353 17.0881 32.3083 17.0881 31.633 17.0881C31.0541 17.0881 30.7647 16.847 30.62 16.3164C30.3788 15.4964 30.0894 14.6764 29.8965 13.8564C29.8 13.374 29.5588 13.2775 29.1247 13.2775C28.4977 13.2775 27.7741 13.2293 27.0506 13.2293ZM27.0989 4.2093C27.0506 4.25754 26.9541 4.25754 26.9541 4.30577C26.3753 6.13872 25.8447 7.92342 25.2659 9.75636C25.1694 10.094 25.2659 10.1905 25.6036 10.1905C26.6647 10.1905 27.7741 10.1905 28.8836 10.1905C28.353 8.1646 27.7259 6.18695 27.0989 4.2093Z'
        fill='currentColor'
      />
      <path
        d='M28.4494 25.4811C26.6647 25.4811 24.9765 25.4811 23.2883 25.4811C21.2141 25.4811 21.1177 25.3846 21.2624 23.3105C21.3106 22.7317 21.5518 22.4423 22.1306 22.4423C25.4106 22.4423 28.6906 22.4423 31.9706 22.4423C32.5012 22.4423 32.7906 22.7317 32.8388 23.214C32.8871 24.034 32.8388 24.8058 32.3082 25.5776C30.0894 28.6646 27.9671 31.7999 25.7965 34.887C25.7 35.0317 25.5553 35.1281 25.5553 35.3211C25.7483 35.4658 26.0377 35.4176 26.2306 35.4176C28.16 35.4176 30.0412 35.4176 31.9706 35.4176C32.7906 35.4176 33.0318 35.6587 33.0318 36.4787C33.08 38.4081 33.08 38.4564 31.1024 38.4564C28.16 38.4564 25.1694 38.4564 22.2271 38.4564C21.2141 38.4564 21.0694 38.2152 21.0212 37.2505C20.973 36.4787 21.2141 35.8517 21.6483 35.2246C23.7706 32.234 25.893 29.147 28.0153 26.1564C28.16 25.9634 28.2565 25.7705 28.4494 25.4811Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default SortIcon;
