import { useLazyQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { ROUTES } from '../config/constants';
import { useAccordionContext } from '../context/AccordionContext';
import { setCrossToken, useAuthContext } from '../context/AuthContext';
import {
  ADD_RECIPE_TO_FAVORITE_QUERY,
  ADD_RECIPE_TO_PRODUCTION_LIST_QUERY,
  CROSS_AUTH_QUERY,
  DELETE_PRODUCT_LIST_QUERY,
  DELETE_RECIPE_QUERY,
  DUBLICATE_PRODUCT_LIST_QUERY,
  EDITE_SETTINGS_QUERY,
  GET_CATEGORIES_QUERY,
  GET_PRODUCTION_LIST_QUERY,
  GET_RECIPE_BY_ID_QUERY,
  PRODUCTION_LIST_QUERY,
  RECIPES_QUERY,
  SEARCH_RECIPES_QUERY,
  SIGN_VIA_PASSWORD_QUERY,
  SIGN_VIA_PIN_QUERY,
  TRANSFER_TAB_QUERY,
  USER_ALL_DATABASES_QUERY,
  USERS_DATAREFERENCE_QUERY,
} from '../graphql/queries';
import {
  setProductionLists,
  setQueryCount as setQueryCountProductList,
  setTimeSpanFinish,
  setTimeSpanStart,
} from '../redux/reducers/productionLists';
import { setActiveRecipe, setRecipes } from '../redux/reducers/recipes';
import {
  setActiveToDo,
  setQueryCount as setQueryCountToDo,
  setToDo,
} from '../redux/reducers/toDo';
import { getRecipesList } from '../utils/getRecipesList';

export const useCrossAuthQuery = () => {
  const { push } = useHistory();
  const [allDBQuery] = useUserAllDatabassesQuery();

  return useLazyQuery(CROSS_AUTH_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: async (response) => {
      if (response?.crossAuth?.token) {
        let token = response.crossAuth.token;
        setCrossToken(token);
        allDBQuery();
        push(ROUTES.loginToSpace);
      }
    },
  });
};

export const useUserAllDatabassesQuery = () => {
  const { formatMessage } = useIntl();
  const { setAllDBsForUser } = useAuthContext();
  return useLazyQuery(USER_ALL_DATABASES_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.getUserAllDatabases?.databases) {
        setAllDBsForUser(response?.getUserAllDatabases?.databases);
      }
    },
    onError: (error) => {
      if (error.message === 'User not found.') {
        toast(formatMessage({ id: 'user_not_found' }), {
          type: 'error',
        });
      }
    },
  });
};

export const useSignViaPinQuery = () => {
  const { handleLogin, finishLoading, setPINError } = useAuthContext();
  return useLazyQuery(SIGN_VIA_PIN_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { token, user } = response?.signViaPin;
      if (token) {
        handleLogin(token, user);
      }
    },
    onError: () => {
      setPINError();
      finishLoading();
    },
  });
};

export const useSignViaPasswordQuery = () => {
  const dispatch = useDispatch();
  const { handleLogin, finishLoading } = useAuthContext();
  const { formatMessage } = useIntl();
  return useLazyQuery(SIGN_VIA_PASSWORD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { token, user } = response?.signIn;
      if (token) {
        handleLogin(token, user);

        if (!user?.settings) {
          dispatch(setTimeSpanStart({ timeSpanStart: 'all' }));
          dispatch(setTimeSpanFinish({ timeSpanFinish: 'all' }));
        } else {
          dispatch(
            setTimeSpanStart({
              timeSpanStart: user?.settings?.settings?.settingSearchPast,
            }),
          );
          dispatch(
            setTimeSpanFinish({
              timeSpanFinish: user?.settings?.settings?.settingSearchFuture,
            }),
          );
        }
      }
    },
    onError: () => {
      finishLoading();
      toast(formatMessage({ id: 'incorect_password' }), {
        type: 'error',
      });
    },
  });
};

export const useUsersDarareferenceQuery = () => {
  const { setAllUsersForDB } = useAuthContext();
  return useLazyQuery(USERS_DATAREFERENCE_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.getUsersDatareference) {
        setAllUsersForDB(response?.getUsersDatareference);
      }
    },
  });
};

export const useProductionListQuery = () => {
  const { setProductList } = useAccordionContext();
  return useLazyQuery(PRODUCTION_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.getProductionListsDatabase) {
        setProductList(response?.getProductionListsDatabase);
      }
    },
  });
};

export const useGetRecipes = () => {
  const dispatch = useDispatch();
  return useLazyQuery(RECIPES_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      dispatch(setRecipes({ recipes: response.getRecipes?.data?.rows }));
    },
  });
};

export const useGetSearchRecipes = (state) => {
  const dispatch = useDispatch();
  return useLazyQuery(SEARCH_RECIPES_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      dispatch(
        state === 'todo'
          ? setToDo({
              toDo: getRecipesList(response?.searchRecipesInProductionList),
            })
          : null,
      );
    },
  });
};

export const useCategories = () => {
  return useLazyQuery(GET_CATEGORIES_QUERY, {
    fetchPolicy: 'network-only',
    // onCompleted: (response) => {
    //   if (response?.getRecipesGroupByDatabaseId) {
    //     console.log(response?.getRecipesGroupByDatabaseId);
    //   }
    // },
  });
};

export const useTransferTab = (lastState, newState) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  return useLazyQuery(TRANSFER_TAB_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response) {
        if (lastState === 'todo') {
          dispatch(setActiveToDo({ activeToDo: null }));
          dispatch(setQueryCountToDo());
        }

        toast(
          formatMessage({
            id:
              newState === 'inProgress'
                ? 'success_added_recipe_in_in_progress'
                : null,
          }),
          {
            type: 'success',
          },
        );
      }
    },
  });
};

export const useAddToDoRecipeToProductionList = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  return useLazyQuery(ADD_RECIPE_TO_PRODUCTION_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response) {
        dispatch(setQueryCountToDo());
        dispatch(setActiveRecipe({ activeRecipe: null }));
        toast(formatMessage({ id: 'success_added_recipe_in_todo' }), {
          type: 'success',
        });
      }
    },
  });
};

export const useAddInProgressRecipeToProductionList = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  return useLazyQuery(ADD_RECIPE_TO_PRODUCTION_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response) {
        dispatch(setActiveRecipe({ activeRecipe: null }));
        toast(formatMessage({ id: 'success_added_recipe_in_in_progress' }), {
          type: 'success',
        });
      }
    },
  });
};

export const useAddRecipeToFavorite = () => {
  return useLazyQuery(ADD_RECIPE_TO_FAVORITE_QUERY, {
    fetchPolicy: 'network-only',
  });
};

export const useProductionListTableQuery = () => {
  const dispatch = useDispatch();
  return useLazyQuery(PRODUCTION_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.getProductionListsDatabase) {
        dispatch(
          setProductionLists({
            productionLists: response?.getProductionListsDatabase,
          }),
        );
      }
    },
  });
};

export const useDeleteRecipe = (state) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  return useLazyQuery(DELETE_RECIPE_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response) {
        if (state === 'todo') {
          dispatch(setQueryCountToDo());
          dispatch(setActiveToDo({ activeToDo: null }));
          toast(formatMessage({ id: 'success_deleted_recipe_in_todo' }), {
            type: 'success',
          });
        }
      }
    },
  });
};

export const useDublicateProducList = () => {
  const dispatch = useDispatch();
  return useLazyQuery(DUBLICATE_PRODUCT_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response) {
        dispatch(setQueryCountProductList());
      }
    },
  });
};

export const useDeleteProducList = () => {
  const dispatch = useDispatch();
  return useLazyQuery(DELETE_PRODUCT_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response) {
        dispatch(setQueryCountProductList());
      }
    },
  });
};

export const useEditeSittings = () => {
  const dispatch = useDispatch();
  return useLazyQuery(EDITE_SETTINGS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response) {
        dispatch(setQueryCountProductList());
      }
    },
  });
};

export const useGetProductionList = () => {
  // const dispatch = useDispatch();
  return useLazyQuery(GET_PRODUCTION_LIST_QUERY, {
    fetchPolicy: 'network-only',
    // onCompleted: (response) => {
    //   if (response) {
    //     dispatch(setQueryCountProductList());
    //   }
    // },
  });
};

export const useGetRecipeById = () => {
  // const dispatch = useDispatch();
  return useLazyQuery(GET_RECIPE_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    // onCompleted: (response) => {
    //   if (response) {
    //     dispatch(setQueryCountProductList());
    //   }
    // },
  });
};
