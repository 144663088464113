import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import create from 'zustand';

import { API_HOST } from '../config/constants';

export const useErrorsStore = create(() => ({
  hasError: false,
  error: null,
  date: null,
}));

const httpLink = createUploadLink({
  uri: `${API_HOST}/graphql`,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors[0],
      date: Date.now(),
    });
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token =
    localStorage.getItem('fstoken') || localStorage.getItem('fsCrossToken');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
