const pallete = {
  primary: '#B9BC16',
  secondary1: '#E9E9E9',
  secondary2: '#F8F8F8',
  textPrimary: '#000',
  textSecondary: '#fff',
  borderBlack: '#000',
  buttonBg: '#C4C4C4',
  buttonActiveBg: '#292F65',
  textMenuSecondary: '#828282',
  textMenuPrimary: '#3949AB',
  menuHoverBg: '#F3F4FC',
  selectBg: '#F4F4F4',
  modalBG: '#D3D6DB',

  correct: '#177A00',
  error: '#BC0900',
  attention2: '#BD7800',
  info: '#0EA7C9',
};

export default pallete;
