import React from 'react';

const ParkingIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.4259 35.2376C22.5318 35.2376 13.6377 35.2376 4.78589 35.2376C4.74354 35.1953 4.65883 35.1953 4.61648 35.1529C3.89648 35.0259 3.26118 34.7294 2.71059 34.2635C1.69412 33.3741 1.22824 32.2306 1.22824 30.9176C1.22824 27.7411 1.22824 24.5647 1.22824 21.3882C1.22824 15.967 1.22824 10.5035 1.22824 5.08233C1.22824 2.37174 3.34589 0.762329 5.54824 0.762329C13.9765 0.804682 22.4047 0.762329 30.833 0.762329C32.2729 0.762329 33.4165 1.35527 34.2635 2.54115C34.8141 3.30351 34.9835 4.19292 34.9835 5.16703C34.9835 10.207 34.9835 15.2894 34.9835 20.3294C34.9835 23.8447 34.9835 27.4023 34.9835 30.9176C34.9835 32.5694 34.3482 33.8823 32.8659 34.7294C32.4424 34.9835 31.8918 35.0682 31.4259 35.2376ZM32.4424 18.0423C32.4424 13.9764 32.4424 9.86821 32.4424 5.80233C32.4424 4.27762 31.5106 3.34586 29.9859 3.34586C22.0235 3.34586 14.1035 3.34586 6.14118 3.34586C5.88706 3.34586 5.59059 3.38821 5.33648 3.43056C4.27765 3.72703 3.68471 4.53174 3.68471 5.75998C3.68471 13.9341 3.68471 22.1082 3.68471 30.2823C3.68471 30.5364 3.68471 30.7906 3.76942 31.0023C4.06589 32.0611 4.87059 32.6541 6.01412 32.6541C14.0188 32.6541 22.0235 32.6541 30.0282 32.6541C30.24 32.6541 30.4941 32.6541 30.7059 32.5694C31.8071 32.2729 32.4 31.4259 32.4 30.24C32.4424 26.2164 32.4424 22.1506 32.4424 18.0423Z'
        fill='currentColor'
      />
      <path
        d='M30.8753 18.0424C30.8753 22.1083 30.8753 26.1318 30.8753 30.1977C30.8753 30.96 30.6635 31.1718 29.9012 31.1718C22.0235 31.1718 14.1459 31.1718 6.22589 31.1718C5.46353 31.1718 5.25177 30.96 5.25177 30.1553C5.25177 22.0659 5.25177 13.9765 5.25177 5.88708C5.25177 5.08237 5.46353 4.87061 6.26824 4.87061C14.1459 4.87061 22.0235 4.87061 29.9012 4.87061C30.6635 4.87061 30.8753 5.08237 30.8753 5.84472C30.8753 9.95296 30.8753 13.9765 30.8753 18.0424ZM16.0094 27.9106C16.0094 25.6235 16.0094 23.3788 16.0094 21.1341C16.0941 21.1341 16.1788 21.1341 16.2635 21.1341C18.0847 21.3035 19.8635 21.1765 21.5577 20.4988C22.8282 19.9906 23.8871 19.2283 24.5647 18.0424C25.3694 16.6871 25.5388 15.2047 25.3271 13.68C25.0729 11.9859 24.1835 10.673 22.6588 9.8259C21.3035 9.06355 19.7788 8.85178 18.2541 8.80943C16.2635 8.76708 14.2306 8.85178 12.24 9.1059C11.9012 9.14825 11.8165 9.23296 11.8165 9.57178C11.8165 15.5435 11.8165 21.5153 11.8165 27.4871C11.8165 27.6141 11.8165 27.7412 11.8165 27.9106C13.2141 27.9106 14.5694 27.9106 16.0094 27.9106Z'
        fill='currentColor'
      />
      <path
        d='M16.0094 17.7882C16.0094 15.8823 16.0094 14.0611 16.0094 12.1552C17.28 11.9435 18.5083 11.8588 19.7365 12.4094C20.6683 12.8329 21.2188 13.807 21.1341 14.9505C21.0494 16.4329 20.2024 17.407 18.7624 17.7458C17.873 17.9576 16.9412 17.9576 16.0094 17.7882Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ParkingIcon;
