import { TableSortLabel } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSort } from '../../../../../redux/reducers/productionLists';
import { productionListsSelector } from '../../../../../redux/selectors';
import useStyles from './styles';

const ProductionListTableHeaderItem = ({ title, mr = 0 }) => {
  const { sortBy, sortType } = useSelector(productionListsSelector);
  const dispatch = useDispatch();
  const classes = useStyles({ title, mr });

  const handleSort = (item) => {
    let sort;
    if (item !== sortBy) {
      sort = 'DESC';
    } else if (sortType === 'DESC') {
      sort = 'ASC';
    } else {
      sort = 'DESC';
    }

    dispatch(
      setSort({
        sortBy: item,
        sortType: sort,
      }),
    );
  };

  return (
    <Box className={classes.tableListHeaderItem}>
      <Box className={classes.cutText}>
        {' '}
        {title === 'name'
          ? 'name of production list'
          : title === 'forADay'
          ? 'date'
          : title}
      </Box>
      {title === 'name' || title === 'forADay' ? (
        <TableSortLabel
          active
          // direction={'desc'}
          direction={title === sortBy && sortType === 'DESC' ? 'desc' : 'asc'}
          onClick={() => handleSort(title)}
          className={classes.tableSortLabel}
        ></TableSortLabel>
      ) : null}
    </Box>
  );
};

export default ProductionListTableHeaderItem;
