import React from 'react';

const ArrowLeftStrongIcon = ({ width = 21, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3604 -1.15383e-07C18.6398 -1.03166e-07 18.9193 -9.09486e-08 19.1678 -8.0089e-08C19.1678 0.0614122 19.2299 0.030706 19.2609 0.0614122C19.882 0.214943 20.3479 0.583417 20.6273 1.13613C20.9379 1.71954 21 2.36437 21 3.0092C21 8.68984 21 14.3398 21 20.0204C21 20.481 20.9689 20.9109 20.8447 21.3408C20.5342 22.4155 19.7578 23.0296 18.6398 22.9989C17.9877 22.9989 17.3977 22.7225 16.8697 22.4155C11.8389 19.5598 6.77697 16.7041 1.74612 13.8485C1.34241 13.6335 1.00081 13.3879 0.690267 13.0501C-0.117154 12.2518 -0.210318 11.2384 0.37972 10.3173C0.752376 9.79526 1.2803 9.42679 1.80823 9.11972C5.72112 6.90888 9.60295 4.66733 13.5158 2.4872C15.0996 1.62743 16.5592 0.522004 18.3604 -1.15383e-07Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ArrowLeftStrongIcon;
