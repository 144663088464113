import React from 'react';

const HamburgerMenuIcon = () => {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='21' height='5' fill='#2A3066' />
      <rect y='8' width='21' height='5' fill='#2A3066' />
      <rect y='16' width='21' height='5' fill='#2A3066' />
    </svg>
  );
};

export default HamburgerMenuIcon;
