import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';

const useStyles = () =>
  makeStyles(() => ({
    wrapper: {
      width: '100%',
      height: 'calc(100vh - 340px)',
      overflowY: 'auto',
    },
    tableRowsWrapper: {
      paddingRight: 23,
    },
    tableRow: {
      display: 'flex',
      paddingTop: 8,
      paddingBottom: 8,
      borderBottom: '1px solid #757575',
    },
    tableRowCheckboxWrapper: {
      marginRight: 17,
    },
    formControlLabel: {
      height: 16,
      width: 16,
    },
    tableRowElem: {
      fontSize: fontSizes.md,
    },
    tableRowName: {
      width: 300,
      marginRight: 'auto',
    },
    tableRowDate: {
      width: 108,
      marginRight: 'auto',
      textAlign: 'center',
    },
    tableRowPositions: {
      width: 85,
      marginRight: 'auto',
      textAlign: 'center',
    },
    tableRowDone: {
      width: 78,
      textAlign: 'center',
    },
  }))();

export default useStyles;
