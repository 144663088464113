import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import pallete from '../../theme/pallete';

const useStyles = () => {
  return makeStyles(() => ({
    contentWrapper: {
      width: 454,
      transition: 'all 0.5s',
      height: 0,
      overflow: 'hidden',
      backgroundColor: pallete.textSecondary,
      marginLeft: 5,
    },
    activeContentWrapper: {
      height: 'auto',
    },
    content: {
      padding: 10,
    },
    contentRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    contentRowTitle: {
      fontSize: fontSizes.md,
      width: 85,
    },
    contetnRowTitleAuto: {
      width: 'auto',
    },
    contentRowValue: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.BOLD,
    },
    contentRowWeightValue: {
      marginLeft: 15,
    },
    contentRowLine: {
      flexGrow: 1,
      backgroundColor: '#A9A9A9',
      marginLeft: 10,
      marginRight: 10,
    },
    contentIngridientsValue: {
      marginLeft: 17,
      fontSize: fontSizes.md,
      fontWeight: fontWeights.BOLD,
      marginBottom: 5,
    },
  }))();
};

export default useStyles;
