import { makeStyles } from '@material-ui/styles';

const useStyles = () => {
  return makeStyles(() => ({
    content: {
      height: 'calc(100vh - 288px)',
      width: 545,
      margin: '0 auto',
      marginTop: 30,
      marginBottom: 30,
      overflowY: 'auto',
    },
  }))();
};

export default useStyles;
