import { Box, IconButton, InputBase } from '@material-ui/core';
import React, { useCallback } from 'react';

import CloseIcon from '../icons/CloseIcon';
import SearchGlass from './../../components/icons/SearchGlass';
import useStyles from './styles';

const SearchComponent = ({ searchValue, setSearchValue, handleSearch }) => {
  const classes = useStyles({ searchValue });

  const handleClearSearch = () => {
    handleSearch('');
    setSearchValue('');
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleEnterPress = useCallback((e) => {
    if (e.code === 'Enter') {
      handleSearch(searchValue);
    }
  }, []);

  return (
    <Box className={classes.searchInputContainer}>
      <InputBase
        className={classes.searchInput}
        onChange={handleSearchValue}
        value={searchValue}
        onKeyPress={handleEnterPress}
      />
      <IconButton
        color='primary'
        aria-label='upload picture'
        className={classes.searchBtn}
        component='span'
        onClick={() => handleSearch()}
      >
        <SearchGlass />
      </IconButton>
      {searchValue && (
        <IconButton onClick={handleClearSearch} className={classes.clearSearch}>
          <CloseIcon color={'#3C3C43'} />
        </IconButton>
      )}
    </Box>
  );
};

export default SearchComponent;
