import LoginChoiceAccountPage from '../pages/Authorization/LoginChoiceAccount/LoginChoiceAccount';
import LoginToSpacePage from '../pages/Authorization/LoginToSpace/LoginToSpace';
import UserLoginPage from '../pages/Authorization/UserLogin/UserLogin';
import UserLoginPasswordPage from '../pages/Authorization/UserLoginPassword/UserLoginPassword';
import UserLoginPinPage from '../pages/Authorization/UserLoginPin/UserLoginPin';
import HomePage from '../pages/Home/Home';
import ProductionList from '../pages/ProductionList/ProductionList';
import ProductionLists from '../pages/ProductionLists/ProductionLists';
import { ROUTES } from './constants';

const routes = [
  {
    path: ROUTES.loginChoiceAccount,
    component: LoginChoiceAccountPage,
    exact: true,
  },
  { path: ROUTES.loginToSpace, component: LoginToSpacePage, exact: true },
  { path: ROUTES.userLogin, component: UserLoginPage, exact: true },
  {
    path: ROUTES.userLoginPassword,
    component: UserLoginPasswordPage,
    exact: true,
  },
  { path: ROUTES.userLoginPin, component: UserLoginPinPage, exact: true },
  { path: ROUTES.home, component: HomePage, isPrivate: true, exact: true },
  {
    path: ROUTES.productionLists,
    component: ProductionLists,
    isPrivate: true,
    exact: true,
  },
  {
    path: ROUTES.productionList,
    component: ProductionList,
    isPrivate: true,
    exact: false,
  },
];
export default routes;
