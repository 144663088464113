import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import pallete from '../../theme/pallete';

const useStyles = ({ isAuth }) =>
  makeStyles(() => ({
    wrapper: {
      paddingLeft: 18,
      paddingRight: 18,
      backgroundColor: pallete.secondary1,
      borderRadius: fontSizes.extra8,
      display: 'flex',
      alignItems: 'center',
      height: 58,
    },
    hamburgerMenuBtnWrapper: {
      display: 'flex',
      marginRight: 24,
      cursor: 'pointer',
    },
    daysSelectWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    languageSelectWrapper: {
      marginLeft: isAuth ? 26 : 'auto',
    },
    scalesSelectWrapper: {
      marginLeft: 20,
    },
    userSelectWrapper: {
      width: 'auto',
      maxWidth: 190,
      minWidth: 180,
      marginLeft: 20,
    },
    loginBtnWrapper: {
      cursor: 'pointer',
      '& p': {
        fontWeight: fontWeights.BOLD,
        fontSize: fontSizes.md,
      },
    },
    noUserIconWrapper: {
      '& img': {
        width: 35,
        height: 35,
        borderRadius: '50%',
      },
    },
    userInfoWrapper: {
      '& p': {
        fontWeight: fontWeights.BOLD,
        fontSize: fontSizes.sm,
        marginRight: 10,
      },
    },
  }))();

export default useStyles;
