import React from 'react';

const LogoIcon = () => {
  return (
    <svg
      width='163'
      height='31'
      viewBox='0 0 163 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M0.179596 29.7993C0.323271 29.6537 0.323271 29.4718 0.35919 29.2899C0.933893 27.1432 3.08903 25.615 5.28008 25.7969C5.42376 25.7969 5.53151 25.8697 5.63927 25.9789C8.33319 28.3803 11.4941 29.3263 15.05 29.1444C18.0313 28.9988 20.6175 27.9437 22.8444 25.9425C22.9163 25.8697 23.024 25.7969 23.1318 25.7969C25.7898 25.5059 28.1963 27.4707 28.4837 30.1268C28.5196 30.4906 28.4119 30.5634 28.0527 30.5634C18.8933 30.5634 9.76994 30.5634 0.610623 30.5634C0.466947 30.5634 0.287352 30.5634 0.143677 30.5634C0.179596 30.3087 0.179596 30.054 0.179596 29.7993Z'
          fill='#2A3066'
        />
        <path
          d='M95.9035 18.2289C95.9035 22.1948 95.9035 26.1608 95.9035 30.1631C95.9035 30.5634 95.7957 30.6362 95.4366 30.6362C92.599 30.6362 89.7614 30.5998 86.9597 30.6362C85.3074 30.6725 84.0143 29.9812 83.0086 28.7078C81.6796 26.9977 80.9971 25.0329 80.7816 22.9225C80.5302 20.8486 80.6739 18.8474 81.4641 16.8826C82.0029 15.5364 82.7572 14.2993 83.9066 13.3533C84.8046 12.5892 85.8821 12.2254 87.0315 12.2254C88.576 12.2254 90.1206 12.2254 91.701 12.2254C92.0602 12.2254 92.132 12.1162 92.132 11.7887C92.132 9.9331 92.132 8.07747 92.132 6.22184C92.132 5.89437 92.2038 5.78522 92.563 5.78522C93.5328 5.8216 94.5027 5.8216 95.5084 5.78522C95.8676 5.78522 95.9753 5.85799 95.9753 6.25822C95.9035 10.2606 95.9035 14.2265 95.9035 18.2289ZM91.9524 21.3944C91.9524 19.7934 91.9524 18.1925 91.9524 16.6279C91.9524 16.2277 91.8447 16.1549 91.4855 16.1549C90.4797 16.1913 89.4381 16.1549 88.4324 16.1549C87.4625 16.1549 86.6723 16.5552 86.0976 17.2829C85.3074 18.2653 84.9482 19.3932 84.8405 20.6303C84.7327 22.3404 85.0201 23.9413 85.9899 25.3967C86.5287 26.1972 87.283 26.5974 88.2528 26.5974C89.3663 26.5974 90.4797 26.561 91.5573 26.5974C91.9165 26.5974 91.9883 26.4883 91.9883 26.1608C91.9524 24.5599 91.9524 22.9589 91.9524 21.3944Z'
          fill='#BABC1A'
        />
        <path
          d='M142.454 23.9413C142.454 26.3064 142.454 28.2711 142.454 30.2359C142.454 30.527 142.383 30.6362 142.059 30.6362C138.647 30.6362 135.271 30.6362 131.858 30.6362C130.17 30.6362 128.949 29.7265 128.266 28.3075C127.117 25.9061 127.261 23.5047 128.769 21.2852C129.452 20.2664 130.457 19.7934 131.679 19.7934C133.654 19.7934 135.63 19.7934 137.605 19.7934C138.36 19.7934 138.36 19.7934 138.36 18.993C138.36 18.4108 138.216 17.9014 137.929 17.392C137.534 16.7007 136.995 16.3369 136.169 16.3369C133.798 16.3369 131.427 16.3369 129.057 16.3369C128.482 16.3369 128.482 16.3369 128.482 15.7183C128.482 14.6995 128.482 13.6444 128.482 12.6256C128.482 12.3709 128.518 12.2617 128.805 12.2617C131.355 12.2617 133.906 12.2617 136.456 12.2617C138.18 12.2617 139.796 12.662 140.91 14.1538C141.736 15.2089 142.095 16.446 142.239 17.7559C142.562 19.939 142.383 22.1585 142.454 23.9413ZM135.666 26.8521C136.456 26.8521 137.282 26.8521 138.072 26.8521C138.252 26.8521 138.396 26.8885 138.396 26.5974C138.396 25.5787 138.396 24.5599 138.396 23.5047C138.396 23.3228 138.324 23.25 138.144 23.25C136.528 23.25 134.911 23.2136 133.295 23.25C132.325 23.2864 131.715 24.1233 131.751 25.1784C131.786 26.1244 132.469 26.8157 133.403 26.8157C134.157 26.8521 134.911 26.8521 135.666 26.8521Z'
          fill='#2A3066'
        />
        <path
          d='M62.463 21.9038C62.463 19.2477 62.894 17.1737 64.2589 15.4272C66.1985 12.9531 68.8206 12.0434 71.8737 12.2617C73.957 12.4073 75.7889 13.1714 77.2257 14.7723C78.4469 16.1185 79.0575 17.7559 79.273 19.5387C79.5245 21.7218 79.4167 23.8686 78.6624 25.9425C77.4771 29.0352 75.2142 30.6725 71.9815 31C70.6525 31.1455 69.3235 31.0364 68.0304 30.6725C65.2287 29.8721 63.6483 27.9437 62.9299 25.1784C62.5707 23.9777 62.463 22.7042 62.463 21.9038ZM66.8451 21.3944C66.8451 23.1045 67.0965 24.5235 67.9586 25.7606C68.8565 27.0704 70.1137 27.6162 71.6941 27.3615C73.1309 27.1432 73.9929 26.27 74.4958 24.9601C74.855 24.0505 74.9628 23.0681 75.0346 22.1221C75.1424 20.5575 74.9628 19.0657 74.0289 17.7195C72.5203 15.5364 69.539 15.5 67.9586 17.6467C67.0965 18.8474 66.8092 20.1937 66.8451 21.3944Z'
          fill='#BABC1A'
        />
        <path
          d='M61.0981 21.4308C61.0621 23.2864 60.8466 25.142 59.9846 26.8157C58.6915 29.3627 56.5723 30.7089 53.7706 31C52.5134 31.1455 51.2922 31.0728 50.035 30.7817C47.6285 30.2359 45.9044 28.8169 45.0064 26.4883C43.9647 23.7958 43.7851 21.0305 44.5394 18.2653C45.4374 14.9178 48.1673 12.6256 51.5796 12.2981C53.3037 12.1162 54.9559 12.2981 56.5364 13.0258C59.1225 14.2629 60.4515 16.4096 60.9185 19.1749C61.0262 19.939 61.0981 20.6667 61.0981 21.4308ZM48.4905 21.2488C48.4905 23.0681 48.742 24.5235 49.6399 25.7606C50.5379 27.034 51.7591 27.5798 53.3037 27.3615C54.7404 27.1432 55.6025 26.3063 56.1413 24.9965C56.5004 24.0869 56.6441 23.1409 56.68 22.1948C56.7878 20.5939 56.6082 19.0657 55.6743 17.7195C54.1657 15.5364 51.1844 15.4636 49.604 17.6467C48.7779 18.811 48.4905 20.1573 48.4905 21.2488Z'
          fill='#BABC1A'
        />
        <path
          d='M103.051 30.6726C101.471 30.6726 99.8905 30.6726 98.3101 30.6726C97.9868 30.6726 97.8431 30.6362 97.879 30.2359C97.915 29.3991 97.915 28.5622 97.879 27.7254C97.879 27.3979 97.9509 27.3251 98.2741 27.3251C100.717 27.3251 103.159 27.2888 105.602 27.3251C107.613 27.3615 108.224 25.7606 107.649 24.1596C107.326 23.2864 106.571 22.9953 105.709 22.9589C104.344 22.9226 102.944 22.9226 101.579 22.9589C100.717 22.9589 100.034 22.6679 99.4235 22.0857C97.879 20.6303 97.3762 18.811 97.6276 16.7371C97.7354 15.7911 97.9509 14.8815 98.3819 14.0446C99.0644 12.7711 100.106 12.1162 101.543 12.1162C104.524 12.1162 107.505 12.1162 110.487 12.1162C110.81 12.1162 110.882 12.2254 110.882 12.5528C110.846 13.4625 110.846 14.3357 110.882 15.2453C110.882 15.5728 110.774 15.6092 110.487 15.6092C107.936 15.6092 105.422 15.6092 102.872 15.6092C102.261 15.6092 101.866 15.8639 101.615 16.4096C101.291 17.1373 101.255 17.865 101.615 18.5927C101.938 19.2113 102.405 19.5387 103.123 19.5387C104.38 19.5024 105.638 19.5387 106.931 19.5387C109.553 19.5387 111.097 20.7758 111.636 23.3592C112.067 25.4331 111.816 27.4343 110.487 29.1808C109.732 30.1632 108.762 30.7089 107.505 30.7089C106.069 30.6726 104.56 30.6726 103.051 30.6726Z'
          fill='#2A3066'
        />
        <path
          d='M158.654 23.6866C157.361 23.6866 156.104 23.6866 154.81 23.6866C154.487 23.6866 154.415 23.7594 154.451 24.0869C154.487 24.7782 154.631 25.4331 155.026 26.0153C155.637 26.9613 156.535 27.2524 157.576 27.2524C159.229 27.2887 160.917 27.2887 162.569 27.2524C162.928 27.2524 163 27.3615 163 27.7254C162.964 28.635 163 29.5082 163 30.4179C163 30.6725 162.928 30.7453 162.677 30.7453C160.45 30.7453 158.223 30.7453 155.996 30.7453C154.344 30.7089 153.086 29.8357 152.117 28.5622C150.464 26.4519 149.782 24.0505 149.854 21.3944C149.926 18.811 150.68 16.4824 152.332 14.4812C153.266 13.3533 154.379 12.4801 155.888 12.2981C156.211 12.2617 156.535 12.2617 156.822 12.2617C158.726 12.2617 160.629 12.2617 162.533 12.2617C162.892 12.2617 162.964 12.3709 162.964 12.6984C162.928 13.6808 162.928 14.6995 162.964 15.7183C162.964 16.0094 162.892 16.1186 162.569 16.1186C161.132 16.1186 159.66 16.1186 158.223 16.1186C157.756 16.1186 157.289 16.1549 156.822 16.2641C155.277 16.6279 154.451 18.2653 154.451 19.7571C154.451 20.0117 154.631 19.9754 154.775 19.9754C155.924 19.9754 157.037 19.9754 158.187 19.9754C159.66 19.9754 161.132 19.9754 162.569 19.9754C162.856 19.9754 162.928 20.0481 162.928 20.3392C162.928 21.3944 162.928 22.4132 162.928 23.4683C162.928 23.7594 162.856 23.8322 162.569 23.8322C161.276 23.6866 159.983 23.6866 158.654 23.6866Z'
          fill='#2A3066'
        />
        <path
          d='M35.9548 23.5047C35.9548 21.2852 35.9548 19.1021 35.9548 16.8826C35.9548 16.4824 35.8471 16.4096 35.4879 16.4096C34.8054 16.446 34.1229 16.4096 33.4764 16.4096C33.225 16.4096 33.1172 16.3369 33.1172 16.0822C33.1172 15.0998 33.1531 14.081 33.1172 13.0622C33.1172 12.7347 33.225 12.6984 33.5123 12.6984C34.2307 12.6984 34.9132 12.6984 35.6315 12.6984C35.9189 12.6984 35.9907 12.6256 35.9907 12.3345C35.9907 11.243 35.883 10.1514 36.1344 9.09625C36.3499 8.18662 36.7809 7.38615 37.4275 6.73122C38.074 6.03991 38.8642 5.8216 39.7622 5.78521C41.1271 5.74883 42.4921 5.78521 43.857 5.78521C44.1084 5.78521 44.1802 5.89437 44.1443 6.11268C44.1443 7.16784 44.1443 8.22301 44.1443 9.27817C44.1443 9.53287 44.0725 9.60564 43.8211 9.60564C42.959 9.60564 42.1329 9.56925 41.2708 9.67841C40.8039 9.75118 40.4806 9.96949 40.301 10.4425C40.0137 11.1338 40.0855 11.8251 40.0855 12.5528C40.0855 12.8075 40.301 12.7347 40.4447 12.7347C41.5222 12.7347 42.5639 12.7347 43.6415 12.7347C43.9647 12.7347 44.1084 12.8075 44.0725 13.1714C44.0366 14.1538 44.0366 15.0998 44.0725 16.0822C44.0725 16.3732 44.0007 16.446 43.7133 16.446C42.6357 16.446 41.5941 16.446 40.5165 16.446C40.1573 16.446 40.0855 16.5552 40.0855 16.8826C40.0855 21.3216 40.0855 25.7242 40.0855 30.1632C40.0855 30.5634 40.0137 30.6725 39.5826 30.6725C38.5051 30.6362 37.4275 30.6362 36.3499 30.6725C36.0267 30.6725 35.9189 30.5998 35.9189 30.2359C35.9548 27.9437 35.9548 25.7242 35.9548 23.5047Z'
          fill='#BABC1A'
        />
        <path
          d='M122.627 30.5998C121.621 30.5634 120.4 30.6725 119.215 30.527C116.844 30.2359 115.084 28.9261 114.114 26.743C112.462 23.1045 112.498 19.3932 114.33 15.8275C115.587 13.3897 117.706 12.189 120.436 12.189C122.34 12.189 124.208 12.189 126.111 12.189C126.399 12.189 126.506 12.2617 126.506 12.5528C126.47 13.7535 126.506 14.9178 126.506 16.1185C126.506 16.4096 126.435 16.446 126.147 16.446C124.459 16.446 122.771 16.446 121.083 16.446C120.077 16.446 119.287 16.8462 118.604 17.5739C116.808 19.6115 116.629 22.9953 118.245 25.1784C118.928 26.1244 119.825 26.6338 121.011 26.6338C122.699 26.5974 124.387 26.6338 126.075 26.6338C126.363 26.6338 126.47 26.6702 126.47 26.9977C126.435 28.0528 126.47 29.1444 126.47 30.1995C126.47 30.4906 126.399 30.5634 126.111 30.5634C125.07 30.5998 123.956 30.5998 122.627 30.5998Z'
          fill='#2A3066'
        />
        <path
          d='M15.5888 3.45658C18.0313 3.74766 20.2583 4.58452 22.162 6.18546C25.8975 9.35095 27.5139 13.3897 26.58 18.1925C25.6102 23.1409 22.5571 26.4155 17.7439 27.8345C10.9193 29.8721 3.87923 25.8333 2.04737 18.993C0.323262 12.5164 4.23842 5.71245 10.7757 3.85682C11.4581 3.67489 12.1406 3.56574 12.823 3.4202C13.7569 3.4202 14.6908 3.38381 15.5888 3.45658ZM13.7929 5.05752C13.3618 5.05752 12.4998 5.16668 11.6736 5.3486C5.92661 6.65846 2.19104 12.5892 3.59188 18.3017C5.45967 25.9789 13.9006 28.6714 19.7913 25.2148C23.5987 22.9953 25.7179 18.6291 25.0714 14.3721C24.2453 8.9871 19.6836 5.05752 13.7929 5.05752Z'
          fill='#2A3066'
        />
        <path
          d='M144.43 18.2289C144.43 14.2266 144.43 10.2606 144.43 6.25824C144.43 5.89439 144.502 5.74885 144.897 5.78524C145.939 5.82162 146.98 5.82162 148.022 5.78524C148.345 5.78524 148.453 5.85801 148.453 6.18547C148.453 14.1902 148.453 22.2312 148.453 30.2359C148.453 30.527 148.381 30.6362 148.058 30.6362C146.98 30.6362 145.903 30.5998 144.825 30.6362C144.466 30.6362 144.394 30.527 144.394 30.1996C144.43 26.1972 144.43 22.1949 144.43 18.2289Z'
          fill='#2A3066'
        />
        <path
          d='M15.5888 3.45658C14.6549 3.38381 13.7569 3.42019 12.8231 3.45658C12.7153 3.09273 12.7512 2.69249 12.7871 2.29226C12.7871 1.92841 12.6794 1.85564 12.3561 1.85564C9.69811 1.85564 7.00419 1.81925 4.34619 1.81925C3.01719 1.81925 1.90371 1.41902 0.969816 0.47301C0.897978 0.436625 0.862059 0.40024 0.718384 0.2547C9.76995 0.436625 18.6778 0.436625 27.6216 0.2547C27.6576 0.436625 27.5498 0.509395 27.442 0.582164C26.5082 1.4554 25.3947 1.81925 24.1375 1.81925C21.4795 1.81925 18.7856 1.85564 16.1276 1.85564C15.8043 1.85564 15.6966 1.92841 15.6966 2.29226C15.6966 2.65611 15.8043 3.09273 15.5888 3.45658Z'
          fill='#BABC1A'
        />
        <path
          d='M14.2598 9.27817C14.5831 9.71479 14.7627 10.1878 14.7627 10.7336C14.7268 12.007 14.7627 13.2441 14.7627 14.5176C14.7627 14.7359 14.7986 14.8815 14.9782 15.027C15.4451 15.4636 15.4811 16.1185 15.05 16.5552C14.619 16.9918 13.9365 16.9918 13.5055 16.5552C13.0745 16.1185 13.0745 15.4272 13.5774 15.027C13.7569 14.8451 13.7929 14.6995 13.7929 14.4812C13.7929 13.2077 13.7929 11.9343 13.7929 10.6608C13.7569 10.0423 13.7929 9.9331 14.2598 9.27817Z'
          fill='#2A3066'
        />
        <path
          d='M5.24417 15.3909C5.53152 15.4273 5.74703 15.5728 5.74703 15.8639C5.74703 16.155 5.56744 16.3369 5.24417 16.3369C4.95682 16.3369 4.77722 16.155 4.77722 15.8639C4.77722 15.6092 4.95682 15.4273 5.24417 15.3909Z'
          fill='#BABC1A'
        />
        <path
          d='M14.2239 25.5423C14.2239 25.2148 14.4035 25.0329 14.6908 25.0329C15.0141 25.0329 15.2296 25.2148 15.2296 25.5423C15.2296 25.8697 15.05 26.0153 14.7267 26.0516C14.4394 26.0153 14.2239 25.8697 14.2239 25.5423Z'
          fill='#BABC1A'
        />
        <path
          d='M14.2239 7.45893C13.9365 7.42254 13.721 7.277 13.7569 6.94954C13.7569 6.65846 13.9365 6.47653 14.2239 6.47653C14.5112 6.47653 14.7267 6.65846 14.7267 6.94954C14.7267 7.277 14.5472 7.45893 14.2239 7.45893Z'
          fill='#BABC1A'
        />
        <path
          d='M23.2036 16.3733C22.9163 16.3369 22.7008 16.1913 22.7367 15.8639C22.7367 15.5728 22.9163 15.3909 23.2036 15.3909C23.491 15.3909 23.7065 15.5728 23.7065 15.8639C23.7065 16.1913 23.491 16.3369 23.2036 16.3733Z'
          fill='#BABC1A'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='163' height='31' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
