import { MenuItem, Select } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React from 'react';

import SelectArrowIcon from '../icons/SelectArrowIcon';
import useStyles from './styles';

const CustomSelect = ({
  value,
  onChange,
  itemsList,
  width = 323,
  paperWidth = 369,
  papperML = 23,
  ml = 10,
  height = 31,
}) => {
  const classes = useStyles({ width, paperWidth, papperML, ml, height });
  return (
    <Select
      value={value}
      onChange={onChange}
      classes={{
        root: classes.select,
      }}
      IconComponent={() => (
        <Box className={classes.arrowBtn}>
          <SelectArrowIcon />
        </Box>
      )}
      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
    >
      {itemsList?.map((item, index) => {
        return (
          <MenuItem disabled={item?.disabled} key={index} value={item.value}>
            {item.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CustomSelect;
