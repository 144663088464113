import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import AuthLayout from '../../../components/Layout/AuthLayout/AuthLayout';
import MainLayout from '../../../components/Layout/MainLayout/MainLayout';
import SpinnerLogo from '../../../components/Spinner/SpinnerLogo';
import { API_HOST, ROUTES } from '../../../config/constants';
import {
  getActiveSpaceToLogin,
  setActiveUserForPassLogin,
  useAuthContext,
} from '../../../context/AuthContext';
import { useUsersDarareferenceQuery } from '../../../hooks/useQueries';
import fontSizes from '../../../theme/fontSizes';
import useStyles from './styles';

const LoginChoiceAccount = () => {
  const { formatMessage } = useIntl();

  const { loading, startLoading, allUsersForDB, removellUsersForDB } =
    useAuthContext();
  const [query] = useUsersDarareferenceQuery();
  const classes = useStyles();
  const { push } = useHistory();
  const activeSpaceToLogin = getActiveSpaceToLogin();
  const handleChangePage = () => {
    removellUsersForDB();
    push(ROUTES.userLogin);
  };

  useEffect(() => {
    startLoading();
    query({
      variables: {
        databaseId: +activeSpaceToLogin?.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainLayout>
      <AuthLayout
        title='user_login_title'
        botomBtnTitle={'go_to_login_selection'}
        bottomBtnAction={handleChangePage}
      >
        <Typography
          fontSize={fontSizes.ml}
          marginTop='46px'
          marginBottom={'33px'}
        >
          {formatMessage({ id: 'select_accout' })}
        </Typography>
        {loading ? (
          <SpinnerLogo />
        ) : (
          allUsersForDB?.map((account, index) => {
            return (
              <Box
                key={index}
                className={classes.accountBtn}
                onClick={() => {
                  setActiveUserForPassLogin(account);
                  push(ROUTES.userLoginPassword);
                }}
              >
                <img
                  src={`${API_HOST}/${account?.avatar}`}
                  alt={`${account?.firstName} ${account?.lastName}`}
                />
                <Typography
                  fontSize={fontSizes.ml}
                >{`${account?.firstName} ${account?.lastName}`}</Typography>
              </Box>
            );
          })
        )}
      </AuthLayout>
    </MainLayout>
  );
};

export default LoginChoiceAccount;
