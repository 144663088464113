import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';

const useStyles = () =>
  makeStyles(() => ({
    btnsWrapper: {
      display: 'flex',
      width: '100%',
      padding: 6,
      border: '1px solid #5E5E5E',
      marginBottom: 17,
    },
    selectsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 25,
    },
    timeSpanTitle: {
      marginRight: 23,
      fontSize: fontSizes.md,
    },
    toTitle: {
      marginLeft: 12,
      marginRight: 21,
      fontSize: fontSizes.md,
    },
  }))();

export default useStyles;
