import { createTheme } from '@material-ui/core/styles';

import fontSizes from './fontSizes';
import fontWeights from './fontWeights';
import pallete from './pallete';

const theme = createTheme({
  pallete,
  fontSizes,
  fontWeights,
});

export default theme;
