// import { FormControlLabel } from '@material-ui/core';
import { Box } from '@material-ui/system';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomCheckBox from '../../../components/CheckBox/CustomCheckBox';
import { setActiveRow } from '../../../redux/reducers/productionLists';
import { productionListsSelector } from '../../../redux/selectors';
import { getDayName } from '../../../utils/getDayName';
import { getDefaultDateFormat } from '../../../utils/getDefaultDateFormat';
import ProductionListTableHeader from './ProductionListsTableHeader/ProductionListTableHeader';
import useStyles from './styles';

const ProductionListTableRow = ({ row }) => {
  const dispatch = useDispatch();
  const { activeRow } = useSelector(productionListsSelector);

  const handleActiveRow = (row) => {
    dispatch(
      setActiveRow({ activeRow: row.id === activeRow?.id ? null : row }),
    );
  };

  const classes = useStyles();
  return (
    <Box className={classes.tableRow}>
      <Box className={classes.tableRowCheckboxWrapper}>
        <CustomCheckBox
          size='small'
          checked={activeRow?.id === row?.id ? true : false}
          onChange={() => handleActiveRow(row)}
          classes={{ root: classes.formControlLabel }}
        />
      </Box>
      <Box className={classNames(classes.tableRowElem, classes.tableRowName)}>
        {`${row?.name}, ${getDayName(row?.forADay)}, ${getDefaultDateFormat(
          row?.forADay,
          'y-MM-d',
        )}`}
      </Box>

      <Box className={classNames(classes.tableRowElem, classes.tableRowDate)}>
        {getDefaultDateFormat(row?.forADay, 'd.MM.y')}
      </Box>
      <Box
        className={classNames(classes.tableRowElem, classes.tableRowPositions)}
      >
        {row?.getPositions}
      </Box>
      <Box className={classNames(classes.tableRowElem, classes.tableRowDone)}>
        {row?.getDone}
      </Box>
    </Box>
  );
};

const ProductionListTable = ({ listItems }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <ProductionListTableHeader />
      <Box className={classes.tableRowsWrapper}>
        {listItems?.map((item, index) => {
          return <ProductionListTableRow key={index} row={item} />;
        })}
      </Box>
    </Box>
  );
};

export default ProductionListTable;
