import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import fontSizes from '../../../../theme/fontSizes';
import fontWeights from '../../../../theme/fontWeights';
import useStyles from './styles';

const AccordionItemHeader = ({ Icon, contentTitle }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.iconWrapper}>
        <Icon />
      </Box>
      <Box className={classes.titleWrapper}>
        <Typography fontSize={fontSizes.lg} fontWeight={fontWeights.BOLD}>
          {formatMessage({ id: contentTitle })}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccordionItemHeader;
