import { Box } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import useStyles from './styles';

const PrintModal = ({ options }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Box className={classes.wrapper}>
      {options?.map((item, index) => {
        return (
          <Box key={index} onClick={item?.action} className={classes.printElem}>
            {formatMessage({ id: item?.title })}
          </Box>
        );
      })}
    </Box>
  );
};

export default PrintModal;
