import { Dialog, Paper } from '@material-ui/core';
import { Box } from '@material-ui/system';
import classNames from 'classnames';
import React from 'react';
import Draggable from 'react-draggable';

import CloseIcon from '../../icons/CloseIcon';
import KeyboardIcon from '../../icons/KeyboardIcon';
import useStyles from './styles';

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      // cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ background: 'none' }} {...props} />
    </Draggable>
  );
}

const ModalLayout = ({
  type = 'search',
  onClose = () => {},
  children,
  open,
  setType,
  //   action = () => {},
}) => {
  const classes = useStyles({ type });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby='draggable-dialog-title'
    >
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Box
            className={classes.headerLinerWrapper}
            id='draggable-dialog-title'
          >
            <Box className={classes.headerLine}></Box>
            <Box className={classes.headerLine}></Box>
            <Box className={classes.headerLine}></Box>
            <Box
              className={classNames(classes.headerLine, classes.headerLastLine)}
            ></Box>
          </Box>

          {type === 'search' ? (
            <Box className={classes.btn} onClick={onClose}>
              <CloseIcon />
            </Box>
          ) : null}

          {type !== 'search' ? (
            <Box className={classes.btn} onClick={onClose}>
              <KeyboardIcon />
            </Box>
          ) : null}

          {type === 'number' ? (
            <Box className={classes.btn} onClick={setType}>
              <Box className={classes.numberTypeBtn}></Box>
            </Box>
          ) : null}
          {type === 'calc' ? (
            <Box
              className={classNames(classes.btn, classes.calcBtnWrapeer)}
              onClick={setType}
            >
              <Box className={classes.calcTypeBtn}></Box>
            </Box>
          ) : null}
        </Box>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Dialog>
  );
};

export default ModalLayout;
