import React from 'react';

const ResipesListIcon = () => {
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 35 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.7118 7.08236C34.3824 7.94707 34.0941 8.81177 33.7647 9.67648C31.6647 15.4412 29.5647 21.2059 27.4647 26.9706C26.8059 28.7412 26.0235 30.4294 24.8706 31.9118C23.553 33.6412 21.8235 34.5471 19.6412 34.7118C18.1177 34.8353 16.6765 34.5059 15.2765 33.8471C11.7765 32.2412 8.27648 30.6353 4.77648 29.0706C3.62354 28.5765 2.63531 27.9588 1.85295 26.9706C1.02942 25.9412 0.576482 24.7471 0.452952 23.4294C0.329423 22.4824 0.411776 21.5765 0.535305 20.6294C1.44119 21 2.30589 21.3294 3.1706 21.7C7.16472 23.3059 11.1177 24.9118 15.1118 26.5177C15.4 26.6412 15.5235 26.7647 15.5235 27.1353C15.6059 28.453 15.8941 29.7706 16.7588 30.8412C17.5 31.7471 18.4882 32.1177 19.6412 32.0765C21.1647 31.9941 22.2353 31.1706 23.0177 29.9353C24.0882 28.2882 24.8294 26.4765 25.4882 24.6235C27.5059 19.1059 29.5235 13.5882 31.5412 8.0706C31.5824 7.98825 31.5824 7.90589 31.6235 7.82354C26.0235 6.38236 20.4235 4.94119 14.7412 3.50001C12.3941 9.4706 10.0471 15.4 7.70001 21.4118C6.87648 21.1235 6.05295 20.7941 5.22942 20.5059C5.55883 19.7235 5.84707 18.9412 6.13531 18.1588C8.27648 12.7235 10.4177 7.28824 12.5588 1.85295C12.8882 0.947068 13.4647 0.70001 14.3706 0.905892C20.7118 2.42942 27.053 3.95295 33.3941 5.47648C33.9706 5.60001 34.3824 5.92942 34.6294 6.50589C34.7118 6.62942 34.7118 6.87648 34.7118 7.08236Z'
        fill='currentColor'
      />
      <path
        d='M22.8118 23.347C22.8118 24.3765 21.9471 25.0353 21.0824 24.747C19.1882 24.0882 17.2941 23.4294 15.4 22.7294C14.6588 22.4412 14.3294 21.7412 14.5765 21.0412C14.8235 20.3412 15.5647 20.0118 16.3059 20.2588C17.8706 20.7941 19.4765 21.3706 21.0412 21.947C21.3706 22.0706 21.7 22.1529 22.0294 22.3176C22.5647 22.5235 22.8118 22.9353 22.8118 23.347Z'
        fill='currentColor'
      />
      <path
        d='M24.953 17.9941C24.953 18.9412 24.0471 19.5589 23.1412 19.2294C21.9471 18.8177 20.753 18.4059 19.6 17.953C18.9 17.7059 18.1588 17.4589 17.4588 17.1706C16.8824 16.9647 16.5941 16.3883 16.6353 15.8118C16.6765 15.2353 17.1294 14.7824 17.7059 14.6589C17.953 14.6177 18.2412 14.6589 18.4471 14.7412C20.3 15.4 22.1941 16.0589 24.0471 16.7177C24.6647 16.9647 24.953 17.4177 24.953 17.9941Z'
        fill='currentColor'
      />
      <path
        d='M25.9 13.7118C25.8177 13.7118 25.653 13.6706 25.4883 13.6294C23.6765 13.0941 21.9059 12.5176 20.0941 11.9823C19.2706 11.7353 18.8588 11.1176 19.0236 10.3353C19.1883 9.67646 19.8883 9.18234 20.5471 9.34705C21.8647 9.71764 23.1824 10.1294 24.4588 10.5412C25.1177 10.747 25.8177 10.9529 26.4765 11.1588C27.0941 11.3647 27.4236 12.0235 27.3 12.6412C27.1765 13.3 26.6 13.7118 25.9 13.7118Z'
        fill='currentColor'
      />
      <path
        d='M10.2941 19.9706C10.2941 19.1882 10.953 18.5294 11.7353 18.5294C12.5177 18.5294 13.2177 19.1882 13.2177 19.9294C13.2177 20.7118 12.5588 21.3706 11.7353 21.3706C10.953 21.4118 10.2941 20.753 10.2941 19.9706Z'
        fill='currentColor'
      />
      <path
        d='M17.4588 9.26475C17.4588 10.0471 16.8 10.7059 15.9765 10.7059C15.1941 10.7059 14.5353 10.0471 14.5353 9.30592C14.5353 8.52357 15.1941 7.86475 15.9765 7.86475C16.8412 7.82357 17.5 8.48239 17.4588 9.26475Z'
        fill='currentColor'
      />
      <path
        d='M13.9588 15.9353C13.1353 15.9353 12.5177 15.3176 12.5177 14.4941C12.5177 13.7118 13.1765 13.0941 14 13.0941C14.7824 13.0941 15.4412 13.7529 15.4412 14.4941C15.4 15.3176 14.7412 15.9353 13.9588 15.9353Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ResipesListIcon;
