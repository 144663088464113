import { Box } from '@material-ui/system';
import React from 'react';

import ProductionListTableHeaderItem from './ProductionListTableHeaderItem/ProductionListTableHeaderItem';
import useStyles from './styles';

const ProductionListTableHeader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.tableHeader}>
      <ProductionListTableHeaderItem title={'name'} mr={'auto'} />
      <ProductionListTableHeaderItem title={'forADay'} mr={'auto'} />
      <ProductionListTableHeaderItem title={'positions'} mr={'auto'} />
      <ProductionListTableHeaderItem title={'done'} />
    </Box>
  );
};

export default ProductionListTableHeader;
