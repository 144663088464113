import React from 'react';

const ToDoIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.96236 35.0259C7.70824 34.9412 7.45412 34.8988 7.20001 34.7718C5.76001 34.2212 4.78589 32.8659 4.78589 31.3412C4.78589 23.04 4.78589 14.7812 4.78589 6.48003C4.78589 4.53179 6.01412 3.04944 7.92001 2.71061C8.13177 2.66826 8.34354 2.66826 8.5553 2.66826C9.06354 2.66826 9.57177 2.66826 10.08 2.66826C10.1647 2.41414 10.1647 2.20238 10.2071 1.99061C10.3765 1.14356 11.0541 0.677673 11.8588 0.677673C15.8824 0.677673 19.9059 0.677673 23.8871 0.677673C24.9035 0.677673 25.5812 1.35532 25.6659 2.37179C25.6659 2.4565 25.6659 2.5412 25.6659 2.71061C25.7929 2.71061 25.92 2.71061 26.0894 2.71061C26.8094 2.75297 27.4871 2.66826 28.1647 2.79532C29.8165 3.13414 31.0024 4.65885 31.0024 6.31061C31.0024 14.6118 31.0024 22.913 31.0024 31.2141C31.0024 33.1624 29.8588 34.5177 27.9529 34.9836C27.9106 34.9836 27.9106 35.0259 27.8682 35.0259C21.2188 35.0259 14.5694 35.0259 7.96236 35.0259ZM10.1647 4.65885C9.69883 4.65885 9.23295 4.7012 8.85177 4.65885C7.79295 4.57414 6.86118 5.29414 6.86118 6.60709C6.86118 14.7812 6.86118 22.8706 6.81883 31.0447C6.81883 32.3153 7.49648 32.993 8.76706 32.993C14.8659 32.993 20.9224 32.993 27.0212 32.993C28.0377 32.993 28.9694 32.3577 28.9694 31.0447C28.9694 22.8706 28.9694 14.7388 29.0118 6.52238C29.0118 6.43767 29.0118 6.35297 29.0118 6.26826C28.9694 5.50591 28.4612 4.82826 27.7835 4.7012C27.1059 4.6165 26.4282 4.6165 25.7082 4.57414C25.7082 4.7012 25.7082 4.78591 25.7082 4.82826C25.7506 5.88708 24.8612 6.60709 23.8447 6.60709C19.9059 6.56473 15.9247 6.60709 11.9859 6.60709C11.0541 6.60709 10.4188 6.0565 10.2494 5.20944C10.2071 5.08238 10.2071 4.91297 10.1647 4.65885ZM12.24 4.65885C16.0518 4.65885 19.8212 4.65885 23.5906 4.65885C23.5906 3.9812 23.5906 3.34591 23.5906 2.66826C19.7788 2.66826 16.0094 2.66826 12.24 2.66826C12.24 3.34591 12.24 3.9812 12.24 4.65885Z'
        fill='currentColor'
      />
      <path
        d='M21.8541 27.4871C20.7106 27.4871 19.5247 27.4871 18.3812 27.4871C17.7035 27.4871 17.28 27.0636 17.28 26.513C17.28 25.8777 17.7459 25.4965 18.3812 25.4965C20.7106 25.4965 23.04 25.4965 25.3271 25.4965C26.0047 25.4965 26.4282 25.9201 26.4282 26.4706C26.4282 27.1059 26.0047 27.4871 25.3271 27.4871C24.1412 27.4871 22.9976 27.4871 21.8541 27.4871Z'
        fill='currentColor'
      />
      <path
        d='M21.8118 12.0706C22.9553 12.0706 24.1835 12.0706 25.3271 12.0706C25.9624 12.0706 26.4282 12.4941 26.4282 13.0447C26.4282 13.6377 25.9624 14.0189 25.3271 14.0189C22.9976 14.0189 20.6682 14.0189 18.3388 14.0189C17.6612 14.0189 17.28 13.5953 17.28 13.0447C17.28 12.4941 17.7459 12.0706 18.3812 12.0706C19.4824 12.0706 20.6259 12.0706 21.8118 12.0706Z'
        fill='currentColor'
      />
      <path
        d='M21.8118 20.7529C20.6682 20.7529 19.4824 20.7529 18.3812 20.7529C17.7035 20.7529 17.28 20.3294 17.28 19.7788C17.28 19.1435 17.7459 18.7623 18.3812 18.7623C20.7106 18.7623 23.04 18.7623 25.3271 18.7623C26.0047 18.7623 26.4282 19.1859 26.4282 19.7364C26.4282 20.287 25.9624 20.7529 25.3271 20.7529C24.0988 20.7529 22.9553 20.7529 21.8118 20.7529Z'
        fill='currentColor'
      />
      <path
        d='M11.6047 19.9058C12.4094 19.1011 13.1294 18.3388 13.8918 17.6188C14.5271 17.0258 15.3741 17.2376 15.6282 18.0423C15.7553 18.4235 15.6282 18.7623 15.3741 18.9741C14.4 19.9906 13.3412 21.007 12.3247 22.0235C11.9012 22.447 11.393 22.447 10.9271 22.0235C10.4612 21.6 9.9953 21.1341 9.61413 20.7106C9.23295 20.287 9.2753 19.6941 9.65648 19.3129C10.0377 18.9317 10.6306 18.9317 11.0541 19.3129C11.2659 19.4823 11.4353 19.6517 11.6047 19.9058Z'
        fill='currentColor'
      />
      <path
        d='M11.6047 26.5976C12.4094 25.7929 13.1294 25.0729 13.8918 24.3106C14.1882 24.0141 14.527 23.8447 14.9506 23.9718C15.7129 24.1835 15.967 25.0306 15.4588 25.5812C14.4423 26.64 13.3835 27.6988 12.3247 28.7153C11.9435 29.0965 11.3929 29.0541 11.0118 28.7153C10.5459 28.2918 10.08 27.8259 9.65646 27.36C9.27528 26.9365 9.31763 26.3435 9.69881 25.9623C10.08 25.5812 10.6729 25.5812 11.0965 25.9623C11.2659 26.2165 11.3929 26.3859 11.6047 26.5976Z'
        fill='currentColor'
      />
      <path
        d='M11.6894 12.7906C11.8165 12.5788 11.8588 12.4518 11.9435 12.3671C12.6212 11.6894 13.2565 11.0118 13.9765 10.3341C14.4 9.91061 14.9929 9.91061 15.3741 10.2918C15.7977 10.673 15.84 11.3083 15.3741 11.7318C14.4 12.7483 13.3412 13.7647 12.3671 14.7388C11.9012 15.2047 11.3929 15.1624 10.9271 14.6965C10.5035 14.273 10.08 13.8918 9.74118 13.5106C9.31765 13.0871 9.31765 12.4941 9.69883 12.0706C10.1224 11.6471 10.6729 11.6471 11.1388 12.0706C11.2659 12.2824 11.3929 12.4941 11.6894 12.7906Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ToDoIcon;
