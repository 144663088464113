import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const getWrapperWidth = (type) => {
  switch (type) {
    case 'search':
      return 521;
    case 'number':
      return 231;
    case 'calc':
      return 368;
  }
};

const useStyles = ({ type }) =>
  makeStyles(() => ({
    wrapper: {
      backgroundColor: pallete.modalBG,
      border: '2px solid #000000',
      borderRadius: 7,
      padding: '17px 12px',
      width: getWrapperWidth(type),
      // position: 'absolute',
      // top: '50%',
      // left: '50%',
    },
    header: {
      display: 'flex',
      width: '100%',
    },
    headerLinerWrapper: {
      width: `calc(100% - ${type === 'search' ? '44px' : '88px'})`,
      cursor: 'move',
    },
    headerLine: {
      width: '100%',
      height: 3,
      backgroundColor: pallete.textSecondary,
      marginBottom: 7.5,
    },
    headerLastLine: {
      marginBottom: 0,
    },
    btn: {
      backgroundColor: pallete.textSecondary,
      width: 34,
      height: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 0.9px 0px rgba(0, 0, 0, 0.3)',
      borderRadius: 8,
      marginLeft: 10,
      cursor: 'pointer',
    },
    calcBtnWrapeer: {
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      padding: 2,
    },
    calcTypeBtn: {
      width: 18,
      height: 16,
      backgroundColor: pallete.textPrimary,
      borderRadius: '4px 4px 4px 8px',
    },
    numberTypeBtn: {
      height: 26,
      width: 30,
      backgroundColor: pallete.textPrimary,
      borderRadius: 8,
    },
    content: {
      width: '100%',
    },
  }))();

export default useStyles;
