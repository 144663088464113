import { gql } from '@apollo/client';

export const CREATE_PRODUCTION_LIST_MUTATION = gql`
  mutation editProductionList(
    $productionListId: Int
    $name: String!
    $date: String!
    $databaseId: Int!
    $arrayRecipes: [ProductionListRecipeInput!]!
  ) {
    editProductionList(
      productionListId: $productionListId
      name: $name
      date: $date
      databaseId: $databaseId
      arrayRecipes: $arrayRecipes
    ) {
      status
    }
  }
`;

export const EDIT_RECIPE_MUTATION = gql`
  mutation editAmount(
    $productionListRecipeId: Int!
    $typeMeasurements: String!
    $count: Int
    $weight: Int
    $arrayAmounts: [AmountInput]
  ) {
    editAmount(
      productionListRecipeId: $productionListRecipeId
      typeMeasurements: $typeMeasurements
      count: $count
      weight: $weight
      arrayAmounts: $arrayAmounts
    ) {
      status
    }
  }
`;

export const IMPORT_PRODUCTION_LIST_MUTATION = gql`
  mutation importFile($databaseId: Int!, $file: Upload!) {
    importFile(databaseId: $databaseId, file: $file) {
      status
    }
  }
`;
