import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/system';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import SpinnerLogo from '../components/Spinner/SpinnerLogo';
import { CURRENT_USER_QUERY } from '../graphql/queries';
import {
  setTimeSpanFinish,
  setTimeSpanStart,
} from '../redux/reducers/productionLists';

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

//token
export const getToken = () => localStorage.getItem('fstoken');
export const setToken = (token) => localStorage.setItem('fstoken', token);
export const removeToken = () => localStorage.removeItem('fstoken');

//crossToken
export const getCrossToken = () => localStorage.getItem('fsCrossToken');
export const setCrossToken = (token) =>
  localStorage.setItem('fsCrossToken', token);

// Active Space To Login
export const getActiveSpaceToLogin = () =>
  JSON.parse(localStorage.getItem('activeSpaceToLogin'));
export const setActiveSpaceToLogin = (space) =>
  localStorage.setItem('activeSpaceToLogin', JSON.stringify(space));
export const removeActiveSpaceToLogin = () =>
  localStorage.removeItem('activeSpaceToLogin');

//active User For Pass Login
export const getActiveUserForPassLogin = () =>
  JSON.parse(localStorage.getItem('activeUserForPassLogin'));
export const setActiveUserForPassLogin = (user) =>
  localStorage.setItem('activeUserForPassLogin', JSON.stringify(user));
export const removeActiveUserForPassLogin = () =>
  localStorage.removeItem('activeUserForPassLogin');

const initialState = {
  user: null,
  allDBs: [],
  allUsersForDB: [],
  productionList: [],
  isAuth: false,
  hasToken: getToken(),
  pinError: false,
  loading: false,
};

const AuthContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      setToken(action.token);
      return {
        ...state,
        user: action.user,
        isAuth: true,
        hasToken: true,
        loading: false,
      };
    case 'SET_ALL_DBS':
      return {
        ...state,
        allDBs: action.payload,
        loading: false,
      };
    case 'REMOVE_ALL_DBS':
      return {
        ...state,
        allDBs: [],
      };
    case 'REMOVE_ALL_USERS_FOR_DB':
      return {
        ...state,
        allUsersForDB: [],
      };
    case 'SET_USER':
      if (action.payload) {
        return {
          ...state,
          user: action.payload,
          isAuth: true,
          hasToken: true,
          loading: false,
        };
      }
      return state;

    case 'SET_ALL_USERS_FOR_DB':
      return {
        ...state,
        allUsersForDB: action.payload,
        loading: false,
      };

    case 'LOGOUT':
      removeToken();
      removeActiveSpaceToLogin();
      removeActiveUserForPassLogin();
      return {
        ...state,
        user: null,
        isAuth: false,
        hasToken: false,
        loading: false,
        allDBs: [],
        allUsersForDB: [],
      };

    case 'START_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'FINISH_LOADING':
      return {
        ...state,
        loading: false,
      };

    case 'SET_PIN_ERROR':
      return {
        ...state,
        pinError: true,
      };
    case 'REMOVE_PIN_ERROR':
      return {
        ...state,
        pinError: false,
      };
    default:
      throw new Error();
  }
};

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [userInfo, dispatchReducer] = useReducer(reducer, initialState);
  const classes = useStyles();

  const [checkTokenQuery, { loading }] = useLazyQuery(CURRENT_USER_QUERY, {
    onCompleted: (response) => {
      if (!response.getCurrentUser) {
        handleLogout();
      } else {
        setUser(response.getCurrentUser);

        if (!response.getCurrentUser?.settings) {
          dispatch(setTimeSpanStart({ timeSpanStart: 'all' }));
          dispatch(setTimeSpanFinish({ timeSpanFinish: 'all' }));
        } else {
          dispatch(
            setTimeSpanStart({
              timeSpanStart:
                response.getCurrentUser?.settings?.settingSearchPast,
            }),
          );
          dispatch(
            setTimeSpanFinish({
              timeSpanFinish:
                response.getCurrentUser?.settings?.settingSearchFuture,
            }),
          );
        }
      }
    },
    onError: (error) => {
      handleLogout();
      if (error.message === 'User not found.') {
        toast(formatMessage({ id: 'user_not_found' }), {
          type: 'error',
        });
      }
    },
  });

  const handleLogin = (token, user) => {
    dispatchReducer({ type: 'LOGIN', token, user });
  };
  const handleLogout = () => {
    dispatchReducer({ type: 'LOGOUT' });
  };
  const setUser = (user) => {
    dispatchReducer({ type: 'SET_USER', payload: user });
  };
  const setAllDBsForUser = (dbs) => {
    dispatchReducer({ type: 'SET_ALL_DBS', payload: dbs });
  };
  const setAllUsersForDB = (users) => {
    dispatchReducer({ type: 'SET_ALL_USERS_FOR_DB', payload: users });
  };
  const removeAllDBsForUser = () => {
    dispatchReducer({ type: 'REMOVE_ALL_DBS' });
  };
  const removellUsersForDB = () => {
    dispatchReducer({ type: 'REMOVE_ALL_USERS_FOR_DB' });
  };
  const setPINError = () => {
    dispatchReducer({ type: 'SET_PIN_ERROR' });
  };
  const removePINError = () => {
    dispatchReducer({ type: 'REMOVE_PIN_ERROR' });
  };
  const startLoading = () => {
    dispatchReducer({ type: 'START_LOADING' });
  };
  const finishLoading = () => {
    dispatchReducer({ type: 'FINISH_LOADING' });
  };

  const value = {
    isAuth: userInfo.isAuth,
    loading: userInfo.loading,
    allDBs: userInfo.allDBs,
    allUsersForDB: userInfo.allUsersForDB,
    user: userInfo?.user,
    hasToken: userInfo?.hasToken,
    pinError: userInfo?.pinError,
    handleLogout,
    handleLogin,
    setUser,
    setAllDBsForUser,
    setAllUsersForDB,
    removeAllDBsForUser,
    removellUsersForDB,
    setPINError,
    removePINError,
    startLoading,
    finishLoading,
  };

  const crossToken = new URLSearchParams(location.search).get('t');

  useEffect(() => {
    console.log(crossToken);
    if (crossToken) {
      value.handleLogout();
    } else if (value.hasToken) {
      startLoading();
      checkTokenQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crossToken]);

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <Box className={classes.spinnerWrapper}>
          <SpinnerLogo />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const useStyles = () =>
  makeStyles(() => ({
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
    },
  }))();

export { AuthProvider };
