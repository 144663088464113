import { Box } from '@material-ui/system';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CustomSelect from '../../components/CustomSelect/CustomSelect';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import ProductionListLayout from '../../components/Layout/ProductionListLayout/ProductionListLayout';
import { ROUTES } from '../../config/constants';
import { useImportProductionListMutation } from '../../hooks/useMutations';
import {
  useDeleteProducList,
  useDublicateProducList,
  useEditeSittings,
  useProductionListTableQuery,
} from '../../hooks/useQueries';
// import { productionListMoc } from '../../moc/productionsList';
import {
  setTimeSpanFinish,
  setTimeSpanStart,
} from '../../redux/reducers/productionLists';
import { productionListsSelector } from '../../redux/selectors';
import ProductionListsBtn from './ProductionListsBtn/ProductionListsBtn';
import ProductionListTable from './ProductionListsTable/ProductionListTable';
import useStyles from './styles';

const ProductionLists = () => {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [productionListQuery, { loading }] = useProductionListTableQuery();
  const [dublicateListQuery, { loading: dublicateLoading }] =
    useDublicateProducList();

  const [deleteListQuery, { loading: deleteLoading }] = useDeleteProducList();
  const [editeSettingsQuery, { loading: editeSettingsLoading }] =
    useEditeSittings();

  const [importPL, { loading: importPLLoading }] =
    useImportProductionListMutation();

  const {
    timeSpanStart,
    timeSpanFinish,
    activeRow,
    sortBy,
    sortType,
    productionLists,
    queryCount,
  } = useSelector(productionListsSelector);

  const databaseId = JSON.parse(localStorage.getItem('activeSpaceToLogin'))?.id;

  const handleChangeStartTime = (event) => {
    dispatch(setTimeSpanStart({ timeSpanStart: event.target.value }));
  };

  const handleChangeFinishTime = (event) => {
    dispatch(setTimeSpanFinish({ timeSpanFinish: event.target.value }));
  };

  const TIME_SPAN_START_OPTIONS = [
    { label: '- all', value: 'all' },
    { label: '- 3 days', value: '3' },
    { label: '- 2 days', value: '2' },
    { label: '- 1 day', value: '1' },
    { label: 'today', value: 'today' },
    {
      label: '+ 1 day',
      value: '+1',
      disabled: timeSpanFinish === 'today' ? true : false,
    },
    {
      label: '+ 2 days',
      value: '+2',
      disabled:
        timeSpanFinish === '1' || timeSpanFinish === 'today' ? true : false,
    },
  ];

  const TIME_SPAN_FINISH_OPTIONS = [
    {
      label: 'today',
      value: 'today',
      disabled: timeSpanStart === '+1' || timeSpanStart === '+2' ? true : false,
    },
    {
      label: '+ 1 day',
      value: '1',
      disabled: timeSpanStart === '+2' ? true : false,
    },
    { label: '+ 2 days', value: '2' },
    { label: '+ 3 days', value: '3' },
    { label: '+ all', value: 'all' },
  ];

  const handleDublicate = () => {
    dublicateListQuery({
      variables: {
        productionListId: +activeRow?.id,
      },
    });
  };

  const handleDelete = () => {
    deleteListQuery({
      variables: {
        arrayProductionListId: [+activeRow?.id],
      },
    });
  };

  const handleEditeSettings = () => {
    editeSettingsQuery({
      variables: {
        input: {
          settingSearchPast: timeSpanStart,
          settingSearchFuture: timeSpanFinish,
          //todo
          timeDefined: 1,
        },
      },
    });
  };

  const handleImportPL = (file) => {
    importPL({
      variables: {
        databaseId: +databaseId,
        file,
      },
    });
  };

  useEffect(() => {
    productionListQuery({
      variables: {
        databaseId: +databaseId,
        sortBy,
        sortType,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortType, queryCount]);

  useEffect(() => {
    handleEditeSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSpanStart, timeSpanFinish]);

  return (
    <MainLayout>
      <ProductionListLayout
        title='production_lists_title'
        loading={
          loading || dublicateLoading || deleteLoading || editeSettingsLoading
        }
      >
        <Box className={classes.btnsWrapper}>
          <ProductionListsBtn
            title='create_btn_title'
            isActive={true}
            action={() => push(`${ROUTES.productionLists}/new`)}
          />
          <ProductionListsBtn
            title='edit_btn_title'
            isActive={activeRow}
            action={() => push(`${ROUTES.productionLists}/${activeRow?.id}`)}
          />
          <ProductionListsBtn
            title='dublicate_btn_title'
            isActive={activeRow}
            action={handleDublicate}
          />
          <ProductionListsBtn
            title='delete_btn_title'
            isActive={activeRow}
            action={handleDelete}
          />
          <ProductionListsBtn
            title='import_btn_title'
            isActive={!importPLLoading}
            type='file'
            action={handleImportPL}
          />
        </Box>
        <Box className={classes.selectsWrapper}>
          <Box className={classes.timeSpanTitle}>
            {formatMessage({ id: 'time_span_title' })}
          </Box>

          <CustomSelect
            value={timeSpanStart}
            onChange={handleChangeStartTime}
            itemsList={TIME_SPAN_START_OPTIONS}
            width={140}
            paperWidth={140}
            papperML={0}
            ml={0}
          />

          <Box className={classes.toTitle}>
            {formatMessage({ id: 'to_title' })}
          </Box>

          <CustomSelect
            value={timeSpanFinish}
            onChange={handleChangeFinishTime}
            itemsList={TIME_SPAN_FINISH_OPTIONS}
            width={140}
            paperWidth={140}
            papperML={0}
            ml={0}
          />
        </Box>

        <ProductionListTable listItems={productionLists} />
      </ProductionListLayout>
    </MainLayout>
  );
};

export default ProductionLists;
