import { makeStyles } from '@material-ui/styles';

const useStyles = () =>
  makeStyles(() => ({
    wrapper: {
      padding: '15px 20px',
    },
    contentWrapper: {
      height: 'calc(100vh - 198px)',
      overflowY: 'auto',
      paddingTop: 16,
    },
  }))();

export default useStyles;
