import ArrowLeftIcon from '../components/icons/ArrowLeftIcon';
import CupIcon from '../components/icons/CupIcon';
import DoneIcon from '../components/icons/DoneIcon';
import inProgressIcon from '../components/icons/inProgressIcon';
import ParkingIcon from '../components/icons/ParkingIcon';
import ProductLogIcon from '../components/icons/ProductLogIcon';
import ResipesListIcon from '../components/icons/ResipesListIcon';
import Settings from '../components/icons/SettingsIcon';
import ToDoIcon from '../components/icons/ToDoIcon';
import AllRecipes from '../pages/AllRecipes/AllRecipes';
import TestComponent from '../pages/TestComponent/TestComponent';
import ToDo from '../pages/ToDo/ToDo';
import enFlagImg from './../img/en-flag-icon.png';
import scaleImg from './../img/scale-1.png';

export const API_HOST =  process.env.API_HOST;
export const BASE_URL = '';

export const DEFAULT_LOCALE = 'en';
export const LOCALE_OPTIONS = [
  { value: 'en-GB', imgUrl: enFlagImg },
  // { value: 'de-DE', imgUrl: enFlagImg },
];

export const SCALES_OPTIONS = [
  { value: 'scale 1', imgUrl: scaleImg },
  // { value: 'scale 2', imgUrl: scaleImg },
];

export const LOGIN_OPTIONS = [{ value: 'logout', title: 'LOGOUT' }];

export const MENU_OPTIONS = [
  { title: 'Hide navigation', value: 'hide_menu', icon: ArrowLeftIcon },
  { title: 'Production list', value: 'production_list', icon: CupIcon },
  { title: 'Production Log', value: '/', icon: ProductLogIcon },
  { title: 'Settings', value: '/', icon: Settings },
];

export const FONT_FAMILIES = {
  REGULAR: "'Open Sans', sans-serif",
};

export const KEYBOARD_FOR_PIN_OPTIONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
];

export const MAX_PIN_LENGTH = 8;

export const ACCORDION_ITEMS_OPTIONS = [
  {
    btnTitle: 'all_recipes_block_title',
    contentTitle: 'all_recipes_block_title',
    count: '1233 3',
    content: 'content',
    Icon: ResipesListIcon,
    children: AllRecipes,
  },
  {
    btnTitle: 'todo_block_title',
    contentTitle: 'todo_block_title_up',
    count: '1233 3',
    content: 'content',
    Icon: ToDoIcon,
    children: ToDo,
  },
  {
    btnTitle: 'parking_block_title',
    contentTitle: 'parking_block_title_up',
    count: '1233 3',
    content: 'content',
    Icon: ParkingIcon,
    children: TestComponent,
  },
  {
    btnTitle: 'in_progress_block_title',
    contentTitle: 'in_progress_block_title_up',
    count: '1233 3',
    content: 'content',
    Icon: inProgressIcon,
    children: TestComponent,
  },
  {
    btnTitle: 'done_block_title',
    contentTitle: 'done_block_title',
    count: '1233 3',
    content: 'content',
    Icon: DoneIcon,
    children: TestComponent,
  },
];

export const ROUTES = {
  //HOME
  home: '/',
  // LOGIN
  loginChoiceAccount: '/login-choice-account',
  loginToSpace: '/login-to-space',
  userLogin: '/user-login',
  userLoginPassword: '/user-login-password',
  userLoginPin: '/user-login-pin',
  productionLists: '/production-lists',
  productionList: '/production-lists/:id',
};

export const MEASUREMENT_SELECT_OPTIONS = [
  { label: 'amount products', value: 'amount products' },
  { label: 'complete sum', value: 'complete sum' },
  { label: 'x-time the base recipe', value: 'x-time the base recipe' },
];

export const PRINT_MODAL_BTN_OPTIONS = [
  { title: 'print_production_list_title' },
  { title: 'print_parking_list_title' },
  { title: 'print_recipe_title' },
];
