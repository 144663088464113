import { Box } from '@material-ui/system';
import React from 'react';

import useStyles from './styles';

const ActionBtn = ({
  isActive,
  action = () => {},
  Icon,
  marginLeft = 0,
  marginRight = 0,
  isDisable,
}) => {
  const classes = useStyles({ isActive, marginLeft, marginRight, isDisable });
  return (
    <Box className={classes.wrapper} onClick={() => !isDisable && action()}>
      <Icon />
    </Box>
  );
};

export default ActionBtn;
