import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recipes: [],
  activeRecipe: null,
  search: '',
  sortType: 'ASC',
  isFavorite: false,
  category: 'all',
};

export const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    setRecipes: (state, action) => {
      state.recipes = action.payload.recipes;
    },
    setActiveRecipe: (state, action) => {
      state.activeRecipe = action.payload.activeRecipe;
    },
    setSearch: (state, action) => {
      state.search = action.payload.search;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload.sortType;
    },
    setCategory: (state, action) => {
      state.category = action.payload.category;
    },
    setIsFavorite: (state, action) => {
      state.isFavorite = action.payload.isFavorite;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setRecipes,
  setActiveRecipe,
  setSearch,
  setSortType,
  setCategory,
  setIsFavorite,
} = recipesSlice.actions;

export default recipesSlice.reducer;
