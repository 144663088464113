import { TextField, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import ArrowLeftStrongIcon from '../../../components/icons/ArrowLeftStrongIcon';
import CompleteIcon from '../../../components/icons/CompleteIcon';
import AuthLayout from '../../../components/Layout/AuthLayout/AuthLayout';
import MainLayout from '../../../components/Layout/MainLayout/MainLayout';
import SpinnerLogo from '../../../components/Spinner/SpinnerLogo';
import { API_HOST, ROUTES } from '../../../config/constants';
import {
  getActiveUserForPassLogin,
  useAuthContext,
} from '../../../context/AuthContext';
import { useSignViaPasswordQuery } from '../../../hooks/useQueries';
import fontSizes from '../../../theme/fontSizes';
import useStyles from './styles';

const UserLoginPassword = () => {
  const { formatMessage } = useIntl();
  const { removellUsersForDB } = useAuthContext();
  const [passwordValue, setPasswordValue] = useState('');
  const [query] = useSignViaPasswordQuery();
  const { push } = useHistory();
  const { loading, startLoading } = useAuthContext();
  const user = getActiveUserForPassLogin();

  const handleChangePage = () => {
    removellUsersForDB();
    push(ROUTES.loginChoiceAccount);
  };

  const isValid = passwordValue.length > 0;

  const classes = useStyles({ isValid });

  const handleChange = (event) => {
    setPasswordValue(event.target.value);
  };

  const deleteLastPasswordSymbol = () => {
    let newPinValue = passwordValue;
    if (passwordValue.length > 0) {
      setPasswordValue(newPinValue.slice(0, -1));
    }
  };

  return (
    <MainLayout>
      <AuthLayout
        title='user_login_title'
        botomBtnTitle={'go_to_login_selection'}
        bottomBtnAction={handleChangePage}
      >
        <Typography
          fontSize={fontSizes.ml}
          marginTop='30px'
          marginBottom={'25px'}
        >
          {formatMessage({ id: 'login_to_accoutnt_with' })}
        </Typography>
        <Box className={classes.userInfoWrapper}>
          <img
            src={`${API_HOST}/${user?.avatar}`}
            alt={`${user?.firstName} ${user?.lastName}`}
          />
          <Typography
            fontSize={fontSizes.xl}
          >{`${user?.firstName} ${user?.lastName}`}</Typography>
        </Box>

        <Typography fontSize={fontSizes.ml} marginBottom={'25px'}>
          {formatMessage({ id: 'password_title' })}
        </Typography>
        {loading ? (
          <SpinnerLogo />
        ) : (
          <Box className={classes.enterPasswordWrapper}>
            <TextField
              type='password'
              value={passwordValue}
              classes={{ root: classes.passwordInput }}
              onChange={handleChange}
            />
            <Box className={classes.btn} onClick={deleteLastPasswordSymbol}>
              <ArrowLeftStrongIcon width={13} height={12} />
            </Box>
            <Box
              className={classes.btn}
              onClick={async () => {
                startLoading();
                await query({
                  variables: {
                    email: user?.email,
                    password: passwordValue,
                  },
                });

                setPasswordValue('');
              }}
            >
              <CompleteIcon width={13} height={13} />
            </Box>
          </Box>
        )}
      </AuthLayout>
    </MainLayout>
  );
};

export default UserLoginPassword;
