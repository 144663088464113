import { Box } from '@material-ui/system';
import { Field, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import SelectArrowIcon from '../../../components/icons/SelectArrowIcon';
import useStyles from './styles';

const ProductionListsBtn = ({
  title,
  mr = 15,
  isActive,
  action = () => {},
  type = 'text',
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ mr, isActive });
  return (
    <>
      {type === 'text' ? (
        <Box className={classes.wrapper} onClick={() => isActive && action()}>
          <SelectArrowIcon width={18} height={18} />
          <Box className={classes.btnTitle}>{formatMessage({ id: title })}</Box>
        </Box>
      ) : (
        <label htmlFor='file'>
          <Box className={classes.wrapper}>
            <Formik
              initialValues={{
                file: '',
              }}
            >
              {() => (
                <Field
                  type='file'
                  name={`file`}
                  id={`file`}
                  onChange={async (e) => {
                    let file = await e.target.files[0];
                    file && action(file);
                  }}
                  className={classes.fileInput}
                />
              )}
            </Formik>
            <SelectArrowIcon width={18} height={18} />
            <Box className={classes.btnTitle}>
              {formatMessage({ id: title })}
            </Box>
          </Box>
        </label>
      )}
    </>
  );
};

export default ProductionListsBtn;
