import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const useStyles = ({ isActive, marginLeft, marginRight, isDisable }) => {
  return makeStyles(() => ({
    wrapper: {
      width: 58,
      height: 58,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: isActive ? pallete.textSecondary : pallete.textPrimary,
      backgroundColor: isActive ? pallete.buttonActiveBg : pallete.buttonBg,
      border: '1px solid #000',
      cursor: isDisable ? 'auto' : 'pointer',
      borderRadius: 7,
      marginLeft,
      marginRight,
      opacity: isDisable ? 0.4 : 1,
    },
  }))();
};

export default useStyles;
