import { useLazyQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { PRODUCTION_LIST_QUERY } from '../graphql/queries';
import { productionListsSelector } from '../redux/selectors';
import { useAuthContext } from './AuthContext';

export function useAccordionContext() {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error(
      'useAccordionContext must be used within a AccordionProvider',
    );
  }
  return context;
}

const initialState = {
  activeTab: '',
  //productionList
  productionList: [],
  activeDayId: '',
  //categories
  categories: [],
};

const AccordionContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    //productionList;
    case 'SET_PRODUCT_LIST':
      return {
        ...state,
        productionList: action.payload,
      };
    case 'SET_ACTIVE_DAY':
      return {
        ...state,
        activeDayId: action.payload,
      };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload };

    default:
      throw new Error();
  }
};

const AccordionProvider = ({ children }) => {
  const { isAuth } = useAuthContext();
  const { productionLists } = useSelector(productionListsSelector);
  const [accordionInfo, dispatch] = useReducer(reducer, initialState);

  const [productionListQuery] = useLazyQuery(PRODUCTION_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.getProductionListsDatabase) {
        dispatch({
          type: 'SET_PRODUCT_LIST',
          payload: response?.getProductionListsDatabase?.map((item) => {
            return { ...item };
          }),
        });
      }
    },
  });

  const setActiveTab = (activeTab) => {
    dispatch({ type: 'SET_ACTIVE_TAB', payload: activeTab });
  };
  //PRODUCTION LIST
  const setProductList = (productList) => {
    dispatch({ type: 'SET_PRODUCT_LIST', payload: productList });
  };

  const setActiveDay = (day) => {
    dispatch({ type: 'SET_ACTIVE_DAY', payload: day });
  };

  const setCategories = (categories) => {
    dispatch({ type: 'SET_CATEGORIES', payload: categories });
  };

  const value = {
    activeTab: accordionInfo.activeTab,
    setActiveTab,
    //productionList
    setProductList,
    setActiveDay,
    productionList: accordionInfo.productionList,
    activeDayId: accordionInfo.activeDayId,
    //categories
    categories: accordionInfo.categories,
    setCategories,
  };

  useEffect(() => {
    const databaseId = JSON.parse(
      localStorage.getItem('activeSpaceToLogin'),
    )?.id;

    isAuth &&
      databaseId &&
      productionListQuery({
        variables: {
          databaseId: +databaseId,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, productionLists]);

  useEffect(() => {
    const isHasActivedayIdIndex = value?.productionList
      ?.map((item) => +item.id)
      .indexOf(+value?.activeDayId);

    if (isHasActivedayIdIndex < 0) {
      if (value?.productionList.length > 0) {
        setActiveDay(value?.productionList[0]?.id);
      } else {
        setActiveDay('');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.productionList]);

  return (
    <AccordionContext.Provider value={value}>
      {children}
    </AccordionContext.Provider>
  );
};

export { AccordionProvider };
