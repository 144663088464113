import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const useStyles = ({ isAuth, isProductionListPage }) => {
  return makeStyles(() => ({
    accardionBtn: {
      width: 51,
      marginLeft: 2.5,
      marginRight: 2.5,
      backgroundColor: pallete.secondary1,
      borderRadius: 7,
      border: '1px solid #000',
      paddingTop: 15,
      paddingBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: pallete.textPrimary,
      cursor: !isProductionListPage && isAuth ? 'pointer' : 'auto',
      overflow: 'hidden',
      opacity: !isProductionListPage && isAuth ? 1 : 0.4,
    },

    titleWrapper: {
      height: 200,
      color: pallete.textPrimary,
      '& svg': {
        transform: 'rotate(180deg)',
        margin: 'auto',
        display: 'block',
        marginBottom: 75,
      },
      '& p': {
        transform: 'rotate(-90deg)',
        textAlign: 'center',
      },
    },
    countWrapper: {
      position: 'relative',
      '& p': {
        transform: 'rotate(-90deg)',
        position: 'absolute',
        marginBottom: 55,
        bottom: 0,
        left: '-65px',
      },
    },
    activeBtn: {
      color: pallete.textSecondary,
      backgroundColor: pallete.primary,
      '& p': {
        color: pallete.textSecondary,
      },
      '& svg': {
        color: pallete.textSecondary,
        transform: 'rotate(0deg)',
      },
    },

    contentWrapper: {
      width: 0,
      transition: 'all 1s',
      overflow: 'hidden',
    },
    activeContent: {
      width: 'calc(100% - 280px)',
    },
    content: {
      padding: '7px 15px 20px',
      height: 'calc(100vh - 76px)',
    },
    pageWrapper: {
      height: 'calc(100vh - 170px)',
    },
  }))();
};

export default useStyles;
