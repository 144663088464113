import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { API_HOST } from '../../../config/constants';
import { getActiveSpaceToLogin } from '../../../context/AuthContext';
import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import useStyles from './styles';

const AuthLayout = ({
  children,
  title,
  botomBtnTitle,
  bottomBtnAction = () => {},
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const activeSpaceToLogin = getActiveSpaceToLogin();

  return (
    <Box className={classes.wrapper}>
      <Typography fontSize={fontSizes.lg} fontWeight={fontWeights.BOLD}>
        {formatMessage({ id: title })}
      </Typography>
      <Box className={classes.contentWrapper}>
        <Box className={classes.content}>
          {title && title === 'user_login_title' ? (
            <Box className={classes.spaceInfoWrapper}>
              <Typography fontSize={fontSizes.ml} marginBottom={'9px'}>
                {formatMessage({ id: 'source_login_to_space_title' })}
              </Typography>
              <Box className={classes.spaceInfoRow}>
                <img
                  src={`${API_HOST}/${activeSpaceToLogin?.owner?.avatar}`}
                  alt={activeSpaceToLogin?.name}
                />
                <Typography fontSize={fontSizes.xl}>
                  {activeSpaceToLogin?.name}
                </Typography>
              </Box>
            </Box>
          ) : null}
          {children}
        </Box>
      </Box>
      {botomBtnTitle ? (
        <Box className={classes.actionBtnWrapper}>
          <Button variant='text' onClick={bottomBtnAction}>
            {formatMessage({ id: botomBtnTitle })}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default AuthLayout;
