import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    spaceBtn: {
      backgroundColor: pallete.secondary1,
      marginLeft: 10,
      width: 565,
      height: 70,
      marginBottom: 29,
      border: '1px solid #000000',
      borderRadius: 7,
      display: 'flex',
      alignItems: 'center',
      transition: ' all .5s',
      paddingLeft: 12,
      paddingRight: 12,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: pallete.buttonBg,
      },
      '& img': {
        width: 35,
        height: 35,
        marginRight: 17,
        borderRadius: '50%',
        border: '1px solid #fff',
      },
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }))();

export default useStyles;
