import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import pallete from '../../theme/pallete';

const useStyles = ({ isEdit }) => {
  return makeStyles(() => ({
    content: {
      height: 'calc(100vh - 288px)',
      width: isEdit ? '100%' : 545,
      margin: '0 auto',
      marginTop: 30,
      marginBottom: 30,
      overflowY: 'auto',
    },
    settingPageWrapper: {
      marginLeft: 6,
      marginRight: 6,
    },
    settingRow: {
      display: 'flex',
    },
    settingHeaderElem: {
      fontWeight: fontWeights.BOLD,
      fontSize: fontSizes.exrta32,
      border: '3px solid #BABC1A',
      borderRadius: 7,
      paddingLeft: 16,
      paddingRight: 16,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    settingHeaderTitle: {
      flexGrow: 1,
    },
    settingHeaderWeight: {
      marginLeft: 11,
    },
    settingSelect: {
      flexGrow: 1,
    },
    resetSelectBtnWrapper: {
      padding: '4px 14px',
      display: 'flex',
      alignItems: 'center',
      width: 133,
      height: 40,
      background: pallete.buttonBg,
      border: '1px solid #000000',
      borderRadius: 7,
      cursor: 'pointer',
      marginLeft: 70,
      marginBottom: 25,
      '& p': {
        marginLeft: 20,
        fontSize: fontSizes.md,
        fontWeight: fontWeights.BOLD,
      },
    },

    ingridientsRowsWrapper: {
      height: 175,
      overflowY: 'auto',
    },
    ingridientRow: {
      alignItems: 'center',
      marginBottom: 10,
      paddingRight: 17,
    },
    ingridientInput: {
      marginRight: 8,
      marginLeft: 8,
      height: 48,
      width: 117,
      fontSize: fontSizes.ml,
      fontWeight: fontWeights.BOLD,
      padding: 0,
      textAlign: 'end',
      paddingRight: 12,
      border: '4px solid #A9A9A9',
      borderRadius: 7,
      outline: 'none',
      '&:focus-visible': {
        border: '4px solid #BABC1A',
      },
    },
    infridientTittle: {
      flexGrow: 1,
    },
    infridientText: {
      fontSize: fontSizes.exrta22,
      fontWeight: fontWeights.BOLD,
    },
    ingridientMeasure: {
      marginRight: 28,
    },
    deleteBtn: {
      width: 40,
      height: 40,
      borderRadius: 7,
      border: '1px solid #000000',
      backgroundColor: pallete.buttonBg,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    completeSumWrapper: {
      marginTop: 40,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 35,
    },
    completeSumTitle: {
      fontSize: fontSizes.ml,
      fontWeight: fontWeights.BOLD,
      marginRight: 30,
    },
    completeSumValue: {
      fontWeight: fontWeights.BOLD,
      fontSize: fontSizes.extra40,

      border: '4px solid #A9A9A9',
      borderRadius: 7,
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 14,
      width: 250,
      outline: 'none',
      textAlign: 'right',
      '&:focus-visible': {
        border: '4px solid #BABC1A',
      },
    },
    xValueInput: {
      width: 162,
    },
    recipesBaseWeightTitle: {
      textAlign: 'center',
      marginTop: 20,
      fontWeight: fontWeights.BOLD,
      fontSize: fontSizes.exrta32,
    },
    cancelBtn: {
      width: 111,
      height: 23,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.BOLD,
      background: pallete.buttonBg,
      border: '1px solid #000000',
      borderRadius: 7,
      cursor: 'pointer',
      marginRight: 24,
    },
    okBtn: {
      width: 138,
      height: 46,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: fontSizes.exrta32,
      fontWeight: fontWeights.BOLD,
      background: pallete.buttonBg,
      border: '1px solid #000000',
      borderRadius: 7,
      cursor: 'pointer',
      textTransform: 'uppercase',
      paddingLeft: 14,
      paddingRight: 14,
    },
  }))();
};

export default useStyles;
