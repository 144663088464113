import { makeStyles } from '@material-ui/styles';

const useStyles = () =>
  makeStyles(() => ({
    tableHeader: {
      display: 'flex',
      paddingLeft: 35,
      paddingBottom: 10,
      borderBottom: '1px solid #757575',
      marginRight: 23,
    },
  }))();

export default useStyles;
