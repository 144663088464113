import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import AuthLayout from '../../../components/Layout/AuthLayout/AuthLayout';
import MainLayout from '../../../components/Layout/MainLayout/MainLayout';
import SpinnerLogo from '../../../components/Spinner/SpinnerLogo';
import { API_HOST, ROUTES } from '../../../config/constants';
import {
  setActiveSpaceToLogin,
  useAuthContext,
} from '../../../context/AuthContext';
import {
  useCrossAuthQuery,
  useUserAllDatabassesQuery,
} from '../../../hooks/useQueries';
import fontSizes from '../../../theme/fontSizes';
import useStyles from './styles';

const LoginToSpace = () => {
  const { formatMessage } = useIntl();

  const classes = useStyles();
  let location = useLocation();
  const { push } = useHistory();
  const { loading, startLoading, allDBs } = useAuthContext();
  const [crossAuthQuery] = useCrossAuthQuery();
  const [allDBsQuery] = useUserAllDatabassesQuery();

  useEffect(() => {
    const crossToken = new URLSearchParams(location.search).get('t');
    startLoading();
    if (crossToken) {
      crossAuthQuery({
        variables: {
          auth_token: crossToken,
        },
      });
    } else {
      allDBsQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <AuthLayout title='login_to_space_title'>
        <Typography
          fontSize={fontSizes.ml}
          marginTop='115px'
          marginBottom={'29px'}
        >
          {formatMessage({ id: 'choose_space' })}
        </Typography>

        {loading ? (
          <SpinnerLogo />
        ) : (
          allDBs?.map((space, index) => {
            return (
              <Box
                key={index}
                className={classes.spaceBtn}
                onClick={() => {
                  setActiveSpaceToLogin(space);
                  push(ROUTES.userLogin);
                }}
              >
                <img
                  src={`${API_HOST}/${space?.owner?.avatar}`}
                  alt='space img'
                />
                <Typography fontSize={fontSizes.ml}>{space?.name}</Typography>
              </Box>
            );
          })
        )}
      </AuthLayout>
    </MainLayout>
  );
};

export default LoginToSpace;
