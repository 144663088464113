import { gql } from '@apollo/client';

export const CROSS_AUTH_QUERY = gql`
  query crossAuth($auth_token: String!) {
    crossAuth(auth_token: $auth_token) {
      token
    }
  }
`;

export const USER_ALL_DATABASES_QUERY = gql`
  query {
    getUserAllDatabases {
      databases {
        id
        name
        owner {
          avatar
        }
      }
    }
  }
`;

export const SIGN_VIA_PIN_QUERY = gql`
  query signViaPin($databaseId: Int!, $pin: Int!) {
    signViaPin(databaseId: $databaseId, pin: $pin) {
      token
      user {
        firstName
        lastName
        avatar
        id
      }
    }
  }
`;

export const SIGN_VIA_PASSWORD_QUERY = gql`
  query signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      user {
        firstName
        lastName
        avatar
        id
        settings {
          settingSearchFuture
          settingSearchPast
        }
      }
    }
  }
`;

export const USERS_DATAREFERENCE_QUERY = gql`
  query getUsersDatareference($databaseId: Int!) {
    getUsersDatareference(databaseId: $databaseId) {
      firstName
      lastName
      avatar
      id
      email
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query {
    getCurrentUser {
      id
      firstName
      lastName
      avatar
      settings {
        settingSearchFuture
        settingSearchPast
      }
    }
  }
`;

export const PRODUCTION_LIST_QUERY = gql`
  query getProductionListsDatabase(
    $databaseId: Int!
    $sortBy: String
    $sortType: String
  ) {
    getProductionListsDatabase(
      databaseId: $databaseId
      sortBy: $sortBy
      sortType: $sortType
    ) {
      id
      name
      createAt
      forADay
      getDone
      getPositions
    }
  }
`;

export const RECIPES_QUERY = gql`
  query getRecipes($input: GetRecipeInput!) {
    getRecipes(input: $input) {
      data {
        rows {
          id
          name
          group {
            categoryColor
            outlineColor
            starColor
            name
            id
          }
          databaseId
          isFavorite
        }
        count
      }
    }
  }
`;

export const SEARCH_RECIPES_QUERY = gql`
  query searchRecipesInProductionList(
    $search: String
    $state: String!
    $categoryId: Int
    $productionListId: Int!
  ) {
    searchRecipesInProductionList(
      search: $search
      state: $state
      categoryId: $categoryId
      productionListId: $productionListId
    ) {
      id
      state
      productionList {
        name
        forADay
      }
      recipe {
        id
        name
        group {
          categoryColor
          outlineColor
          starColor
          name
          id
        }
      }
      baseWeight
      count
      typeMeasurements
      weight
      order
      amount {
        id
        recipeItemId
        name
        weight
        amount
        unit {
          fullName
          id
        }
      }
    }
  }
`;

export const GET_CATEGORIES_QUERY = gql`
  query getRecipesGroupByDatabaseId($databaseId: Int!) {
    getRecipesGroupByDatabaseId(databaseId: $databaseId) {
      id
      name
    }
  }
`;

export const TRANSFER_TAB_QUERY = gql`
  query transferTabs(
    $productionListId: Int!
    $productionListRecipeId: Int!
    $recipeState: String!
  ) {
    transferTabs(
      productionListId: $productionListId
      productionListRecipeId: $productionListRecipeId
      recipeState: $recipeState
    ) {
      status
    }
  }
`;

export const ADD_RECIPE_TO_FAVORITE_QUERY = gql`
  query addToFavorite($recipeId: Int!) {
    addToFavorite(recipeId: $recipeId) {
      status
    }
  }
`;

export const DELETE_RECIPE_TO_FAVORITE_QUERY = gql`
  query deleteFavorite($recipeId: Int!) {
    deleteFavorite(recipeId: $recipeId) {
      status
    }
  }
`;

export const ADD_RECIPE_TO_PRODUCTION_LIST_QUERY = gql`
  query addToProductionListRecipe(
    $productionListId: Int!
    $input: ProductionListRecipeAdd
  ) {
    addToProductionListRecipe(
      productionListId: $productionListId
      input: $input
    ) {
      status
    }
  }
`;

export const DELETE_RECIPE_QUERY = gql`
  query deleteRecipeFromProductionList(
    $productionListId: Int!
    $productionListRecipeId: Int!
  ) {
    deleteRecipeFromProductionList(
      productionListId: $productionListId
      productionListRecipeId: $productionListRecipeId
    ) {
      status
    }
  }
`;

export const DUBLICATE_PRODUCT_LIST_QUERY = gql`
  query dublicateProductionList($productionListId: Int!) {
    dublicateProductionList(productionListId: $productionListId) {
      status
    }
  }
`;

export const DELETE_PRODUCT_LIST_QUERY = gql`
  query deleteProductionList($arrayProductionListId: [Int!]!) {
    deleteProductionList(arrayProductionListId: $arrayProductionListId) {
      status
    }
  }
`;

export const EDITE_SETTINGS_QUERY = gql`
  query editSettings($input: SettingsInput!) {
    editSettings(input: $input) {
      status
    }
  }
`;

export const GET_PRODUCTION_LIST_QUERY = gql`
  query getProductionListRecipes($databaseId: Int!, $productionListId: Int!) {
    getProductionListRecipes(
      databaseId: $databaseId
      productionListId: $productionListId
    ) {
      productionList {
        name
        forADay
      }
      productionListRecipeList {
        id
        recipe {
          id
          name
          group {
            categoryColor
            outlineColor
            starColor
            name
            id
          }
        }
        baseWeight
        count
        typeMeasurements
        weight
        order
        amount {
          id
          recipeItemId
          name
          weight
          amount
          unit {
            fullName
            id
          }
        }
      }
    }
  }
`;

export const GET_RECIPE_BY_ID_QUERY = gql`
  query getRecipeById($databaseId: Int, $recipeId: Int) {
    getRecipeById(databaseId: $databaseId, recipeId: $recipeId) {
      id
      name
      weight
      items {
        id
        recipeItemId
        countIngredient
        ingredient {
          name
          weightPerUnit
          unit {
            fullName
            id
          }
        }
        recipeIngredient {
          name
          weight
          unit {
            fullName
            id
          }
        }
      }
    }
  }
`;
