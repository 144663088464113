import React from 'react';

const ProductLogIcon = () => {
  return (
    <svg
      width='37'
      height='37'
      viewBox='0 0 37 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5 35.9553C8.74941 35.9553 0.827057 28.0329 0.827057 18.2823C0.827057 8.53173 8.74941 0.609375 18.5 0.609375C28.2506 0.609375 36.1729 8.53173 36.1729 18.2823C36.1729 28.0329 28.2506 35.9553 18.5 35.9553ZM18.5 3.65643C10.4471 3.65643 3.87412 10.2294 3.87412 18.2823C3.87412 26.3353 10.4471 32.9082 18.5 32.9082C26.5529 32.9082 33.1259 26.3353 33.1259 18.2823C33.1259 10.2294 26.5529 3.65643 18.5 3.65643Z'
        fill='currentColor'
      />
      <path
        d='M6.83411 20.2847C6.96469 19.9365 7.13881 19.6318 7.48705 19.4142C8.09646 18.9789 8.79293 19.0224 9.40234 19.5012C10.4906 20.3718 11.5788 21.3295 12.58 22.3306C13.4941 23.2012 14.4082 24.1153 15.2788 25.0295C15.3223 25.073 15.3659 25.1165 15.4094 25.2471C15.4965 25.0295 15.54 24.8989 15.5835 24.7683C16.3235 22.4177 17.4553 20.2847 18.7612 18.1953C20.0235 16.1495 21.46 14.2342 22.8965 12.3189C23.5929 11.4047 24.2894 10.4471 24.9423 9.53298C25.6388 8.57534 26.7706 8.74945 27.3365 9.35886C27.6847 9.75063 27.7718 10.273 27.5106 10.7083C27.0753 11.4047 26.5529 12.0577 26.0741 12.7106C24.7682 14.4518 23.4623 16.193 22.2435 17.9777C21.0247 19.7189 19.9365 21.5471 19.0659 23.5059C18.3694 25.073 17.847 26.6836 17.5859 28.3377C17.4988 28.9471 17.02 29.3824 16.367 29.3824C15.8882 29.4259 15.4529 29.2518 15.1047 28.9036C13.6682 27.4236 12.1882 25.9436 10.7082 24.4636C9.70705 23.4624 8.66234 22.5483 7.57411 21.6342C7.2694 21.373 7.00822 21.1553 6.87764 20.7636C6.83411 20.633 6.83411 20.4589 6.83411 20.2847Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ProductLogIcon;
