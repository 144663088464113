import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.0547 0C24.4433 0.12956 24.832 0.12956 25.2207 0.215933C30.2303 1.03648 34.5058 5.05284 35.5855 10.0193C36.3197 13.3879 35.8446 16.5405 34.1603 19.5636C33.7285 20.2977 32.9079 20.5137 32.2169 20.125C31.5691 19.7363 31.3532 18.9158 31.7851 18.2248C32.476 17.0155 32.9511 15.7199 33.1238 14.338C33.5557 10.192 32.0874 6.82349 28.6324 4.49141C22.3704 0.25912 13.8194 4.18911 12.8261 11.6604C12.0488 17.361 16.2811 22.673 21.9817 23.1912C23.8387 23.364 25.6094 23.0617 27.3368 22.2843C28.2006 21.8956 29.0643 22.2843 29.2802 23.1912C29.453 23.7958 29.1507 24.4436 28.5892 24.7028C27.2505 25.3506 25.7821 25.7824 24.2706 25.912C21.4635 26.1711 18.7859 25.6097 16.3674 24.1845C16.1515 24.055 16.1083 24.2277 16.0219 24.3141C14.5968 26.0847 13.1716 27.8986 11.7896 29.7124C10.6236 31.2239 9.328 32.5627 7.94603 33.8583C5.65714 36.0609 1.8999 34.938 1.12254 31.8717C1.07935 31.7854 1.12254 31.6558 0.992981 31.5694C0.992981 31.0944 0.992981 30.6625 0.992981 30.1875C1.12254 30.1011 1.07935 29.9715 1.12254 29.842C1.29529 29.1942 1.55441 28.6327 1.98627 28.1577C2.85001 27.2508 3.71374 26.3439 4.66385 25.5233C6.90955 23.5799 9.28482 21.7661 11.6601 19.909C11.876 19.7363 11.876 19.6499 11.7465 19.434C10.1917 16.7132 9.6735 13.8197 10.1917 10.7535C11.0987 5.35514 15.3309 1.12285 20.7725 0.172747C21.0748 0.12956 21.4203 0.172747 21.6794 0C22.4567 0 23.2773 0 24.0547 0ZM5.09571 32.2172C5.48439 32.2172 5.7867 32.0445 6.17538 31.699C9.11207 28.9351 11.401 25.6097 13.9058 22.5002C13.949 22.4571 13.9922 22.3707 13.9922 22.3275C13.8626 22.1979 13.733 22.0252 13.6035 21.8956C13.4739 21.8093 13.3875 21.982 13.3012 22.0684C11.4873 23.5367 9.63031 25.0051 7.81647 26.4302C6.52087 27.4667 5.31165 28.6327 4.14561 29.842C3.71374 30.2738 3.62737 30.7921 3.8433 31.3535C4.01605 31.9149 4.53429 32.2172 5.09571 32.2172Z'
        fill='currentColor'
      />
      <path
        d='M23.5364 5.48468C26.214 5.70061 28.4597 7.12577 29.7985 9.84653C30.1871 10.6239 29.928 11.4013 29.1938 11.7467C28.4597 12.0922 27.7255 11.7899 27.3368 11.0126C26.689 9.67379 25.6957 8.81005 24.3138 8.37819C23.9251 8.24863 23.5364 8.20544 23.1477 8.20544C22.2408 8.16225 21.6362 7.64401 21.6362 6.82347C21.593 5.95974 22.2408 5.4415 23.5364 5.48468Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default SearchIcon;
