import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Provider } from 'react-redux';

import TooltipContainer from './components/TooltipContainer/TooltipContainer';
import { AccordionProvider } from './context/AccordionContext';
import { AuthProvider } from './context/AuthContext';
import { IntlProvider } from './context/IntlContext';
import { LanguageProvider } from './context/LanguageContext';
import { RouterProvider } from './context/RouterContext';
import { client } from './graphql/client';
import { store } from './redux/store';
import theme from './theme/theme';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LanguageProvider>
            <IntlProvider>
              <AuthProvider>
                <AccordionProvider>
                  <RouterProvider />
                  <TooltipContainer />
                </AccordionProvider>
              </AuthProvider>
            </IntlProvider>
          </LanguageProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
