import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

// import { ROUTES } from '../../../config/constants';
import { useAuthContext } from '../../../context/AuthContext';
import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import ArrowLeftStrongIcon from '../../icons/ArrowLeftStrongIcon';
import AccordionItemHeader from './AccordionItemHeader/AccordionItemHeader';
import useStyles from './styles';

const AccardionItem = ({
  activeTab,
  btnTitle,
  count,
  children,
  setActiveTab,
  contentTitle,
  Icon,
}) => {
  const location = useLocation();

  const urlReg = /production-lists/gi;

  const isProductionListPage = urlReg.test(location?.pathname);

  const { isAuth } = useAuthContext();
  const classes = useStyles({ isAuth, isProductionListPage });
  const { formatMessage } = useIntl();

  const handleChange = () => {
    !isProductionListPage &&
      isAuth &&
      setActiveTab(activeTab === btnTitle ? '' : btnTitle);
  };

  const isActiveTab = activeTab === btnTitle;
  return (
    <>
      <Box
        className={classNames(
          classes.accardionBtn,
          isActiveTab ? classes.activeBtn : '',
        )}
        onClick={handleChange}
      >
        <Icon />
        <Box className={classes.titleWrapper}>
          <ArrowLeftStrongIcon width={11} height={12} />
          <Typography
            width={173}
            fontSize={fontSizes.exrta20}
            fontWeight={fontWeights.BOLD}
          >
            {formatMessage({ id: btnTitle })}
          </Typography>
        </Box>
        <Box className={classes.countWrapper}>
          <Typography
            width={130}
            fontSize={fontSizes.exrta20}
            fontWeight={fontWeights.BOLD}
          >
            {count}
          </Typography>
        </Box>
      </Box>

      <Box
        className={classNames(
          classes.contentWrapper,
          isActiveTab ? classes.activeContent : '',
        )}
      >
        <Box className={classes.content}>
          <AccordionItemHeader Icon={Icon} contentTitle={contentTitle} />
          <Box className={classes.pageWrapper}>{children}</Box>
        </Box>
      </Box>
    </>
  );
};

export default AccardionItem;
