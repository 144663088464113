import './calculator.css';

import { Box } from '@material-ui/system';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import ModalLayout from '../../Layout/ModalLayout/ModalLayout';
import { BTN_ACTIONS, calcBtns, numbBtns } from './btnConfig';
import useStyles from './styles';

const CalcModal = ({
  onClose,
  open,
  changeValueName,
  changeValueIndex,
  setFieldValue,
  values,
}) => {
  const [type, setType] = useState('calc');
  const classes = useStyles();

  const handleChangeType = () => {
    if (type === 'calc') {
      setType('number');
    } else {
      setType('calc');
    }
  };

  const expRef = useRef(null);

  let value = '';
  const isIngridients = changeValueName === 'ingridients';

  if (isIngridients) {
    value = values[changeValueName][changeValueIndex]?.count;
  } else if (changeValueName) {
    value = values[changeValueName];
  } else {
    value = '';
  }

  const btnClick = (item) => {
    const expDiv = expRef.current;

    if (item.action == BTN_ACTIONS.REMOVE) {
      let result = value.slice(0, value.length - 1);
      expDiv.innerHTML = result;

      if (isIngridients) {
        let amountResult = [...values[changeValueName]];

        amountResult[changeValueIndex].count = result;
        setFieldValue(changeValueName, amountResult);
      } else {
        setFieldValue(changeValueName, result);
      }
    }

    if (item.action === BTN_ACTIONS.ADD) {
      addAnimSpan(item.display);

      const oper = item.display !== 'x' ? item.display : '*';

      if (isIngridients) {
        let amountResult = [...values[changeValueName]]?.map((item, index) => {
          if (changeValueIndex === index) {
            return { ...item, count: value + oper };
          }
          return { ...item };
        });

        setFieldValue(changeValueName, amountResult);
      } else {
        setFieldValue(changeValueName, value + oper);
      }
    }

    if (item.action === BTN_ACTIONS.DELETE) {
      expDiv.parentNode.querySelector('div:last-child').innerHTML = '';
      expDiv.innerHTML = '';

      if (isIngridients) {
        let amountResult = [...values[changeValueName]];

        amountResult[changeValueIndex].count = '';
        setFieldValue(changeValueName, amountResult);
      } else {
        setFieldValue(changeValueName, '');
      }
    }

    if (item.action === BTN_ACTIONS.CALC) {
      if (value.trim().length <= 0) return;

      expDiv.parentNode.querySelector('div:last-child').remove();

      const cloneNode = expDiv.cloneNode(true);
      expDiv.parentNode.appendChild(cloneNode);

      const transform = `translateY(${
        -(expDiv.offsetHeight + 10) + 'px'
      }) scale(0.4)`;

      try {
        let res = eval(value);

        if (isIngridients) {
          let amountResult = [...values[changeValueName]];

          amountResult[changeValueIndex].count = res.toString();
          setFieldValue(changeValueName, amountResult);
        } else {
          setFieldValue(changeValueName, res.toString());
        }

        setTimeout(() => {
          cloneNode.style.transform = transform;
          expDiv.innerHTML = '';
          addAnimSpan(Math.floor(res * 100000000) / 100000000);
        }, 0);
      } catch {
        setTimeout(() => {
          cloneNode.style.transform = transform;
          cloneNode.innerHTML = 'Syntax err';
        }, 0);
      } finally {
        console.log('calc complete');
      }
    }
  };

  const handleNumChange = (item) => {
    if (item.action === BTN_ACTIONS.ADD) {
      if (isIngridients) {
        let amountResult = [...values[changeValueName]];

        amountResult[changeValueIndex].count = value + item.display;
        setFieldValue(changeValueName, amountResult);
      } else {
        setFieldValue(changeValueName, value + item.display);
      }
    }

    if (item.action === BTN_ACTIONS.DELETE) {
      if (isIngridients) {
        let amountResult = [...values[changeValueName]];

        amountResult[changeValueIndex].count = '';
        setFieldValue(changeValueName, amountResult);
      } else {
        setFieldValue(changeValueName, '');
      }
    }

    if (item.action == BTN_ACTIONS.REMOVE) {
      let result = value.slice(0, value.length - 1);

      if (isIngridients) {
        let amountResult = [...values[changeValueName]];

        amountResult[changeValueIndex].count = result;
        setFieldValue(changeValueName, amountResult);
      } else {
        setFieldValue(changeValueName, result);
      }
    }
  };

  const addAnimSpan = (content) => {
    const expDiv = expRef?.current;
    const span = document.createElement('span');

    span.innerHTML = content;
    span.style.opacity = '0';
    expDiv?.appendChild(span);

    const width = span.offsetWidth + 'px';
    span.style.width = '0';

    setTimeout(() => {
      span.style.opacity = '1';
      span.style.width = width;
    }, 100);
  };

  return (
    <ModalLayout
      onClose={onClose}
      open={open}
      type={type}
      setType={handleChangeType}
    >
      {type === 'calc' ? (
        <Box className={classes.calculatorWrapper}>
          <Box className={classes.calculatorResultWrapper}>
            <Box className={classes.calculatorResult}>
              <Box ref={expRef} className='calculator__result__exp'>
                {value}
              </Box>
              <Box className='calculator__result__exp'></Box>
            </Box>
          </Box>

          <Box className={classes.calculatorBtnsWrapper}>
            {calcBtns.map((item, index) => (
              <Box
                component='button'
                key={index}
                className={classNames(classes.btn, classes[item.class])}
                onClick={() => btnClick(item)}
              >
                {item.display}
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box className={classes.numberWrapper}>
          <Box className={classes.number__btns}>
            {numbBtns.map((item, index) => (
              <Box
                component='button'
                key={index}
                className={classNames(
                  classes.btn,
                  classes.numBtn,
                  classes[item.class],
                )}
                onClick={() => handleNumChange(item)}
              >
                {item.display}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </ModalLayout>
  );
};

export default CalcModal;
