import React from 'react';

const SelectArrowIcon = ({ width = 12, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 1.38267C0 1.23627 0 1.08987 0 0.959736C0.0320412 0.959736 0.0160206 0.927203 0.0320412 0.910936C0.112144 0.585602 0.304391 0.341601 0.592762 0.195201C0.897153 0.0325334 1.23359 0 1.57002 0C4.53383 0 7.48162 0 10.4454 0C10.6857 0 10.91 0.0162667 11.1343 0.0813336C11.695 0.244001 12.0154 0.650669 11.9994 1.23627C11.9994 1.57787 11.8552 1.88694 11.695 2.16347C10.2051 4.79868 8.71521 7.45016 7.22529 10.0854C7.11315 10.2968 6.98498 10.4758 6.80875 10.6384C6.39222 11.0614 5.86354 11.1102 5.38292 10.8011C5.11057 10.6059 4.91832 10.3294 4.75812 10.0528C3.60463 8.00322 2.43513 5.96989 1.29767 3.92028C0.849092 3.09068 0.27235 2.32614 0 1.38267Z'
        fill='black'
      />
    </svg>
  );
};

export default SelectArrowIcon;
