import { makeStyles } from '@material-ui/styles';

const useStyles = () =>
  makeStyles(() => ({
    // wrapper: {
    //   width: 521,
    //   position: 'absolute',
    //   top: 200,
    // },
    contentWrapper: {
      marginTop: 11,
    },
    contentRow: {
      display: 'flex',
      alignItems: 'center',
    },
    contentRowTitle: {
      width: 113,
    },
    contentBottomRow: {
      marginTop: 11,
    },
    result: {
      marginLeft: 10,
    },
  }))();

export default useStyles;
