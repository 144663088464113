import { createSlice } from '@reduxjs/toolkit';

// import {
//   TIME_SPAN_FINISH_OPTIONS,
//   TIME_SPAN_START_OPTIONS,
// } from '../../config/constants';

const initialState = {
  productionLists: [],
  productionList: null,
  activeRow: null,
  activeRecipes: null,
  sortType: 'ASC',
  sortBy: 'name',
  timeSpanStart: 'all',
  timeSpanFinish: 'all',
  queryCount: 0,
};

export const recipresSlice = createSlice({
  name: 'productionLists',
  initialState,
  reducers: {
    setProductionLists: (state, action) => {
      state.productionLists = action.payload.productionLists;
    },
    setProductionList: (state, action) => {
      state.productionList = action.payload.productionList;
    },
    setSort: (state, action) => {
      state.sortBy = action.payload.sortBy;
      state.sortType = action.payload.sortType;
    },
    setTimeSpanStart: (state, action) => {
      state.timeSpanStart = action.payload.timeSpanStart;
      state.activeRow = [];
    },
    setTimeSpanFinish: (state, action) => {
      state.timeSpanFinish = action.payload.timeSpanFinish;
      state.activeRow = [];
    },
    setActiveRow: (state, action) => {
      state.activeRow = action.payload.activeRow;
    },
    setActiverecipes: (state, action) => {
      state.activeRecipes = action.payload.activeRecipes;
    },
    setQueryCount: (state) => {
      state.queryCount += 1;
      state.activeRow = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProductionLists,
  setProductionList,
  setSort,
  setTimeSpanStart,
  setTimeSpanFinish,
  setActiveRow,
  setActiverecipes,
  setQueryCount,
} = recipresSlice.actions;

export default recipresSlice.reducer;
