import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import pallete from '../../theme/pallete';

const useStyles = ({ recipe }) => {
  return makeStyles(() => ({
    wrapper: {
      marginBottom: 13,
    },
    btnRowWrapper: {
      width: 454,
      display: 'flex',
      alignItems: 'center',
      padding: '7px 12px',
      borderRadius: 7,
      border: `1px solid ${recipe?.group?.outlineColor || '#FEA57D'}`,
      backgroundColor: recipe?.group?.categoryColor || '#FFE2D6',
      marginRight: 57,
      transition: 'all 0.5s',
      marginLeft: 6,
    },
    activeBtnRowWrapper: {
      boxShadow: `0px 0px 0px 5px ${recipe?.group?.outlineColor || '#FEA57D'}`,
      marginBottom: 6,
      marginTop: 6,
    },
    titleWrapper: {
      marginRight: 'auto',
    },
    favoriteWrapper: {
      marginRight: 18,
      marginTop: 3,
      cursor: 'pointer',
    },
    arrowWrapper: {
      transform: 'rotate(0deg)',
      transition: 'all 0.5s',
      cursor: 'pointer',
    },
    activeArrowWrapper: {
      transform: 'rotate(180deg)',
    },
    contentWrapper: {
      width: 454,
      transition: 'all 0.5s',
      height: 0,
      overflow: 'hidden',
      backgroundColor: pallete.textSecondary,
      marginLeft: 5,
    },
    activeContentWrapper: {
      height: 'auto',
    },
    content: {
      padding: 10,
    },
    contentRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    contentRowTitle: {
      fontSize: fontSizes.md,
      width: 85,
    },
    contetnRowTitleAuto: {
      width: 'auto',
    },
    contentRowValue: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.BOLD,
    },
    contentRowWeightValue: {
      marginLeft: 15,
    },
    contentRowLine: {
      flexGrow: 1,
      backgroundColor: '#A9A9A9',
      marginLeft: 10,
      marginRight: 10,
      height: 1,
    },
    contentIngridientsValue: {
      marginLeft: 17,
      fontSize: fontSizes.md,
      fontWeight: fontWeights.BOLD,
      marginBottom: 5,
    },
  }))();
};

export default useStyles;
