export const getCategoryOptions = (categoryData) => {
  let result = [{ label: 'all', value: 'all' }];

  if (categoryData && categoryData.length) {
    categoryData?.forEach((item) => {
      result.push({
        label: item?.name,
        value: item?.id,
      });
    });
  }

  return result;
};
