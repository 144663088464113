import fontWeights from '../../theme/fontWeights';
import pallete from '../../theme/pallete';

export const HighlightingText = ({
  text,
  regex,
  color = pallete.textPrimary,
}) => {
  const test = text.match(new RegExp(regex, 'i'));

  if (test && typeof test.index !== 'undefined' && regex) {
    return (
      <>
        {text.slice(0, test.index)}
        <span style={{ color, fontWeight: fontWeights.BOLD }}>
          {text.slice(test.index, test.index + regex.length)}
        </span>
        {text.slice(test.index + regex.length)}
      </>
    );
  }
  return <>{text}</>;
};
