import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';
import pallete from '../../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    wrapper: {
      padding: '15px 20px',
    },
    contentWrapper: {
      height: 'calc(100vh - 198px)',
      overflowY: 'auto',
    },
    content: {
      width: 585,
      height: 'auto',
      margin: 'auto',
    },
    spaceInfoWrapper: {
      marginTop: 48,
    },
    spaceInfoRow: {
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center',
      '& img': {
        height: 58,
        width: 58,
        borderRadius: '50%',
        marginRight: 28,
        border: '1px solid #fff',
      },
    },
    actionBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        textTransform: 'none',
        color: pallete.textPrimary,
        fontSize: fontSizes.md,
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  }))();

export default useStyles;
