import React, { createContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../config/constants';
import routes from '../config/routes';
import { getActiveSpaceToLogin, useAuthContext } from './AuthContext';

const RouterContext = createContext();

export const RouterProvider = () => {
  const { isAuth, hasToken } = useAuthContext();
  const activeSpaceToLogin = getActiveSpaceToLogin();
  return (
    <RouterContext.Provider value={{}}>
      <BrowserRouter>
        <Switch>
          {routes.map((route) => {
            const { path, component: Component, isPrivate = false } = route;
            return (
              <Route
                key={path}
                path={path}
                exact={route?.exact}
                render={(props) => {
                  if (!isAuth && hasToken) {
                    return <Redirect to={ROUTES.home} />;
                  }
                  if (!isPrivate) {
                    return isAuth ? (
                      <Redirect to={ROUTES.home} />
                    ) : (
                      <Component {...props} />
                    );
                  } else if (!isAuth && activeSpaceToLogin) {
                    return <Redirect to={ROUTES.userLogin} />;
                  } else if (!isAuth)
                    return <Redirect to={ROUTES.loginToSpace} />;
                  return <Component {...props} />;
                }}
              />
            );
          })}
          <Route render={() => <strong>404</strong>} />
        </Switch>
      </BrowserRouter>
    </RouterContext.Provider>
  );
};
