import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { ROUTES } from '../config/constants';
import {
  CREATE_PRODUCTION_LIST_MUTATION,
  IMPORT_PRODUCTION_LIST_MUTATION,
} from '../graphql/mutations';
import { setQueryCount } from '../redux/reducers/productionLists';

export const useCreateProductionListMutation = (isEdit) => {
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  return useMutation(CREATE_PRODUCTION_LIST_MUTATION, {
    onCompleted: (response) => {
      if (response) {
        if (response?.editProductionList?.status === 'Success') {
          toast(
            formatMessage({
              id: !isEdit
                ? 'success_added_production_list'
                : 'success_edit_production_list',
            }),
            {
              type: 'success',
            },
          );
        } else {
          toast(formatMessage({ id: 'failed_added_production_list' }), {
            type: 'error',
          });
        }

        push(ROUTES.productionLists);
      }
    },
  });
};

export const useImportProductionListMutation = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  return useMutation(IMPORT_PRODUCTION_LIST_MUTATION, {
    onCompleted: (response) => {
      if (response) {
        dispatch(setQueryCount());
        toast(formatMessage({ id: 'success_imported_production_list' }), {
          type: 'success',
        });
      }
    },
    onError: (error) => {
      toast(
        formatMessage({
          id:
            error.message === 'Error: Incorrect type!'
              ? 'import_incorrect_type'
              : error.message === 'Error: Incorrect type!'
              ? 'import_recipe_not_found'
              : 'import_error',
        }),
        {
          type: 'error',
        },
      );
    },
  });
};
