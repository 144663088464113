import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { GET_RECIPE_BY_ID_QUERY } from '../../../graphql/queries';
//import { useGetRecipeById } from '../../../hooks/useQueries';
import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import CustomSelect from '../../CustomSelect/CustomSelect';
import CompleteIcon from '../../icons/CompleteIcon';
import useStyles from './styles';

const getAllRecipeOptions = (recipesList) => {
  return recipesList?.map((item) => {
    return { label: item?.name, value: item?.id };
  });
};

const isValidRecipe = (recipes, currentRecipeId) => {
  const recipesIds = recipes?.map((item) => item?.id);
  return recipesIds?.includes(currentRecipeId);
};

const getRecipeInfoByForm = (recipe) => {
  let ingridients = [];
  recipe?.items?.map((item) => {
    if (item?.ingredient) {
      ingridients.push({
        name: item?.ingredient?.name,
        weight: item?.ingredient?.weightPerUnit,
        measure: item?.ingredient?.unit?.fullName,
        measureId: item?.ingredient?.unit?.id,
        count: item?.countIngredient,
        recipeItemId: recipe?.id,
      });
    } else if (item?.recipeIngredient) {
      ingridients.push({
        name: item?.recipeIngredient?.name,
        weight: item?.recipeIngredient?.weight,
        measure: item?.recipeIngredient?.unit?.fullName,
        measureId: item?.recipeIngredient?.unit?.id,
        count: item?.countIngredient,
        recipeItemId: recipe?.id,
      });
    }
  });

  return {
    id: recipe?.id,
    ingridients,
    order: 0,
    name: recipe?.name,
    baseWight: recipe?.weight,
    xValue: '1',
    variant: 'ingridients',
    completeSum: recipe?.weight,
  };
};

const AddRecipe = ({ recipesList, setIsAddRecipe, values, setFieldValue }) => {
  const [recipeByIdQuery, { loading }] = useLazyQuery(GET_RECIPE_BY_ID_QUERY, {
    onCompleted: (response) => {
      if (response?.getRecipeById) {
        let recipe = getRecipeInfoByForm(response?.getRecipeById);
        const newRecipesValue = [...values?.recipes];
        newRecipesValue.push(recipe);
        setFieldValue('recipes', newRecipesValue);
        setIsAddRecipe(false);
      }
    },
  });

  const selectList = getAllRecipeOptions(recipesList);
  const databaseId = JSON.parse(localStorage.getItem('activeSpaceToLogin'))?.id;
  const [selectValue, setSelectValue] = useState(selectList[0].value || '');
  const isValidRecipeValue = isValidRecipe(values.recipes, selectValue);
  const classes = useStyles({ isValidRecipeValue, loading });
  const { formatMessage } = useIntl();

  const handleAddRecipe = () => {
    recipeByIdQuery({
      variables: {
        databaseId: +databaseId,
        recipeId: +selectValue,
      },
    });
  };

  return (
    <Box className={classes.wrapper}>
      <Typography
        marginBottom={'32px'}
        fontSize={fontSizes.md}
        fontWeight={fontWeights.BOLD}
      >
        {formatMessage({ id: 'add_precipe_modal_title' })}
      </Typography>
      <CustomSelect
        value={selectValue}
        onChange={(event) => {
          setSelectValue(event.target.value);
        }}
        itemsList={selectList}
        height={37}
        width={'100%'}
        ml={0}
        papperML={0}
        paperWidth={'100%'}
      />
      <Typography
        marginTop={'20px'}
        marginBottom={'40px'}
        fontSize={fontSizes.sm}
        fontWeight={fontWeights.BOLD}
        color={'#CA0C00'}
        style={{ opacity: !isValidRecipeValue ? 0 : 1 }}
      >
        {formatMessage({ id: 'entry_exists_title' })}
      </Typography>
      <Box className={classes.btnsWrapper}>
        <Box
          className={classes.cancelBtn}
          onClick={() => setIsAddRecipe(false)}
        >
          {formatMessage({ id: 'cancel_title' })}
        </Box>
        <Box
          className={classes.okBtn}
          onClick={!isValidRecipeValue && !loading && handleAddRecipe}
        >
          <Box> {formatMessage({ id: 'ok_title' })}</Box>
          <CompleteIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default AddRecipe;
