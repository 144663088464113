import React from 'react';

import pallete from '../../theme/pallete';

const CloseIcon = ({ color = pallete.textPrimary }) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.55859 7.30859L0.339844 0.898438H4.39453L6.9375 5.07031L9.50391 0.898438H13.5586L9.29297 7.30859L13.7578 14H9.69141L6.9375 9.51172L4.17188 14H0.117188L4.55859 7.30859Z'
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
