import React from 'react';

const CompleteIcon = ({ width = 23, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.1438 31.9649C11.2908 31.9649 10.5541 31.542 9.93374 30.6961C7.14205 26.7359 4.2728 22.7756 1.4811 18.777C0.240348 17.0083 0.240348 14.2784 1.4811 12.5482C2.72186 10.818 4.62176 10.7796 5.90129 12.5867C7.83997 15.2397 9.73987 17.9311 11.6398 20.6225C12.1826 21.3915 12.1826 21.3915 12.7642 20.584C17.3007 14.2015 21.8372 7.85747 26.3737 1.47496C27.8084 -0.56283 30.0185 -0.409034 31.1817 1.7441C32.0735 3.32051 32.0347 5.55054 31.1429 7.12694C30.9878 7.35764 30.8327 7.58833 30.6776 7.81902C25.3269 15.355 19.9761 22.891 14.6253 30.3885C13.9274 31.3497 13.152 32.0034 12.1438 31.9649Z'
        fill='#050606'
      />
    </svg>
  );
};

export default CompleteIcon;
