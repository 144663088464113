import { makeStyles } from '@material-ui/styles';

const useStyles = () =>
  makeStyles(() => ({
    wrapper: {
      paddingBottom: 13,
      padding: '6px 6px 13px 6px',
      borderBottom: '1px solid #A9A9A9',
      display: 'flex',
      alignItems: 'center',
    },
    iconWrapper: {
      paddingRight: 20,
      paddingTop: 5,
      borderRight: '3px solid #000',
    },
    titleWrapper: {
      paddingLeft: 20,
    },
  }))();

export default useStyles;
