import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import pallete from '../../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    wrapper: {
      width: 351,
      backgroundColor: '#fff',
      position: 'absolute',
      bottom: 65,
      borderRadius: 7,
      padding: 6,
      border: '1px solid #C4C4C4',
    },
    printElem: {
      width: '100%',
      marginBottom: 6,
      borderRadius: 7,
      fontSize: fontSizes.ml,
      fontWeight: fontWeights.BOLD,
      textAlign: 'center',
      border: '1px solid #000',
      backgroundColor: pallete.buttonBg,
      cursor: 'pointer',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }))();

export default useStyles;
