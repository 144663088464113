import { useLazyQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ActionBtn from '../../components/ContentAccordion/ActionBtn/ActionBtn';
import { HighlightingText } from '../../components/HighlightingText/HighlightingText';
import FavoriteIcon from '../../components/icons/FavoriteIcon';
import inProgressIcon from '../../components/icons/inProgressIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import SortIcon from '../../components/icons/SortIcon';
import ToDoIcon from '../../components/icons/ToDoIcon';
import SearchModal from '../../components/Modals/SearchModal/SearchModal';
import RecipesListItem from '../../components/RecipesListItem/RecipesListItem';
import SpinnerLogo from '../../components/Spinner/SpinnerLogo';
import { useAccordionContext } from '../../context/AccordionContext';
import { useAuthContext } from '../../context/AuthContext';
import {
  ADD_RECIPE_TO_FAVORITE_QUERY,
  DELETE_RECIPE_TO_FAVORITE_QUERY,
} from '../../graphql/queries';
import {
  useAddInProgressRecipeToProductionList,
  useAddToDoRecipeToProductionList,
  // useAddRecipeToFavorite,
  // useDeleteRecipeToFavorite,
  useCategories,
  useGetRecipes,
} from '../../hooks/useQueries';
import {
  setCategory,
  setIsFavorite,
  setSearch,
  setSortType,
} from '../../redux/reducers/recipes';
import { recipeSelector, toDoSelector } from '../../redux/selectors';
import { getCategoryOptions } from '../../utils/getCategoryOptions';
import useStyles from './styles';

const AllRecipes = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('');
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const { recipes, activeRecipe, search, sortType, isFavorite, category } =
    useSelector(recipeSelector);

  const { toDo } = useSelector(toDoSelector);

  const isValidRecipeByTransfer = (id, toDo) => {
    return toDo?.map((item) => +item?.id)?.includes(+id);
  };

  const isValidRecipe = isValidRecipeByTransfer(activeRecipe?.id || 0, toDo);

  const { isAuth } = useAuthContext();

  const classes = useStyles();

  const [query, { loading }] = useGetRecipes();
  const [addToFavoriteQuery, { loading: addFavLoad }] = useLazyQuery(
    ADD_RECIPE_TO_FAVORITE_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        if (response?.addToFavorite?.status) {
          getRecipres();
        }
      },
    },
  );
  const [deleteToFavoriteQuery, { loading: deleteFavLoad }] = useLazyQuery(
    DELETE_RECIPE_TO_FAVORITE_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        if (response?.deleteFavorite?.status) {
          getRecipres();
        }
      },
    },
  );

  const [addToDoRecipeToProdListQuery] = useAddToDoRecipeToProductionList();
  const [addinProgressRecipeToProdListQuery] =
    useAddInProgressRecipeToProductionList();

  const [categoriesQuery, { data: categoryData }] = useCategories();

  const { activeDayId } = useAccordionContext();
  const categoryOptions = getCategoryOptions(
    categoryData?.getRecipesGroupByDatabaseId,
  );
  const categoryRecipeProps =
    category !== 'all' ? { categoryId: +category } : {};
  const databaseId = JSON.parse(localStorage.getItem('activeSpaceToLogin'))?.id;

  const setSearchValue = (value) => {
    dispatch(setSearch({ search: value }));
  };

  const handleSort = () => {
    let sort;
    if (sortType === 'DESC') {
      sort = 'ASC';
    } else {
      sort = 'DESC';
    }

    dispatch(
      setSortType({
        sortType: sort,
      }),
    );
  };

  const handleFavorite = () => {
    dispatch(
      setIsFavorite({
        isFavorite: !isFavorite,
      }),
    );
  };

  const getRecipres = (
    searchProps = search,
    sortTypeProps = sortType,
    isFavoriteProps = isFavorite,
  ) => {
    query({
      variables: {
        input: {
          limit: 1000,
          page: 1,
          sortBy: 'name',
          sortType: sortTypeProps,
          isFavorite: isFavoriteProps,
          databaseId: +databaseId,
          search: searchProps,
          searchFields: 'name',
          ...categoryRecipeProps,
        },
      },
    });
  };

  const addRecipeToProdListAction = (query, state) => {
    activeRecipe &&
      query({
        variables: {
          productionListId: +activeDayId,
          input: {
            state: state,
            typeMeasurements: 'amount of products from this recipe',
            recipeId: +activeRecipe?.id,
          },
        },
      });
    setActiveTab('');
  };

  const handleChangeIsFavoriteRecipe = (id, isFavorite) => {
    const options = {
      variables: {
        recipeId: +id,
      },
    };

    !isFavorite ? addToFavoriteQuery(options) : deleteToFavoriteQuery(options);
  };

  useEffect(() => {
    isAuth &&
      categoriesQuery({
        variables: {
          databaseId: +databaseId,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isAuth && getRecipres();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, isFavorite]);

  if (loading || addFavLoad || deleteFavLoad) {
    return <SpinnerLogo fullPage={true} />;
  }

  return (
    <>
      <Box className={classes.content}>
        {recipes?.map((recipe, index) => {
          return (
            <RecipesListItem
              activeTab={activeTab}
              recipe={recipe}
              key={index}
              setActiveTab={setActiveTab}
              HighlightingText={HighlightingText}
              handleFavorite={handleChangeIsFavoriteRecipe}
              regex={search}
              favoriteLoading={addFavLoad || deleteFavLoad}
              type='all'
            />
          );
        })}
      </Box>
      <Box style={{ display: 'flex' }}>
        <SearchModal
          onClose={() => setIsOpenSearch(false)}
          open={isOpenSearch}
          searchValue={search}
          categoryList={categoryOptions}
          setSearchValue={(value) => setSearchValue(value)}
          handleSearch={(search) => getRecipres(search)}
          handleCategory={(event) =>
            dispatch(setCategory({ category: event.target.value }))
          }
          category={category}
          recipes={recipes}
        />

        <ActionBtn
          Icon={SearchIcon}
          action={() => setIsOpenSearch(!isOpenSearch)}
          marginRight={14}
        />
        <ActionBtn
          Icon={SortIcon}
          marginRight={19}
          isActive={sortType === 'ASC'}
          action={handleSort}
        />
        <ActionBtn
          Icon={FavoriteIcon}
          marginRight={'auto'}
          action={handleFavorite}
          isActive={isFavorite}
        />
        <ActionBtn
          Icon={ToDoIcon}
          marginRight={19}
          isDisable={!activeRecipe || !activeDayId || isValidRecipe}
          action={() =>
            addRecipeToProdListAction(addToDoRecipeToProdListQuery, 'todo')
          }
        />
        <ActionBtn
          Icon={inProgressIcon}
          isDisable={!activeRecipe || !activeDayId || isValidRecipe}
          action={() =>
            addRecipeToProdListAction(
              addinProgressRecipeToProdListQuery,
              'inProgress',
            )
          }
        />
      </Box>
    </>
  );
};

export default AllRecipes;
