import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import AuthLayout from '../../../components/Layout/AuthLayout/AuthLayout';
import MainLayout from '../../../components/Layout/MainLayout/MainLayout';
import { ROUTES } from '../../../config/constants';
import {
  removeActiveSpaceToLogin,
  useAuthContext,
} from '../../../context/AuthContext';
import fontSizes from '../../../theme/fontSizes';
import useStyles from './styles';

const UserLogin = () => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { removeAllDBsForUser } = useAuthContext();

  const handleChangePage = () => {
    removeActiveSpaceToLogin();
    removeAllDBsForUser();
    push(ROUTES.loginToSpace);
  };

  const classes = useStyles();
  return (
    <MainLayout>
      <AuthLayout
        title='user_login_title'
        botomBtnTitle={'select_db'}
        bottomBtnAction={handleChangePage}
      >
        <Box className={classes.loginBtnsWrapper}>
          <Box
            className={classes.loginBtn}
            onClick={() => {
              push(ROUTES.userLoginPin);
            }}
          >
            <Typography fontSize={fontSizes.xl}>
              {formatMessage({ id: 'pinLogin' })}
            </Typography>
          </Box>
          <Box
            className={classes.loginBtn}
            onClick={() => {
              push(ROUTES.loginChoiceAccount);
            }}
          >
            <Typography fontSize={fontSizes.xl}>
              {formatMessage({ id: 'userLogin' })}
            </Typography>
          </Box>
        </Box>
      </AuthLayout>
    </MainLayout>
  );
};

export default UserLogin;
