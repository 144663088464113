import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../../../theme/fontSizes';
import pallete from '../../../../../theme/pallete';

const getWidth = (title) => {
  switch (title) {
    case 'name':
      return 300;
    case 'date':
      return 108;
    case 'positions':
      return 85;
    case 'done':
      return 78;
    default:
      return 108;
  }
};

const useStyles = ({ title, mr }) =>
  makeStyles(() => ({
    tableListHeaderItem: {
      background: pallete.buttonBg,
      display: 'flex',
      padding: '5px 9px',
      fontSize: fontSizes.sm,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: pallete.textPrimary,
      width: getWidth(title),
      marginRight: mr,
    },
    cutText: {
      margin: 0,

      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tableSortLabel: {
      marginLeft: 10,
    },
  }))();

export default useStyles;
