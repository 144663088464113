import { Box } from '@material-ui/core';
import React from 'react';

import ContentAccordion from '../../ContentAccordion/ContentAccordion';
import NavBar from '../../NavBar/NavBar';
import useStyles from './styles';

const MainLayout = ({
  children,
  isHasAccardeon = true,
  isHasContent = true,
}) => {
  const classes = useStyles({ isHasContent, isHasAccardeon });

  return (
    <Box className={classes.screenContainer}>
      <NavBar />
      <Box className={classes.contentWrapper}>
        {isHasContent ? (
          <Box className={classes.pagesWrapper}>{children}</Box>
        ) : null}

        {isHasAccardeon ? (
          <Box className={classes.accardeonWrapper}>
            <ContentAccordion />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default MainLayout;
