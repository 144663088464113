import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import pallete from '../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    btnsWrapper: {
      display: 'flex',
      width: '100%',
      padding: 6,
      border: '1px solid #5E5E5E',
      marginBottom: 27,
    },
    titleRow: {
      display: 'flex',
      marginBottom: 40,
      alignItems: 'center',
    },
    listName: {
      marginLeft: 26,
      fontSize: fontSizes.md,
    },
    title: {
      height: '30px!important',
      '& input': {
        width: '296px!important',
        borderRadius: 'none!important',
        height: '30px!important',
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    date: {
      marginRight: 13,
      fontSize: fontSizes.md,
    },
    dateInput: {
      '& input': {
        width: 169,
        height: 30,
        padding: 0,
        paddingLeft: 14,
      },
    },
    tableWrapper: {
      width: 528,
      margin: 'auto',
      paddingRight: 36,
      overflowY: 'auto',
      height: 'calc(100vh - 365px)',
      padding: 15,
    },
    tableListItemWrapper: {
      padding: 15,
      backgroundColor: pallete.textSecondary,
      position: 'relative',
      width: '100%',
    },
    tableRow: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    tableRowCheckboxWrapper: {
      marginRight: 15,
    },
    formControlLabel: {
      height: 16,
      width: 16,
    },
    tableRowTitleWrapper: {
      display: 'flex',
      width: 'calc(100% - 31px)',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'move',
      borderBottom: '1px solid #000',
    },
    rowTitle: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.BOLD,
      width: 240,
    },
    totalWeight: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.BOLD,
    },
    listsWrapper: {
      paddingLeft: 70,
      paddingRight: 70,
    },
    listsWrapperItem: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 5,
      paddingBottom: 5,
    },
    listsWrapperItemText: {
      fontSize: fontSizes.sm,
    },
  }))();

export default useStyles;
