import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    loginBtnsWrapper: {
      marginTop: 50,
      marginLeft: 10,
    },
    loginBtn: {
      backgroundColor: pallete.secondary1,
      width: 565,
      height: 100,
      marginBottom: 25,
      border: '1px solid #000000',
      borderRadius: 7,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: ' all .5s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: pallete.buttonBg,
      },
    },
  }))();

export default useStyles;
