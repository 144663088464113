import React, { createContext, useContext, useEffect, useReducer } from 'react';

import LOCALES from '../i18n/locales';

export function useLanguageContext() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error(
      'useLanguageContext must be used within a LanguageProvider',
    );
  }
  return context;
}

const language = JSON.parse(localStorage.getItem('language')) || {
  value: LOCALES.ENGLISH,
};

const initialState = {
  currentLanguage: language,
  loading: false,
};

const LanguageContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      localStorage.setItem('language', JSON.stringify(action.payload));
      return {
        ...state,
        currentLanguage: action.payload,
      };

    default:
      throw new Error();
  }
};

const LanguageProvider = ({ children }) => {
  const [languageInfo, dispatch] = useReducer(reducer, initialState);

  const handleChangeLanguage = (language) => {
    dispatch({ type: 'SET_LANGUAGE', payload: language });
  };

  useEffect(() => {
    localStorage.setItem(
      'language',
      JSON.stringify(languageInfo.currentLanguage),
    );
  }, [languageInfo.currentLanguage]);

  const value = {
    currentLanguage: languageInfo.currentLanguage,
    handleChangeLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider };
