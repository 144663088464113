import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../theme/fontSizes';
import pallete from '../../theme/pallete';

const useStyles = ({ searchValue }) =>
  makeStyles(() => ({
    searchInputContainer: {
      position: 'relative',
      '& > .Mui-focused': {
        border: `1px solid #000`,
      },
    },
    searchInput: {
      border: '1px solid #000',
      padding: '10px 43px 10px 13px',
      width: 269,
      height: 37,
      borderRadius: '7px',
      background: pallete.secondary1,
      fontSize: fontSizes.md,
    },
    searchBtn: {
      position: 'absolute!important',
      top: '-2px',
      right: searchValue.length < 1 ? '5px' : '30px',
      padding: 0,
    },
    clearSearch: {
      position: 'absolute!important',
      right: 4,
      top: 5,
      cursor: 'pointer',
      opacity: 0.6,
    },
  }))();

export default useStyles;
