import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import ActionBtn from '../../components/ContentAccordion/ActionBtn/ActionBtn';
import { HighlightingText } from '../../components/HighlightingText/HighlightingText';
import DeleteIcon from '../../components/icons/DeleteIcon';
import FolderIcon from '../../components/icons/FolderIcon';
import GearSettingsIcon from '../../components/icons/GearSettingsIcon';
import inProgressIcon from '../../components/icons/inProgressIcon';
import PrintIcon from '../../components/icons/PrintIcon';
// import SaveIcon from '../../components/icons/SaveIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import PrintModal from '../../components/Modals/PrintModal/PrintModal';
import SearchModal from '../../components/Modals/SearchModal/SearchModal';
import PrintRecipe from '../../components/PrintRecipe/PrintRecipe';
import RecipesListItem from '../../components/RecipesListItem/RecipesListItem';
import SpinnerLogo from '../../components/Spinner/SpinnerLogo';
import { ROUTES } from '../../config/constants';
import { useAccordionContext } from '../../context/AccordionContext';
import { useAuthContext } from '../../context/AuthContext';
import { EDIT_RECIPE_MUTATION } from '../../graphql/mutations';
import {
  useCategories,
  useDeleteRecipe,
  useGetSearchRecipes,
  useTransferTab,
} from '../../hooks/useQueries';
import {
  setActiveToDo,
  setCategory,
  setQueryCount,
  setSearch,
  setToDo,
} from '../../redux/reducers/toDo';
import { productionListsSelector, toDoSelector } from '../../redux/selectors';
import { getCategoryOptions } from '../../utils/getCategoryOptions';
import { validationToDoRecipe } from '../../utils/validationRecipevalues';
import EditRecipe from '../EditRecipe/EditRecipe';
import useStyles from './styles';

const ToDo = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const [activeTab, setActiveTab] = useState('');

  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [printModal, setPrintModal] = useState(false);

  const { toDo, activeToDo, search, category, queryCount } =
    useSelector(toDoSelector);

  const { productionLists } = useSelector(productionListsSelector);

  const { isAuth } = useAuthContext();

  const [categoriesQuery, { data: categoryData }] = useCategories();

  const [transferTabQuery] = useTransferTab('todo', 'inProgress');

  const [deleteRecipeQuery] = useDeleteRecipe('todo');

  const { activeDayId, setActiveTab: setActiveAccordionTab } =
    useAccordionContext();
  const categoryOptions = getCategoryOptions(
    categoryData?.getRecipesGroupByDatabaseId,
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const classes = useStyles({ isEdit });

  const [query, { loading }] = useGetSearchRecipes('todo');

  const [editRecipeMutation, { loading: editLoading }] = useMutation(
    EDIT_RECIPE_MUTATION,
    {
      onCompleted: (responce) => {
        setIsEdit(false);
        if (responce?.editAmount?.status == 'Success') {
          toast(formatMessage({ id: 'success_edit_recipe' }), {
            type: 'success',
          });
        } else {
          toast(formatMessage({ id: 'failed_edit_recipe' }), {
            type: 'error',
          });
        }
        setIsEdit(false);
        dispatch(setQueryCount());
        setActiveTab('');
      },
    },
  );

  const categoryRecipeProps =
    category !== 'all' ? { categoryId: +category } : {};
  const databaseId = JSON.parse(localStorage.getItem('activeSpaceToLogin'))?.id;

  const setSearchValue = (value) => {
    dispatch(setSearch({ search: value }));
  };

  const getToDoRecipres = (searchProps = search) => {
    query({
      variables: {
        search: searchProps,
        state: 'todo',
        productionListId: +activeDayId,
        ...categoryRecipeProps,
      },
    });
  };

  const handleDeleteRecipe = () => {
    setActiveTab('');
    setPrintModal(false);
    deleteRecipeQuery({
      variables: {
        productionListId: +activeDayId,
        productionListRecipeId: +activeToDo?.transferId,
      },
    });
  };

  const handleTranferTab = () => {
    transferTabQuery({
      variables: {
        productionListId: +activeDayId,
        productionListRecipeId: +activeToDo?.transferId,
        recipeState: 'inProgress',
      },
    });
  };

  useEffect(() => {
    isAuth &&
      categoriesQuery({
        variables: {
          databaseId: +databaseId,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    activeDayId && getToDoRecipres();
    dispatch(setActiveToDo({ activeToDo: null }));
    setActiveTab('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDayId, queryCount]);

  useEffect(() => {
    if (productionLists?.length === 0) {
      dispatch(setToDo({ toDo: [] }));
      dispatch(setActiveToDo({ activeToDo: null }));
      setActiveTab('');
    }
  }, [productionLists]);

  const getMeasureTypeType = (measure) => {
    switch (measure) {
      case 'amount products':
        return 'amount of products from this recipe';
      case 'complete sum':
        return 'complete sum';
      case 'x-time the base recipe':
        return 'x-time the base recipe';
    }
  };

  const editRecipeAction = (formValues) => {
    const getVariantInfo = (variant, ingridients) => {
      switch (variant) {
        case 'amount products':
          return {
            arrayAmounts: ingridients?.map((item) => {
              return {
                id: +item?.id,
                recipeItemId: +item?.recipeItemId,
                amount: +item?.count,
                name: item?.name,
                weight: item?.weight,
              };
            }),
          };
        case 'complete sum':
          return {
            weight: +formValues?.completeSum,
          };
        case 'x-time the base recipe':
          return {
            count: +formValues?.xValue,
          };
      }
    };

    const variantInfo = getVariantInfo(
      formValues?.selectValue,
      formValues?.ingridients,
    );

    if (validationToDoRecipe(variantInfo, formValues?.selectValue)) {
      editRecipeMutation({
        variables: {
          productionListRecipeId: +activeToDo?.transferId,
          typeMeasurements: getMeasureTypeType(formValues?.selectValue),
          ...variantInfo,
        },
      });
    } else {
      toast(formatMessage({ id: 'failed_edit_recipe' }), {
        type: 'error',
      });
    }
  };

  const printOptions = [
    {
      title: 'print_recipe_title',
      action: handlePrint,
    },
  ];

  if (loading) {
    return <SpinnerLogo fullPage={true} />;
  }

  return (
    <>
      {!isEdit ? (
        <>
          <Box className={classes.content}>
            {toDo?.map((recipe, index) => {
              return (
                <RecipesListItem
                  activeTab={activeTab}
                  key={index}
                  recipe={recipe}
                  setActiveTab={setActiveTab}
                  setPrintModal={setPrintModal}
                  HighlightingText={HighlightingText}
                  regex={search}
                  type='todo'
                />
              );
            })}
          </Box>

          <Box
            style={{ display: 'flex', alignItems: 'flex-end' }}
            className={classes.btnsWrapper}
          >
            <SearchModal
              onClose={() => setIsOpenSearch(false)}
              recipes={toDo}
              open={isOpenSearch}
              searchValue={search}
              categoryList={categoryOptions}
              setSearchValue={(value) => setSearchValue(value)}
              handleSearch={(search) => getToDoRecipres(search)}
              handleCategory={(event) =>
                dispatch(setCategory({ category: event.target.value }))
              }
              category={category}
            />

            <ActionBtn
              Icon={SearchIcon}
              action={() => setIsOpenSearch(!isOpenSearch)}
              marginRight={48}
            />
            <ActionBtn
              Icon={GearSettingsIcon}
              action={() => activeToDo && setIsEdit(true)}
              isDisable={!activeToDo}
              marginRight={15}
            />
            <ActionBtn
              Icon={FolderIcon}
              marginRight={15}
              action={() => {
                setActiveAccordionTab('');
                push(ROUTES.productionLists);
              }}
            />
            {/* <ActionBtn Icon={SaveIcon} marginRight={15} /> */}

            <ActionBtn
              Icon={DeleteIcon}
              marginRight={15}
              isDisable={!activeToDo}
              action={handleDeleteRecipe}
            />
            <Box marginRight='auto' style={{ position: 'relative' }}>
              <ActionBtn
                Icon={PrintIcon}
                action={() => activeToDo && setPrintModal(!printModal)}
                isActive={printModal}
                isDisable={!activeToDo}
              />
              {printModal ? (
                <PrintModal
                  handleClose={() => setPrintModal(false)}
                  options={printOptions}
                />
              ) : null}
            </Box>

            <ActionBtn
              Icon={inProgressIcon}
              isDisable={!activeToDo}
              action={handleTranferTab}
            />
          </Box>
          <div style={{ display: 'none' }}>
            <PrintRecipe ref={componentRef} recipe={activeToDo} type='todo' />
          </div>
        </>
      ) : (
        <EditRecipe
          activeRecipe={activeToDo}
          setIsEdit={setIsEdit}
          editRecipeAction={editRecipeAction}
          loading={editLoading}
        />
      )}
    </>
  );
};

export default ToDo;
