import { configureStore } from '@reduxjs/toolkit';

import productionListsReducer from './reducers/productionLists.js';
import reciperReducer from './reducers/recipes.js';
import toDoReducer from './reducers/toDo.js';

export const store = configureStore({
  reducer: {
    recipes: reciperReducer,
    toDo: toDoReducer,
    productionLists: productionListsReducer,
  },
});
