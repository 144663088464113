import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

// import { useAccordionContext } from '../../context/AccordionContext';
import { setActiveRecipe } from '../../redux/reducers/recipes';
import { setActiveToDo } from '../../redux/reducers/toDo';
import { recipeSelector, toDoSelector } from '../../redux/selectors';
import fontSizes from '../../theme/fontSizes';
import { getDefaultDateFormat } from '../../utils/getDefaultDateFormat';
import {
  // getIngridientsTotalWeight,
  getRecipeTotalWeight,
} from '../../utils/getRecipeTotalWeight';
import { isNaNValidation } from '../../utils/isNaNValidation';
import FavoriteBoldIcon from '../icons/FavoriteBoldIcon';
import FavoriteThinIcon from '../icons/FavoriteThinIcon';
import GearSettingsIcon from '../icons/GearSettingsIcon';
import SelectArrowIcon from '../icons/SelectArrowIcon';
import useStyles from './styles';

const RecipesListItem = ({
  // title,
  activeTab,
  setActiveTab,
  setPrintModal,
  HighlightingText,
  regex,
  type = 'all',
  recipe,
  favoriteLoading,
  // category,
  handleFavorite = () => {},
}) => {
  const dispatch = useDispatch();
  const { activeRecipe } = useSelector(recipeSelector);
  const { activeToDo } = useSelector(toDoSelector);
  const handleChange = (recipe) => {
    setActiveTab(activeTab === recipe?.id ? '' : recipe?.id);

    if (type === 'all') {
      dispatch(
        setActiveRecipe({
          activeRecipe: activeRecipe?.id === recipe?.id ? null : recipe,
        }),
      );
    }

    if (type === 'todo') {
      dispatch(
        setActiveToDo({
          activeToDo: activeToDo?.id === recipe?.id ? null : recipe,
        }),
      );
      activeToDo?.id === recipe?.id ? setPrintModal(false) : null;
    }
  };
  const { formatMessage } = useIntl();

  const isActiveTab = activeTab === recipe?.id;
  const classes = useStyles({ recipe });
  return (
    <Box className={classes.wrapper}>
      <Box
        className={classNames(
          classes.btnRowWrapper,
          isActiveTab ? classes.activeBtnRowWrapper : '',
        )}
      >
        <Box className={classes.titleWrapper}>
          <Typography fontSize={fontSizes.md}>
            <HighlightingText text={recipe?.name || ''} regex={regex} />
          </Typography>
        </Box>
        {type === 'all' ? (
          <Box
            onClick={() =>
              !favoriteLoading && handleFavorite(recipe?.id, recipe?.isFavorite)
            }
            className={classes.favoriteWrapper}
          >
            {recipe?.isFavorite ? (
              <FavoriteBoldIcon color={recipe?.group?.starColor} />
            ) : (
              <FavoriteThinIcon color={recipe?.group?.starColor} />
            )}
          </Box>
        ) : null}

        <Box
          className={classNames(
            classes.arrowWrapper,
            isActiveTab ? classes.activeArrowWrapper : '',
          )}
          onClick={() => handleChange(recipe)}
        >
          <SelectArrowIcon />
        </Box>
      </Box>

      <Box
        className={classNames(
          classes.contentWrapper,
          isActiveTab ? classes.activeContentWrapper : '',
        )}
      >
        <Box className={classes.content}>
          {type === 'todo' ? (
            <Box className={classes.contentRow}>
              {recipe?.createAt ? (
                <>
                  <Box className={classes.contentRowTitle}>
                    {formatMessage({ id: 'to_list_title' })}
                  </Box>
                  <Box className={classes.contentRowValue}>
                    {recipe?.createAt &&
                      getDefaultDateFormat(
                        recipe?.createAt,
                        'y MMMM d @ HH:mm',
                      )}
                  </Box>
                </>
              ) : null}
            </Box>
          ) : null}

          <Box
            className={classes.contentRow}
            marginBottom={type === 'all' ? 0 : '17px'}
          >
            <Box className={classes.contentRowTitle}>
              {formatMessage({ id: 'name_title' })}
            </Box>
            <Box className={classes.contentRowValue}>{recipe?.name}</Box>
          </Box>

          {type === 'todo' ? (
            <>
              {recipe?.ingridients ? (
                <Box className={classes.contentRow}>
                  <Box
                    className={classNames(
                      classes.contentRowTitle,
                      classes.contetnRowTitleAuto,
                    )}
                  >
                    {formatMessage({ id: 'planed_amount_title' })}
                  </Box>
                  <Box
                    className={classNames(
                      classes.contentRowValue,
                      classes.contentRowWeightValue,
                    )}
                  >
                    {`${isNaNValidation(
                      getRecipeTotalWeight(recipe)?.toFixed(3),
                      ' kg',
                    )} `}
                  </Box>
                  <Box className={classes.contentRowLine}></Box>
                  <GearSettingsIcon width={20} height={20} />
                </Box>
              ) : null}
              {recipe?.variant === 'ingridients'
                ? recipe?.ingridients?.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        className={classes.contentIngridientsValue}
                        marginBottom={0}
                      >{`${item?.count} * ${item?.name}`}</Box>
                    );
                  })
                : null}
              {recipe?.variant === 'sum' ? (
                <Box
                  className={classes.contentIngridientsValue}
                  marginBottom={0}
                >
                  {`${formatMessage({
                    id: 'fixed_amount_title',
                  })}: ${isNaNValidation(
                    Number(recipe?.completeSum).toFixed(3),
                    ' kg',
                  )}`}
                </Box>
              ) : null}

              {recipe?.variant === 'x-value' ? (
                <Box
                  className={classes.contentIngridientsValue}
                  marginBottom={0}
                >
                  {`${formatMessage({
                    id: 'x_time_base_pecipe',
                  })}: ${isNaNValidation(recipe?.xValue)}`}
                </Box>
              ) : null}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default RecipesListItem;
