import React from 'react';
import { useSelector } from 'react-redux';

import { ACCORDION_ITEMS_OPTIONS } from '../../config/constants';
import { useAccordionContext } from '../../context/AccordionContext';
import { recipeSelector, toDoSelector } from '../../redux/selectors';
import AccardionItem from './AccardionItem/AccardionItem';

const ContentAccordion = () => {
  // const [activeTab, setActiveTab] = useState('');

  const { activeTab, setActiveTab } = useAccordionContext();
  const { recipes } = useSelector(recipeSelector);
  const { toDo, activeToDo } = useSelector(toDoSelector);

  const accordionItems = ACCORDION_ITEMS_OPTIONS;

  const getCountValue = (title) => {
    switch (title) {
      case 'all_recipes_block_title':
        return recipes.length > 0
          ? `${recipes.length} | ${toDo.length > 0 ? toDo.length : '0'} ${
              activeToDo ? '(1)' : ''
            }`
          : '';
      case 'todo_block_title_up':
        return toDo.length > 0
          ? `${toDo.length > 0 ? toDo.length : ''} ${activeToDo ? '| 1' : ''}`
          : '';
      default:
        return '';
    }
  };

  return (
    <>
      {accordionItems?.map(
        ({ btnTitle, contentTitle, Icon, children: Component }, index) => {
          return (
            <AccardionItem
              key={index}
              activeTab={activeTab}
              btnTitle={btnTitle}
              contentTitle={contentTitle}
              // count={count}
              count={getCountValue(contentTitle)}
              Icon={Icon}
              setActiveTab={setActiveTab}
            >
              <Component />
            </AccardionItem>
          );
        },
      )}
    </>
  );
};

export default ContentAccordion;
