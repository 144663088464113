import SelectArrowIcon from '../../icons/SelectArrowIcon';

export const BTN_ACTIONS = {
  ADD: 'ADD',
  THEME: 'THEME',
  CALC: 'CALC',
  DELETE: 'DELETE',
  REMOVE: 'REMOVE',
};

export const calcBtns = [
  {
    display: '7',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '8',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '9',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '/',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: 'C',
    action: BTN_ACTIONS.DELETE,
    class: '',
  },
  {
    display: '4',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '5',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '6',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '*',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: <SelectArrowIcon />,
    action: BTN_ACTIONS.REMOVE,
    class: 'arrow',
  },
  {
    display: '1',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '2',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '3',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '-',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '=',
    action: BTN_ACTIONS.CALC,
    class: 'equal',
  },
  {
    display: '0',
    action: BTN_ACTIONS.ADD,
    class: 'null',
  },
  {
    display: '.',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '+',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
];

export const numbBtns = [
  {
    display: 'C',
    action: BTN_ACTIONS.DELETE,
    class: 'first',
  },
  {
    display: <SelectArrowIcon />,
    action: BTN_ACTIONS.REMOVE,
    class: 'arrow',
  },
  {
    display: '7',
    action: BTN_ACTIONS.DELETE,
  },
  {
    display: '8',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '9',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '4',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '5',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '6',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '1',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '2',
    action: BTN_ACTIONS.ADD,
  },
  {
    display: '3',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
  {
    display: '0',
    action: BTN_ACTIONS.ADD,
    class: 'null',
  },
  {
    display: '.',
    action: BTN_ACTIONS.ADD,
    class: '',
  },
];
