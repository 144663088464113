import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(() => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  overrides: {
    width: ({ size }) => `${size}px !important`,
    height: ({ size }) => `${size}px !important`,
    '& .child:before': {
      backgroundColor: `#BABC16 !important`,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: ({ fullPage }) => (fullPage ? 1 : 'none'),
    width: ({ fullPage }) => (fullPage ? '100%' : 'auto'),
    height: ({ fullPage }) => (fullPage ? '100%' : 'auto'),
  },
  st0: {
    fill: '#2C2E65',
    animation: '$fadeIn 3s cubic-bezier(0.5, 0, 1,-0.69) infinite',
  },
  st1: {
    fill: '#BABC16',
    animation: '$fadeIn 3s cubic-bezier(0.5, 0, 1,-0.69) infinite',
  },
  row1: {
    animationDelay: '0s',
  },
  row2: {
    animationDelay: '0.15s',
  },
  row3: {
    animationDelay: '0.55s',
  },
  row4: {
    animationDelay: '0.75s',
  },
  row5: {
    animationDelay: '0.85s',
  },
  row6: {
    animationDelay: '0.9s',
  },
  row7: {
    animationDelay: '1.2s',
  },
}));

export default styles;
