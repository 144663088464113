import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    accountBtn: {
      backgroundColor: pallete.secondary1,
      marginLeft: 10,
      width: 565,
      height: 52,
      marginBottom: 21,
      border: '1px solid #000000',
      borderRadius: 7,
      display: 'flex',
      alignItems: 'center',
      transition: ' all .5s',
      paddingLeft: 12,
      paddingRight: 12,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: pallete.buttonBg,
      },
      '& img': {
        width: 35,
        height: 35,
        marginRight: 17,
        borderRadius: '50%',
      },
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }))();

export default useStyles;
