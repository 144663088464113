import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toDo: [],
  activeToDo: null,
  search: '',
  category: 'all',
  queryCount: 0,
};

export const toDoSlice = createSlice({
  name: 'toDo',
  initialState,
  reducers: {
    setToDo: (state, action) => {
      state.toDo = action.payload.toDo;
    },
    setActiveToDo: (state, action) => {
      state.activeToDo = action.payload.activeToDo;
    },
    setSearch: (state, action) => {
      state.search = action.payload.search;
    },
    setCategory: (state, action) => {
      state.category = action.payload.category;
    },
    setQueryCount: (state) => {
      state.queryCount += 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToDo, setActiveToDo, setSearch, setCategory, setQueryCount } =
  toDoSlice.actions;

export default toDoSlice.reducer;
