import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { useAccordionContext } from '../../context/AccordionContext';
import { useAuthContext } from '../../context/AuthContext';
import useStyles from './styles';

function useOutsideAlerter(ref, setOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setOpen]);
}

const CustomDropdown = ({
  children,
  content,
  setValue,
  marginTop,
  className,
}) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);
  const classes = useStyles({ marginTop });
  const { setActiveTab } = useAccordionContext();
  const { handleLogout } = useAuthContext();

  useOutsideAlerter(wrapperRef, setVisible);

  const handleChange = (e, listItem) => {
    e.stopPropagation();
    setVisible(false);
    if (listItem.value === 'logout') {
      setActiveTab('');
      handleLogout();
    } else {
      setValue(listItem);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={classNames(classes.relativeContainer, className)}
    >
      <Box onClick={() => setVisible(!visible)}>{children}</Box>
      {visible && (
        <Box className={classes.drodownContainer}>
          <Box className={classes.contentContainer}>
            <List style={{ padding: 0 }}>
              {content.map((listItem) => {
                const Icon = listItem?.icon;
                const imgUrl = listItem?.imgUrl;
                const title = listItem?.title;
                return (
                  <ListItem
                    key={listItem.value}
                    className={classNames(classes.listItem)}
                    onClick={(e) => {
                      handleChange(e, listItem);
                    }}
                  >
                    {Icon && (
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                    )}
                    {imgUrl && <img src={imgUrl} alt={listItem.value} />}
                    {title && (
                      <ListItemText
                        secondary={listItem.title}
                        className={classNames(classes.textItem)}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <span className={classes.toolTipArrow} />
        </Box>
      )}
    </div>
  );
};

export default CustomDropdown;
