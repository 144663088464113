import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import pallete from '../../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    // number
    numberWrapper: {
      width: '100%',
      marginTop: 10,
    },

    number__btns: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '10px',
    },
    btn: {
      border: 0,
      outline: 0,
      borderRadius: 7,
      backgroundColor: pallete.textSecondary,
      color: pallete.textPrimary,
      height: '100% !important',
      minHeight: '51.5px !important',
      fontSize: fontSizes.ml,
      fontWeight: fontWeights.BOLD,
      cursor: 'pointer',
    },
    numBtn: {
      '&:first-child': {
        gridColumnStart: 2,
      },
    },
    //calculator
    calculatorWrapper: {
      width: '100%',
      overflow: 'hidden',
      backgroundColor: pallete.modalBG,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginTop: 10,
    },
    calculatorResultWrapper: {
      padding: '40px 20px 10px',
      height: '100px',
      backgroundColor: pallete.textSecondary,
      borderRadius: 7,
      fontWeight: fontWeights.BOLD,
      marginBottom: 10,
    },
    calculatorResult: {
      position: 'relative',
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    // calculatorResultExp: {
    //   fontSize: fontSizes.xxl,
    //   lineHeight: 1,
    //   transformOrigin: 'right bottom',
    //   transition: 'transform 0.3s ease',
    //   display: 'flex',
    //   alignItems: 'center',
    //   '&:last-child': {
    //     position: 'absolute',
    //     right: 0,
    //   },
    //   '& span': {
    //     display: 'inline-block',
    //     overflow: 'hidden',
    //     transition: 'width 0.3s ease',
    //   },
    // },

    calculatorBtnsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gap: '10px',
    },

    // btnsClasses
    null: {
      gridColumn: 'span 2',
    },
    equal: {
      gridRow: 'span 2',
    },
    arrow: {
      '& svg': {
        transform: 'rotate(90deg)',
        display: 'flex',
        marginLeft: 15,
      },
    },
  }))();

export default useStyles;
